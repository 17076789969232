import { Outlet, Navigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentUser,
  selectToken,
  setCredentials,
} from "./features/authSlice";
// import { ProtectedRoute } from "../app/services/type";

export const ProtectedRoute = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const token = useSelector(selectToken);
  // console.log(token);
  if (!user) {
    if (window.localStorage.getItem("userInfo")) {
      const userInfo: {
        user: UserData;
        auth: {
          access_token: string;
        };
      } = JSON.parse(window.localStorage.getItem("userInfo")!);
      dispatch(setCredentials(userInfo));
      return <Outlet />;
    } else {
      return (
        <Navigate to={`/auth/login?redirect=${window.location.pathname}`} />
      );
    }
  }
  if (user.email_verified === "PENDING") {
    return <Navigate to={"/auth/verify-email"} />;
  }

  //   let gottenToken = token ? (
  //     JSON.parse(token!)
  //   ) : (
  //     <Navigate to={"/auth/login"} />
  //   );

  //   let auth: string = user?.auth?.access_token;

  //   return token ?  <Outlet /> : <Navigate to={"/auth/login"} />;
  return <Outlet />;
};

export const NoAccessRoute = () => {
  let token = window.localStorage.getItem("userInfo");
  let gottenToken = token ? (
    JSON.parse(token!)
  ) : (
    <Navigate to={"/auth/login"} />
  );
  // console.log(gottenToken?.auth?.access_token);
  let auth: string = gottenToken?.auth?.access_token;

  return !auth ? <Outlet /> : <Navigate to={"/dashboard"} />;
};
