import { Flex, Skeleton } from "@chakra-ui/react";

export default function ThreeCardSkeleton() {
  return (
    <Flex w="100%">
      <Skeleton
        h={{ base: "20vh", md: "50vh" }}
        bg="white"
        w={{ base: "100%", md: "300" }}
        mr={{ base: 0, md: "1rem" }}
        mb={{ base: "1rem", md: 0 }}
      />

      <Skeleton
        h={{ base: "20vh", md: "50vh" }}
        bg="white"
        w={{ base: "100%", md: "300" }}
        mr={{ base: 0, md: "1rem" }}
        mb={{ base: "1rem", md: 0 }}
        display={{ base: "none", md: "inline-flex" }}
      />
      <Skeleton
        h={{ base: "20vh", md: "50vh" }}
        bg="white"
        w={{ base: "100%", md: "300" }}
        mr={{ base: 0, md: "1rem" }}
        mb={{ base: "1rem", md: 0 }}
        display={{ base: "none", md: "inline-flex" }}
      />
    </Flex>
  );
}
