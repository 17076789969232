import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";

const slice = createSlice({
  name: "constant",
  initialState: {} as { notifications: NotificationType[] },
  reducers: {
    setNotifications: (
      state,
      { payload }: PayloadAction<NotificationType[]>
    ) => {
      state.notifications = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setNotifications } = slice.actions;

export default slice.reducer;

export const selectNotifications = (state: RootState) =>
  state.notifications.notifications;
