import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../features/authSlice";
import userService from "../app/services/userService";
import { useCustomToast } from "../utils/toast";
export default function useProfileUpdate() {
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const profileFormik = useFormik({
    initialValues: {
      first_name: user?.first_name,
      last_name: user?.last_name,
      phone: user?.phone,
      country: user?.country,
      state: user?.state ? user.state : "",
      bio: user?.bio ? user.bio : "",
      github: user?.github ? user.github : "",
      linkedin: user?.linkedin ? user.linkedin : "",
      telegram: user?.telegram ? user.telegram : "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(30, "Must be 15 characters or less")
        .required("Required"),
      last_name: Yup.string()
        .max(30, "Must be 15 characters or less")
        .required("Required"),
      phone: Yup.number().required("Required"),
      country: Yup.string().required("Required"),
      state: Yup.string(),
      github: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
      // .required("Please enter a valid url!")
      telegram: Yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        "Enter correct url!"
      ),
      // .required("Please enter a valid url!")
      // linkedin: Yup.string().matches(
      //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //   "Enter correct url!"
      // ),
      linkedin: Yup.string().url("Enter correct url!"),
      // .required("Please enter a valid url!")
      bio: Yup.string().min(10, "Character must be greater than ten"),
    }),

    onSubmit: (values) => {
      setIsLoading(true);
      // console.log(values);
      userService
        .updateProfile(values, user?.id!)
        .then((res) => {
          // console.log(res);
          if (res.status) {
            notifySuccess("Update successful");
            dispatch(setUser({ data: res.data }));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          notifyError("Error occured");
          setIsLoading(false);
        });
    },
  });

  return { profileFormik, isLoading };
}
