import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import request from "./httpService";
const activityServices = {
  getConstants() {
    return request.get("/constants");
  },
  getcompliants(id: string) {
    return request.get(`/complaint/${id}`);
  },

  addComplaint(body: object) {
    return request.post("/complaint/send", body);
  },
  getNotifications(id: string) {
    return request.get(`/notification/${id}`);
  },
};

export default activityServices;
