import { Center, Heading, Image, Text } from "@chakra-ui/react";

export default function EmptyState({ message }: { message: string }) {
  return (
    <>
      <Center>
        <Image src="/assets/images/empty.png" />
      </Center>

      <Center mt={{ base: "1.9rem", md: "41px" }}>
        <Heading
          color="font.main"
          fontSize={{ base: "1.5rem", md: "32px" }}
          fontWeight="600"
        >
          Nothing to see for now
        </Heading>
      </Center>
      <Text
        color="#797067"
        fontSize={{ base: "0.9rem", md: "20px" }}
        textAlign="center"
        fontWeight="400"
        mt="12px"
      >
        {message}{" "}
      </Text>
    </>
  );
}
