import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit/dist";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import auth from "../features/authSlice";
import compliantSlice from "../features/compliantSlice";

import constant from "../features/constantSlice";
import courseSlice from "../features/courseSlice";
import notificationSlice from "../features/notificationSlice";
export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      auth: auth,
      constant: constant,
      courses: courseSlice,
      compliants: compliantSlice,
      notifications: notificationSlice,
    },
  });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
