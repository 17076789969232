import {
  Box,
  Center,
  HStack,
  PinInputField,
  chakra,
  Text,
  Heading,
  PinInput,
  useDisclosure,
  Button,
  Spinner,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../app/services/authService";
import SuccessView from "../../components/modal/SuccessView";
// import SuccessView from "../components/modal/SuccessView";
import {
  selectCurrentUser,
  selectOtp,
  setOtp,
  setUser,
} from "../../features/authSlice";
import * as Yup from "yup";
import { useCustomToast } from "../../utils/toast";
import moment from "moment";
import { routeObj } from "../../constants/routes";

export default function VerifyEmail() {
  const inputFontSize = { base: "16px", md: "1.2rem" };
  const { notifyError, notifySuccess } = useCustomToast();
  const { isOpen, onClose } = useDisclosure();
  const otp = useSelector(selectOtp);
  const dispatch = useDispatch();
  const [isLoading, setISLoading] = useState(false);
  const route = useNavigate();
  const user = useSelector(selectCurrentUser);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  // Count down function
  function countDown() {
    const date = new Date();
    date.setMinutes(date.getMinutes() + 3);
    const real_date = moment(date.getTime()).format("lll");
    var countDownDate = new Date(real_date).getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (days < 0 && minutes < 0 && seconds < 0 && hours < 0) {
        setDays(0);
        setMinutes(0);
        setSeconds(0);
        setIsCompleted(true);
        clearInterval(x);
        return;
      } else {
        setDays(days);
        setMinutes(minutes);
        setSeconds(seconds);
      }
    }, 1000);
  }
  const verifyEmailFormik = useFormik({
    initialValues: {
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: Yup.object({
      otp1: Yup.string().required("Required").min(1, "Must be 1 character"),
      otp2: Yup.string().min(1, "Must be 1 character"),
      otp3: Yup.string().min(1, "Must be 1 character"),
      otp4: Yup.string().min(1, "Must be 1 character"),
    }),
    onSubmit: (values) => {
      setISLoading(true);
      const value = values.otp1 + values.otp2 + values.otp3 + values.otp4;

      authService
        .verifyEmail({ OTP: value })
        .then((res) => {
          setISLoading(false);
          // console.log(res);
          notifySuccess(res.message);
          dispatch(setUser({ data: { ...user!, email_verified: "APPROVED" } }));
        })
        .catch((err) => {
          setISLoading(false);
          console.log(err);
          notifyError(err.response.data.message);
        });
    },
  });

  useEffect(() => {
    if (!user) {
      route("/");
    }
    if (user?.email_verified !== "PENDING") {
      route("/dashboard/courses");
    }

    countDown();
  }, [user]);

  return (
    <Box bg="bg.subMain">
      <Box pt="2.4rem" ps="2.5rem" bg="inherit">
        {" "}
        <Link to={routeObj.home}>
          <Image
            // w={"100%"}
            w="220px"
            src="/logo/logo-dark.png"
            display={{ base: "none", md: "inline-flex" }}
          />
        </Link>
      </Box>
      <Box
        px={{ base: "7%", md: "20%" }}
        pt={{ base: "4rem", md: "5rem" }}
        color="font.dark"
        h="100vh"
      >
        <SuccessView isOpen={isOpen} onClose={onClose} />
        <Box textAlign={"center"}>
          <Heading fontSize={{ base: "1.5rem", md: "2rem" }} fontWeight="700">
            Verify your email
          </Heading>
          <Text mt="1.2rem" fontSize={{ base: "14px", md: "1.2rem" }}>
            To verify your email we’ ve sent a one time OTP to {user?.email}
          </Text>
        </Box>
        <Box pt={{ base: "10px", md: "4rem" }} pb="20px">
          <form onSubmit={verifyEmailFormik.handleSubmit}>
            <HStack justifyContent="center" columnGap="10px">
              <PinInput
                size={{ base: "lg", md: "xl" }}
                variant={"unstyled"}
                isDisabled={isLoading}
              >
                <PinInputField
                  fontSize={{ base: "35px", md: "48px" }}
                  h="110px"
                  bg="white"
                  name="otp1"
                  value={verifyEmailFormik.values.otp1}
                  onChange={verifyEmailFormik.handleChange}
                />
                <PinInputField
                  fontSize={{ base: "35px", md: "48px" }}
                  h="110px"
                  bg="white"
                  name="otp2"
                  value={verifyEmailFormik.values.otp2}
                  onChange={verifyEmailFormik.handleChange}
                />
                <PinInputField
                  fontSize={{ base: "35px", md: "48px" }}
                  h="110px"
                  bg="white"
                  name="otp3"
                  value={verifyEmailFormik.values.otp3}
                  onChange={verifyEmailFormik.handleChange}
                />
                <PinInputField
                  fontSize={{ base: "35px", md: "48px" }}
                  h="110px"
                  bg="white"
                  name="otp4"
                  value={verifyEmailFormik.values.otp4}
                  onChange={verifyEmailFormik.handleChange}
                />
              </PinInput>
            </HStack>
            <Box>
              {" "}
              <Text color="red" fontSize="12px">
                {verifyEmailFormik.errors.otp1 && verifyEmailFormik.errors.otp1}
                {/* {verifyEmailFormik.errors.otp2 && verifyEmailFormik.errors.otp2}
              {verifyEmailFormik.errors.otp3 && verifyEmailFormik.errors.otp3}
              {verifyEmailFormik.errors.otp4 && verifyEmailFormik.errors.otp4} */}
              </Text>
            </Box>
            <Center mt={{ base: "1.2rem", md: "1.9rem" }}>
              <Button
                type="submit"
                // onClick={onOpen}
                display="flex"
                w={{ base: "100%", md: "550px" }}
                alignSelf={"center"}
                _hover={{ bg: "blue.700", color: "gray.200" }}
                transition="0.6s"
                borderRadius={"sm"}
                bg="bg.main"
                h="4rem"
                color="#ffffff"
                justifyContent={"center"}
                fontSize={{ base: "0.8rem", md: "1.1rem" }}
                isDisabled={isLoading}
              >
                <Center columnGap="20px">
                  SUBMIT{" "}
                  <Spinner display={isLoading ? "inline-flex" : "none"} />
                </Center>
              </Button>
            </Center>
          </form>
          <Center mt="1.2rem">
            <Text
              fontWeight={"400"}
              display="flex"
              columnGap="10px"
              fontSize={inputFontSize}
              color="#666666"
            >
              {" "}
              Didn’t get an OTP?{" "}
              {isCompleted ? (
                <chakra.a
                  href="#"
                  onClick={() => {
                    authService
                      .getVerifyEmailToken()
                      .then((res) => {
                        notifySuccess(res.message);
                        countDown();
                        setIsCompleted(false);
                      })
                      .catch((err) => {
                        notifyError("Server error");
                      });
                  }}
                  color="font.main"
                >
                  {" "}
                  Resend it
                </chakra.a>
              ) : (
                <Flex>
                  <Text>{minutes}:</Text>
                  <Text>{seconds}</Text>
                </Flex>
              )}
            </Text>
          </Center>
        </Box>
      </Box>
    </Box>
  );
}
