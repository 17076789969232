import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  Icon,
  Spinner,
  FormControl,
  Input,
  HStack,
  FormHelperText,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Layout from "../../layouts/dashboard/Layout";
import courseService from "../../app/services/courseService";
import { useParams } from "react-router-dom";
import { dateFormatter, numberFormatter } from "../../utils";
import { selectCurrentUser } from "../../features/authSlice";
import { useSelector } from "react-redux";
import { useCustomToast } from "../../utils/toast";

export default function Checkout() {
  const { code } = useParams();
  const [batches, setBatches] = useState<Batches>();
  const [isLoading, setIsLoading] = useState(true);
  const [payLoading, setPayLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [validateLoading, setValidateLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [couponVerified, setCouponVerified] = useState(false);
  const [couponError, setCouponError] = useState("");
  const user = useSelector(selectCurrentUser);
  const { notifyError, notifySuccess } = useCustomToast();
  const handleCoupon = () => {
    setCouponError("");
    if (coupon.length < 8) {
      setCouponError("Coupon code must be at least 8 characters long");
      return;
    }
    setValidateLoading(true);
    courseService
      .validateCoupon(coupon)
      .then((res) => {
        // console.log(res);
        // setAmount(res.data.discounted_price);
        if (res.status) {
          let price = batches?.batch_price! * (res.data.percentage / 100);
          price = batches?.batch_price! - price;
          setAmount(price);
          setCouponVerified(true);
          setCouponError("");
          notifySuccess("Coupon applied successfully");
        } else {
          setCouponError(res.message);
        }
        setValidateLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCouponError(err.response.data.message);
        setValidateLoading(false);
        notifyError(err.response.data.message);
      });
  };
  const fetchCourseDetails = () => {
    setIsLoading(true);
    courseService
      .getBatchById(code!)
      .then((res) => {
        // console.log(res);
        setBatches(res.data.coursebatch);
        setAmount(res?.data.coursebatch.batch_price);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    fetchCourseDetails();
  }, []);
  const handlePay = () => {
    setPayLoading(true);
    courseService
      .registerCourseByBatch(code!, {
        email: user?.email,
        [couponVerified ? "coupon_code" : ""]: coupon,
      })
      .then((res) => {
        // console.log(res.data);
        window.location.href = res.data.PaymentDetails.authorization_url;
        // setPayLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPayLoading(false);
        notifyError(err.response.data.message);
      });
  };
  return (
    <Layout>
      <Box
        px={{ base: "1rem", md: "62px" }}
        pt={{ base: "3rem", md: "64px" }}
        pb="127px"
      >
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Center
            bg="bg.subMain"
            pt={{ base: "3rem", md: "137.56px" }}
            pb="56px"
            ps={{ base: "1.5rem", md: "59px" }}
            pe={{ base: "1.5rem", md: "100px" }}
          >
            <Box w="100%">
              <Heading
                color="font.main"
                fontSize={{ base: "1.5rem", md: "40px" }}
                textAlign="center"
              >
                Course details
              </Heading>
              <Box
                pt={{ base: "2.3rem", md: "66.41px" }}
                w="100%"
                color="font.dark"
              >
                <Flex
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  pb={{ base: "0.9rem", md: "24px" }}
                  borderBottomColor="#999FAA"
                  borderBottomWidth={"1px"}
                >
                  <Text fontWeight="400">Batch name</Text>
                  <Text fontWeight="500">
                    {batches?.name?.replace("-", " ")}
                  </Text>
                </Flex>

                <Flex
                  pt={{ base: "2rem", md: "50px" }}
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text fontWeight="400">Start date:</Text>
                  <Text fontWeight="500">
                    {" "}
                    {dateFormatter(batches?.start_date!)}
                  </Text>
                </Flex>

                <Flex
                  pt={{ base: "1.2rem", md: "30px" }}
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text fontWeight="400">Days:</Text>
                  <Text fontWeight="500">
                    {batches &&
                      JSON.parse(`${batches.days}`)?.map(
                        (days: string, index: number) =>
                          `${days} ${index !== 2 ? "/" : ""}`
                      )}
                    {/* {batches && batches.days} */}
                  </Text>
                </Flex>
                <Flex
                  pt={{ base: "1.2rem", md: "30px" }}
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text fontWeight="400">Time:</Text>
                  <Text fontWeight="500">{`${batches?.time} - ${
                    parseInt(batches?.time!) + 1
                  }:00`}</Text>
                </Flex>
                <Flex
                  pt={{ base: "1.2rem", md: "30px" }}
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text fontWeight="400">Location:</Text>
                  <Text fontWeight="500" color="#2CA900">
                    Live Online
                  </Text>
                </Flex>
                <Flex
                  pt={{ base: "1.2rem", md: "30px" }}
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  display={batches?.instructor_first_name ? "flex" : "none"}
                >
                  <Text fontWeight="400">Instructor:</Text>
                  <Text fontWeight="500">
                    {`${batches?.instructor_first_name} ${batches?.instructor_last_name}`}
                  </Text>
                </Flex>
                <Flex
                  pt={{ base: "1.2rem", md: "30px" }}
                  pb={{ base: "2rem", md: "50px" }}
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  borderBottomColor="#999FAA"
                  borderBottomWidth={"1px"}
                >
                  <Text fontWeight="400">Coupon:</Text>
                  <HStack alignItems={"start"}>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="Enter coupon code (optional)"
                        value={coupon}
                        outline="1px solid #999FAA"
                        color={"#999FAA"}
                        isDisabled={couponVerified}
                        _placeholder={{ color: "#999FAA" }}
                        onChange={(e) => setCoupon(e.target.value)}
                      />
                      <FormHelperText color="red.500">
                        {""}
                        {couponError}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      _hover={{}}
                      onClick={handleCoupon}
                      bg="bg.main"
                      color="white"
                      columnGap={2}
                      isDisabled={validateLoading}
                    >
                      {" "}
                      Apply
                      <Spinner display={validateLoading ? "block" : "none"} />
                    </Button>
                  </HStack>
                </Flex>

                <Flex
                  pt="24px"
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text fontWeight="400">Price:</Text>
                  <Text fontWeight="600">
                    ₦{numberFormatter(amount)}
                    {" Monthly"}
                  </Text>
                </Flex>
              </Box>
              <Center pt={{ base: "2.5rem", md: "82px" }}>
                <Button
                  // display="flex"
                  w={{ base: "100%", md: "810px" }}
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.6s"
                  borderRadius={"none"}
                  bg="bg.main"
                  h={{ base: "2.6rem", md: "88px" }}
                  color="#ffffff"
                  fontWeight="600"
                  fontSize={{ base: "14px", md: "24px" }}
                  onClick={handlePay}
                  isDisabled={payLoading}
                >
                  PAY
                  <Spinner display={payLoading ? "block" : "none"} ml="10px" />
                </Button>
              </Center>
            </Box>
          </Center>
        )}
      </Box>
    </Layout>
  );
}
