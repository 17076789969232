import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";

const slice = createSlice({
  name: "auth",
  initialState: { user: null, token: null, layoutHasLoaded: false } as {
    user: null | UserData;
    token: null | string;
    otp: boolean;
    layoutHasLoaded: boolean;
  },
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          user,
          auth: { access_token },
        },
      }: PayloadAction<{
        user: UserData;
        auth: { access_token: string };
      }>
    ) => {
      state.user = user;
      state.token = access_token;
      const userInfo = { user: user, auth: { access_token: access_token } };
      window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    setUser: (
      state,
      {
        payload: { data },
      }: PayloadAction<{
        data: UserData;
      }>
    ) => {
      state.user = data;
      const userInfo = { user: data, auth: { access_token: state.token } };
      window.localStorage.setItem("userInfo", JSON.stringify(userInfo));
    },
    setOtp: (state, { payload }: PayloadAction<boolean>) => {
      state.otp = payload;
    },
    setLayoutHasLoaded: (state) => {
      state.layoutHasLoaded = true;
    },

    logout: (state) => {
      state.user = null;
      state.token = null;
      window.localStorage.removeItem("userInfo");
    },
  },
  extraReducers: (builder) => {},
});

export const { setCredentials, logout, setUser, setOtp, setLayoutHasLoaded } =
  slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectToken = (state: RootState) => state.auth.token;
export const selectOtp = (state: RootState) => state.auth.otp;
export const selectLayoutHasLoaded = (state: RootState) =>
  state.auth.layoutHasLoaded;
