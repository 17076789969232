import {
  Box,
  Center,
  Flex,
  FormControl,
  Heading,
  Input,
  chakra,
  Icon,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  Divider,
  Button,
  Modal,
  ModalOverlay,
  useDisclosure,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useEffect, useState } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { MdArrowDropDown } from "react-icons/md";
import { routeObj } from "../../constants/routes";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import activityServices from "../../app/services/activityServices";
import { useDispatch, useSelector } from "react-redux";
import { selectConstants, setConstants } from "../../features/constantSlice";
import { useCustomToast } from "../../utils/toast";
import authService from "../../app/services/authService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { selectCurrentUser, setCredentials } from "../../features/authSlice";

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
export default function Register() {
  const [show, setShow] = useState(false);
  const inputFontSize = { base: "16px", md: "1.2rem" };
  const route = useNavigate();
  const constants = useSelector(selectConstants);
  const [state, setState] = useState<object | null>();
  const [country, setCountry] = useState<object | null>();
  const [gender, setgender] = useState<object | null>();

  const { onClose, isOpen, onOpen } = useDisclosure();

  const user = useSelector(selectCurrentUser);
  const [isLoading, setIsLoading] = useState(false);

  const { notifyError, notifySuccess } = useCustomToast();
  const dispatch = useDispatch();
  useEffect(() => {
    const init = async () => {
      if (!constants.countries) {
        const data = await activityServices.getConstants();
        dispatch(setConstants(data?.data!));
        setCountry(data?.data?.countries);
        setState(data?.data?.states);
        setgender(data?.data?.gender);
        // console.log(data);
      } else {
        setCountry(constants.countries);
        setState(constants.states);
        setgender(constants.gender);
      }
    };
    init();
  }, []);
  useEffect(() => {
    if (user) {
      route("/dashboard");
    }
  }, [user]);

  const registerFormik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      country: "",
      state: "",
      gender: "",
      password: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(30, "Must be 15 characters or less")
        .required("Required"),
      last_name: Yup.string()
        .max(30, "Must be 15 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      country: Yup.string().required("Required"),
      state: Yup.string(),
      gender: Yup.string().required("Required"),
      password: Yup.string()
        .required("Required")
        .min(8, "Must be 8 characters and above."),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    onSubmit: async (values) => {
      setIsLoading(true);
      // console.log(JSON.stringify(values));
      authService
        .register(values)
        .then((data) => {
          // console.log(data);
          if (data?.data?.auth) {
            dispatch(setCredentials(data?.data?.auth));
            notifySuccess("Registeration successfull");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          notifyError(err.response.data.message);
        });
    },
  });
  const googleRegister = useGoogleLogin({
    onSuccess: (codeResponse) => {
      // console.log(codeResponse);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          // console.log(res.data);
          setIsLoading(true);
          const resp = {
            email: res.data.email,
            first_name: res.data.given_name,
            last_name: res.data.family_name,
            password: res.data.id,

            country: "",
          };
          authService
            .register(resp)
            .then((data) => {
              // console.log(data);
              if (data?.data?.auth) {
                dispatch(setCredentials(data?.data?.auth));
              }
              setIsLoading(false);
              notifySuccess("Registeration successfull");
              onClose();
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              notifyError(err.response.data.message);
              onClose();
            });
          onClose();
        })
        .catch((err) => {
          onClose();
          console.log(err);
          notifyError(err);
        });
    },
    onError: (error) => {
      onClose();
      console.log("Login Failed:", error);
    },
  });
  return (
    <Box margin={0}>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(2px) hue-rotate(10deg)"
        />
      </Modal>
      <Flex
        bg="bg.subMain"
        minH="100vh"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box
          bgImage={{
            base: "/assets/images/auth-mobile.png",
            md: "/assets/images/auth-bg.png",
          }}
          pos="relative"
          bgSize="cover"
          bgPosition={{ base: "bottom", md: "top" }}
          minH={{ base: "36vh", md: "100vh" }}
          w="100%"
          flex={1.2}
        >
          <Box pt="2.4rem" ps="2.5rem">
            {" "}
            <Link to={routeObj.home}>
              <Image
                // w={"100%"}
                src="/logo/logo.png"
                display={{ base: "none", md: "inline-flex" }}
              />
            </Link>
          </Box>
          <Heading
            color="white"
            pos="absolute"
            top="40%"
            left="10%"
            fontWeight="bold"
            fontSize="32px"
            display={{ base: "block", md: "none" }}
          >
            Register
          </Heading>
        </Box>
        <Box
          px={{ base: "20px", md: "3rem" }}
          py={{ base: "38px", md: "5rem" }}
          flex={1}
          color="font.dark"
          bg="bg.subMain"
        >
          <Heading
            display={{ base: "none", md: "block" }}
            color="font.dark"
            fontWeight="700"
            fontSize={{ base: "1.3rem", md: "2rem" }}
          >
            Register
          </Heading>
          <Box pt={{ base: "1rem", md: "2.5rem" }}>
            <form onSubmit={registerFormik.handleSubmit}>
              <FormControl
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                py="0.9rem"
              >
                <Input
                  placeholder="First name"
                  type="text"
                  name="first_name"
                  onChange={registerFormik.handleChange}
                  value={registerFormik.values.first_name}
                  onBlur={registerFormik.handleBlur}
                  borderColor="#C9C9C9"
                  color="font.dark"
                  _placeholder={{ color: "font.dark" }}
                  fontSize={inputFontSize}
                  fontWeight={"400"}
                  variant={"unstyled"}
                  autoComplete="off"
                  isDisabled={isLoading}
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {registerFormik.errors.first_name &&
                  registerFormik.errors.first_name}
              </Text>
              <FormControl
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                py="0.9rem"
                mt="1rem"
              >
                <Input
                  placeholder="Last name"
                  type="text"
                  name="last_name"
                  onChange={registerFormik.handleChange}
                  value={registerFormik.values.last_name}
                  onBlur={registerFormik.handleBlur}
                  borderColor="#C9C9C9"
                  color="font.dark"
                  _placeholder={{ color: "font.dark" }}
                  fontSize={inputFontSize}
                  fontWeight={"400"}
                  variant={"unstyled"}
                  isDisabled={isLoading}
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {registerFormik.errors.last_name &&
                  registerFormik.errors.last_name}
              </Text>
              <FormControl
                mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                py="0.9rem"
              >
                <Input
                  placeholder="Email"
                  borderColor="#C9C9C9"
                  type="email"
                  name="email"
                  onChange={registerFormik.handleChange}
                  value={registerFormik.values.email}
                  onBlur={registerFormik.handleBlur}
                  color="font.dark"
                  _placeholder={{ color: "font.dark" }}
                  fontSize={inputFontSize}
                  fontWeight={"400"}
                  variant={"unstyled"}
                  isDisabled={isLoading}
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {registerFormik.errors.email && registerFormik.errors.email}
              </Text>
              <FormControl
                mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                pt="0.9rem"
              >
                <Select
                  // fontSize={inputFontSize}
                  // fontWeight={"400"}
                  // variant={"unstyled"}
                  placeholder="Select Gender"
                  name="gender"
                  isDisabled={isLoading}
                  onChange={(e) => {
                    const i = e?.value || "";
                    console.log(i);

                    registerFormik.setFieldValue("gender", i);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: state.isFocused ? "none" : "none",
                      background: "none",
                    }),
                  }}
                  // onBlur={registerFormik.handleBlur}
                  options={
                    gender
                      ? Object.entries(gender!).map((item) => ({
                          value: item[1],
                          label: item[1],
                        }))
                      : [{ value: "", label: "" }]
                  }
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {registerFormik.errors.gender && registerFormik.errors.gender}
              </Text>
              {/* <FormControl
                mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                py="0.9rem"
              >
                <Input
                  placeholder="Phone number"
                  borderColor="#C9C9C9"
                  type="tel"
                  name="phone"
                  onChange={registerFormik.handleChange}
                  value={registerFormik.values.phone}
                  onBlur={registerFormik.handleBlur}
                  color="font.dark"
                  isDisabled={isLoading}
                  _placeholder={{ color: "font.dark" }}
                  fontSize={inputFontSize}
                  fontWeight={"400"}
                  variant={"unstyled"}
                />
              </FormControl> */}

              <FormControl
                mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                pt="0.9rem"
              >
                <Select
                  // fontSize={inputFontSize}
                  // fontWeight={"400"}
                  // variant={"unstyled"}
                  placeholder="Select Country"
                  name="country"
                  isDisabled={isLoading}
                  onChange={(e) => {
                    const i = e?.value || "";
                    // console.log(i);

                    registerFormik.setFieldValue("country", i);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: state.isFocused ? "none" : "none",
                      background: "none",
                    }),
                  }}
                  // onBlur={registerFormik.handleBlur}
                  options={
                    country
                      ? Object.entries(country!).map((item) => ({
                          value: item[0],
                          label: item[1],
                        }))
                      : [{ value: "", label: "" }]
                  }
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {registerFormik.errors.country && registerFormik.errors.country}
              </Text>
              <FormControl
                mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                py="0.9rem"
                display={
                  registerFormik.values.country === "NG" ? "block" : "none"
                }
              >
                <Select
                  // fontSize={inputFontSize}
                  // fontWeight={"400"}
                  // variant={"unstyled"}
                  placeholder="Select State"
                  isDisabled={isLoading}
                  // required
                  name="state"
                  onChange={(e) => {
                    const i = e?.value || "";
                    // console.log(i);
                    registerFormik.setFieldValue("state", i);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      border: state.isFocused ? "none" : "none",
                      background: "none",
                    }),
                  }}
                  // onBlur={registerFormik.handleBlur}
                  options={
                    state
                      ? Object.entries(state!).map((item) => ({
                          value: item[0],
                          label: item[1],
                        }))
                      : [{ value: "", label: "" }]
                  }
                />
              </FormControl>

              <FormControl
                mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                py="0.9rem"
              >
                <InputGroup bg={{ base: "white", md: "none" }}>
                  <Input
                    fontSize={inputFontSize}
                    fontWeight={"400"}
                    variant={"unstyled"}
                    isDisabled={isLoading}
                    placeholder="Password"
                    name="password"
                    onChange={registerFormik.handleChange}
                    value={registerFormik.values.password}
                    onBlur={registerFormik.handleBlur}
                    borderColor="#C9C9C9"
                    color="font.dark"
                    _placeholder={{ color: "font.dark" }}
                    type={show ? "text" : "password"}
                    autoComplete="new-password"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      fontSize={inputFontSize}
                      aria-label="Call Segun"
                      onClick={() => setShow(!show)}
                      icon={show ? <BiHide /> : <BiShow />}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Text color="red" fontSize="12px">
                {registerFormik.errors.password &&
                  registerFormik.errors.password}
              </Text>
              <Center mt="1.9rem">
                <Button
                  type="submit"
                  display="flex"
                  w={{ base: "100%", md: "100%" }}
                  alignSelf={"center"}
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.6s"
                  borderRadius={"sm"}
                  bg="bg.main"
                  h="4rem"
                  color="#ffffff"
                  justifyContent={"center"}
                  fontSize={"1.1rem"}
                  isDisabled={isLoading}
                >
                  <Center columnGap="20px">
                    SIGNUP{" "}
                    <Spinner display={isLoading ? "inline-flex" : "none"} />
                  </Center>
                </Button>
              </Center>
            </form>

            <Center mt="1.5rem" columnGap="20px">
              <Divider orientation="horizontal" borderColor="#C9C9C9" />
              <Text fontSize={inputFontSize}>or</Text>
              <Divider orientation="horizontal" borderColor="#C9C9C9" />
            </Center>

            {/* <Center mt="1.5rem">
              <Button
                // href="#"
                fontWeight={"400"}
                onClick={() => {
                  onOpen();
                  googleRegister();
                }}
                display="flex"
                w={{ base: "100%", md: "100%" }}
                alignSelf={"center"}
                _hover={{ bg: "blue.700", color: "gray.200" }}
                transition="0.6s"
                borderRadius={"sm"}
                bg="none"
                borderColor="bg.main"
                borderWidth={"1px"}
                borderStyle="solid"
                // py="12px"
                px="50px"
                h="4rem"
                color="font.dark"
                justifyContent={"center"}
                isDisabled={isLoading}
              >
                <Center
                  columnGap={{ base: "10px", md: "1rem" }}
                  fontSize={inputFontSize}
                >
                  <Icon
                    as={FcGoogle}
                    fontSize={{ base: "30px", md: "2.5rem" }}
                  />
                  <Text>Signup with Google</Text>
                </Center>
              </Button>
            </Center> */}

            <Center mt="22px" columnGap="10px" fontSize={"16px"}>
              <Text>Already have an account? </Text>
              <Text color="font.main">
                {" "}
                <Link to={routeObj.login}>Login</Link>{" "}
              </Text>
            </Center>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
