import {} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import Layout from "../../layouts/dashboard/Layout";
import CourseDetailLayout from "../../components/courses/CourseDetailLayout";
import { useParams } from "react-router-dom";
import { object } from "yup";
import { useDispatch, useSelector } from "react-redux";
import { selectCourses, setCourses } from "../../features/courseSlice";
import courseService from "../../app/services/courseService";

export default function CourseDetailDashboard() {
  const { code } = useParams();
  const courses = useSelector(selectCourses);
  const [course, setCourse] = useState<Course>();
  const [batches, setBatches] = useState<Batches[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (courses?.length) {
      // console.log(parseInt(code!));
      const i = courses.filter((item) => item.course.id === code);
      setCourse(i[0]?.course);
      courseService
        .getBatchByCourse(i[0].course.id)
        .then((res) => {
          // console.log(res);
          setBatches(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      courseService
        .getCoursesForUser()
        .then((res) => {
          dispatch(setCourses({ courses: res.data }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [courses?.length]);
  return (
    <Layout>
      <CourseDetailLayout
        batches={batches!}
        course={course!}
        isLoading={isLoading}
      />
    </Layout>
  );
}
