import {
  Box,
  Flex,
  Image,
  Icon,
  chakra,
  Text,
  Center,
  Heading,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { routeObj } from "../constants/routes";
import { GrLinkedin, GrYoutube } from "react-icons/gr";
import { customStyle } from "../utils/style";
import activityServices from "../app/services/activityServices";
import { useDispatch, useSelector } from "react-redux";
import { selectConstants, setConstants } from "../features/constantSlice";
import { useEffect } from "react";

export default function Footer() {
  const dispatch = useDispatch();
  const constants = useSelector(selectConstants);
  const getConstants = async () => {
    if (!constants.countries) {
      const data = await activityServices.getConstants();
      dispatch(setConstants(data?.data!));
    }
  };
  useEffect(() => {
    getConstants();
  }, []);
  return (
    <Box
      bg="white"
      ps={customStyle.padding.psHome}
      pt={"3rem"}
      pb="1.5rem"
      color="font.main"
    >
      <Flex
        justifyContent={{ base: "center", md: "left" }}
        columnGap={{ base: "20px", md: "10rem" }}
        rowGap={"20px"}
        fontWeight="600 !important"
        flexDirection={{ base: "column-reverse", md: "row" }}
      >
        <Box>
          <Link to={routeObj.home}>
            <Image width="50%" src="/logo/logo-dark.png" />
          </Link>
          {/* <Text fontSize={customStyle.fontSize.logoText}>ODELANDCS</Text> */}

          <Flex
            mt={{ base: "1.7rem", md: "2.2rem" }}
            columnGap={"2rem"}
            ps="0.2rem"
            justifyContent={{ base: "center", md: "left" }}
          >
            <chakra.a href={constants.company?.linkedin_link} target="_blank">
              <Icon fontSize={{ base: "1.2rem", md: "2rem" }} as={GrLinkedin} />
            </chakra.a>
            <chakra.a href={constants.company?.youtube_link} target="_blank">
              <Icon fontSize={{ base: "1.2rem", md: "2rem" }} as={GrYoutube} />
            </chakra.a>
          </Flex>
        </Box>
        <Flex
          columnGap={"8rem"}
          justifyContent={{ base: "center", md: "left" }}
        >
          <Box fontSize={customStyle.fontSize.navItem} fontWeight="600">
            <Heading
              color="#999FAA"
              mb={{ base: "1.2rem", md: "2rem" }}
              fontWeight={"400"}
              fontSize={{ base: "1rem", md: "1.7rem" }}
            >
              Company
            </Heading>
            <Text
              mb={{ base: "0.9rem", md: "1.5rem" }}
              _hover={{ textDecoration: "underline" }}
            >
              <Link to={routeObj.terms}>Terms</Link>
            </Text>
            <Text
              mb={{ base: "0.9rem", md: "1.5rem" }}
              _hover={{ textDecoration: "underline" }}
            >
              <Link to={routeObj.privacy}>Privacy policy</Link>
            </Text>
          </Box>
          <Box fontSize={customStyle.fontSize.navItem} fontWeight="600">
            <Heading
              color="#999FAA"
              mb={{ base: "1.2rem", md: "2rem" }}
              fontWeight={"400"}
              fontSize={{ base: "1rem", md: "1.7rem" }}
            >
              Support
            </Heading>

            <Text
              mb={{ base: "0.9rem", md: "1.5rem" }}
              _hover={{ textDecoration: "underline" }}
            >
              <Link to="/">Help and support</Link>
            </Text>
            <Text
              mb={{ base: "0.9rem", md: "1.5rem" }}
              _hover={{ textDecoration: "underline" }}
            >
              <chakra.a href="#faq">FAQ</chakra.a>
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
