import {
  Box,
  Card,
  Heading,
  Text,
  Avatar,
  Flex,
  Center,
  CardBody,
  Image,
} from "@chakra-ui/react";
import Layout from "../../layouts/dashboard/Layout";
import { courseCard } from "../../utils/data";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { routeObj } from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCompletedCourses,
  setCompletedCourses,
} from "../../features/courseSlice";
import courseService from "../../app/services/courseService";
import ThreeCardSkeleton from "../../components/skeletons/ThreeCardSkeleton";
import EmptyState from "../../components/skeletons/EmptyState";

export default function Certification() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const completedCourses = useSelector(selectCompletedCourses);

  useEffect(() => {
    // console.log("completedCourses", completedCourses);
    if (!completedCourses) {
      setIsLoading(true);
      courseService
        .getFinishedCourses()
        .then((res) => {
          if (res) {
            dispatch(setCompletedCourses(res.data));
            setIsLoading(false);
            // console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [completedCourses]);
  return (
    <Layout>
      <Box
        pt={{ base: "2rem", md: "96px" }}
        pe={{ base: "1rem", md: "59px" }}
        pb="200px"
        ps={{ base: "1rem", md: "114px" }}
      >
        <Box color="font.main">
          <Heading
            textAlign="center"
            fontSize={{ base: "1.5rem", md: "50px" }}
            fontWeight="600"
          >
            Course details
          </Heading>
          <Text
            textAlign="center"
            mt="8px"
            color="font.dark"
            fontSize={{ base: "1.2rem", md: "24px" }}
            fontWeight="400"
          >
            Select any course below to view details below
          </Text>
          {isLoading ? (
            <ThreeCardSkeleton />
          ) : completedCourses?.length ? (
            <Flex
              columnGap="20px"
              rowGap="20px"
              flexWrap="wrap"
              flexDirection={{ base: "column", md: "row" }}
              pt={{ base: "30px", md: "64px" }}
              pb="30px"
            >
              {completedCourses?.map((item, index) => (
                <Link
                  to={`${routeObj.certificationDetail}${item.id}`}
                  // style={{ width: "30%", r }}
                >
                  <Card
                    key={index}
                    bg={"white"}
                    color={"font.dark"}
                    w={{ base: "100%", md: "290px" }}
                    h={{ base: "120px", md: "198px" }}
                    transition="0.3s"
                    cursor="pointer"
                    lineHeight="31px"
                    boxShadow="lg"
                  >
                    <CardBody
                      // px="4rem"
                      justifyContent="center"
                      alignContent={"center"}
                      display="flex"
                    >
                      <Center columnGap="15px">
                        {/* <Avatar
                        boxSize={{ base: "2rem", md: "46px" }}
                        src={item.imageUrl}
                      /> */}
                        <Text
                          fontWeight="500"
                          fontSize={{ base: "0.9rem", md: "1.2rem" }}
                        >
                          {item.name}
                        </Text>
                      </Center>
                    </CardBody>
                  </Card>
                </Link>
              ))}
            </Flex>
          ) : (
            <EmptyState message="All certificate will be dispalyed here" />
          )}
        </Box>
      </Box>
    </Layout>
  );
}
