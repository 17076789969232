import {
  Box,
  Flex,
  Button,
  Heading,
  Text,
  Icon,
  Center,
  Image,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Card,
  CardBody,
  ModalCloseButton,
  CardFooter,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { BsArrowLeft, BsThreeDotsVertical } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useParams } from "react-router-dom";
import { routeObj } from "../../constants/routes";
import { useState, useEffect } from "react";

import Layout from "../../layouts/dashboard/Layout";
import courseService from "../../app/services/courseService";
import { dateTimeFormatter } from "../../utils";
import ThreeCardSkeleton from "../../components/skeletons/ThreeCardSkeleton";
import Pagination from "react-js-pagination";
import "bootstrap/dist/css/bootstrap.min.css";
import EmptyState from "../../components/skeletons/EmptyState";

export default function CourseLectures() {
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  const [lectures, setLectures] = useState<Lectures[]>([]);
  const [courseName, setCourseName] = useState("");
  const [batchStatus, setBatchStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [pageData, setPageData] = useState<any>();
  // const [date, setDate] = useState<string>();
  const { batchId } = useParams();
  // Count down function
  function countDown(date: string) {
    var countDownDate = new Date(date!).getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (days < 0 && minutes < 0 && seconds < 0 && hours < 0) {
        setDays(0);
        setMinutes(0);
        setSeconds(0);
        setHours(0);
        setIsCompleted(true);
        return;
      } else {
        setDays(days);
        setMinutes(minutes);
        setSeconds(seconds);
        setHours(hours);
      }
    }, 1000);
  }
  useEffect(() => {
    courseService
      .getBatchById(batchId!)
      .then((res) => {
        // console.log(res);
        // console.log(dateTimeFormatter(res.data.start_date));
        setCourseName(res.data.coursebatch.name);
        setBatchStatus(res.data.coursebatch.status);
        countDown(res.data.coursebatch.start_date);
        setIsLoading(false);
        if (res.data.coursebatch.status === "EXPIRED") {
          onOpen();
        }
      })
      .catch((err) => {
        console.log(err);
      });
    courseService
      .getLecturesByBatch(batchId!)
      .then((res) => {
        // console.log(res);
        setLectures(res.data.data);
        setPageData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Layout>
      {/* isCompleted &&  */}
      {isLoading ? (
        <Box px="2rem" py="2rem">
          <ThreeCardSkeleton />
        </Box>
      ) : batchStatus === "ACTIVE" ? (
        <Box pt="48px" pb="104px" px={{ base: "1rem", md: "68px" }}>
          <Link to={routeObj.courses}>
            <Button
              bg="none"
              columnGap={{ base: "0.4rem", md: "0.8rem" }}
              fontSize="14px"
              fontWeight="600"
              color="font.dark"
              _hover={{}}
            >
              <Icon fontSize="18px" as={BsArrowLeft} />
              Back
            </Button>
          </Link>
          <Box
            as="h4"
            color="white"
            bg="font.main"
            w="100%"
            py={{ base: "1.5rem", md: "39px" }}
            px={{ base: "1rem", md: "25px" }}
            fontSize={{ base: "1rem", md: "1.5rem" }}
            fontWeight="600"
          >
            {/* UX Design: Storyboarding & Prototyping */}
            {courseName}
          </Box>
          <Box pt="16px" color="font.main">
            {lectures.length ? (
              <Box>
                {lectures.map((lecture) => (
                  <Flex
                    mt="16px"
                    justifyContent="space-between"
                    py={{ base: "1.5rem", md: "2.2rem" }}
                    px={{ base: "1rem", md: "2.2rem" }}
                    bg="bg.subMain"
                    key={lecture.id}
                  >
                    <Flex columnGap={{ base: "0.5rem", md: "27px" }}>
                      <Box>
                        <Image
                          boxSize={{ base: "1rem", md: "2.4rem" }}
                          src="/assets/images/v-player.svg"
                        />
                      </Box>
                      <Box>
                        <Heading
                          fontSize={{ base: "0.8rem", md: "1.5rem" }}
                          fontWeight="400"
                        >
                          {/* Introduction to storyboarding(10:12) */}
                          {lecture?.title}
                        </Heading>
                        <Text
                          fontSize={{ base: "0.5rem", md: "1rem" }}
                          color="font.main"
                          fontWeight="400"
                          mt={{ base: "0.4rem", md: "1.3rem" }}
                        >
                          Date: {dateTimeFormatter(lecture?.lecture_time)}
                        </Text>
                      </Box>
                    </Flex>
                    <Center>
                      <Link to={`${routeObj.courseLectureView}${lecture.id}`}>
                        <Button
                          _hover={{ bg: "blue.700", color: "gray.200" }}
                          transition="0.4s"
                          borderRadius={"none"}
                          bg="bg.main"
                          w={{ base: "4.1rem", md: "147px" }}
                          h={{ base: "1.6rem", md: "55px" }}
                          color="#ffffff"
                          fontSize={{ base: "0.8rem", md: "1.3rem" }}
                          fontWeight="600"
                        >
                          View
                        </Button>
                      </Link>
                    </Center>
                  </Flex>
                ))}
                {pageData?.total > pageData?.per_page && (
                  <Flex py="2rem">
                    <Pagination
                      activePage={pageData?.current_page}
                      itemsCountPerPage={pageData?.per_page}
                      totalItemsCount={pageData.total}
                      pageRangeDisplayed={10}
                      itemClass="page-item"
                      linkClass="page-link"
                      firstPageText={"prev"}
                      lastPageText={"next"}
                      onChange={(pageNumber) => {
                        courseService
                          .getLecturesByBatch(batchId!, pageNumber)
                          .then((res) => {
                            // console.log(res);
                            setLectures(res.data.data);

                            // setIsLoading(false);
                          })
                          .catch((err) => {
                            console.log(err);
                          });
                      }}
                    />
                  </Flex>
                )}
              </Box>
            ) : (
              <EmptyState message=" All lectures will be dispalyed here" />
            )}
          </Box>
        </Box>
      ) : (
        <Box pt="48px" pb="104px" px={{ base: "1rem", md: "68px" }}>
          <Link to={routeObj.courses}>
            <Button
              bg="none"
              columnGap={{ base: "0.4rem", md: "0.8rem" }}
              fontSize="14px"
              fontWeight="600"
              color="font.dark"
              _hover={{}}
            >
              <Icon fontSize="18px" as={BsArrowLeft} />
              Back
            </Button>
          </Link>
          <Box pt="2rem">
            <Box
              color="white"
              pt="29px"
              ps="29px"
              w="100%"
              h={{ base: "310px", md: "520px" }}
              bg="bg.main"
              mt={{ base: "1.2rem", md: "2rem" }}
            >
              <Text>
                {" "}
                <Flex columnGap={"6px"} alignContent="center">
                  <Center>
                    <Image
                      w={{ base: "1rem", md: "50%" }}
                      src="/logo/logo.png"
                    />
                  </Center>
                </Flex>
              </Text>
              <Box
                px={{ base: "1rem", md: "120px" }}
                pt={{ base: "0.9rem", md: "3rem" }}
              >
                <Heading
                  fontSize={{ base: "1.2rem", md: "2.2rem" }}
                  fontWeight="500"
                  textAlign="center"
                  lineHeight={{ base: "1.8rem", md: "57.61px" }}
                >
                  Hey, all videos will be uploaded here after the live classes
                </Heading>
                <Center pt={{ base: "1.8rem", md: "49px" }}>
                  <Image
                    boxSize={{ base: "140px", md: "220px" }}
                    src="/assets/images/thumb-up.png"
                  />
                </Center>
              </Box>
            </Box>
          </Box>
          <Flex
            pt="48px"
            columnGap={{ base: "0.5rem", md: "38px" }}
            justifyContent="center"
            color="font.main"
          >
            <Card
              bg="white"
              w={{ base: "80px", md: "183px" }}
              variant="elevated"
              textAlign="center"
            >
              <CardBody>
                <Text
                  fontSize={{ base: "1.5rem", md: "64px" }}
                  fontWeight="700"
                  color="font.main"
                >
                  {days < 1 ? hours : days}
                </Text>
              </CardBody>
              <CardFooter bg="bg.main" py="14px" justifyContent="center">
                <Text
                  textAlign="center"
                  fontSize={{ base: "0.8rem", md: "20px" }}
                  color="white"
                  fontWeight="500"
                >
                  {days < 1 ? "Hours" : "days"}
                </Text>
              </CardFooter>
            </Card>
            <Text
              fontSize={{ base: "32px", lg: "66px" }}
              fontWeight={"bold"}
              pt={{ base: "30px", lg: "40px" }}
            >
              <Icon as={BsThreeDotsVertical} />
            </Text>
            <Card
              bg="white"
              w={{ base: "80px", md: "183px" }}
              variant="elevated"
              textAlign="center"
            >
              <CardBody>
                <Text
                  fontSize={{ base: "1.5rem", md: "64px" }}
                  fontWeight="700"
                  color="font.main"
                >
                  {minutes}
                </Text>
              </CardBody>
              <CardFooter bg="bg.main" py="14px" justifyContent="center">
                <Text
                  textAlign="center"
                  fontSize={{ base: "0.8rem", md: "20px" }}
                  color="white"
                  fontWeight="500"
                >
                  Minutes
                </Text>
              </CardFooter>
            </Card>
            <Text
              fontSize={{ base: "32px", lg: "66px" }}
              fontWeight={"bold"}
              pt={{ base: "30px", lg: "40px" }}
            >
              <Icon as={BsThreeDotsVertical} />
            </Text>
            <Card
              bg="white"
              w={{ base: "80px", md: "183px" }}
              variant="elevated"
              textAlign="center"
            >
              <CardBody>
                <Text
                  fontSize={{ base: "1.5rem", md: "64px" }}
                  fontWeight="700"
                  color="font.main"
                >
                  {seconds}
                </Text>
              </CardBody>
              <CardFooter bg="bg.main" py="14px" justifyContent="center">
                <Text
                  textAlign="center"
                  fontSize={{ base: "0.8rem", md: "20px" }}
                  color="white"
                  fontWeight="500"
                >
                  Seconds
                </Text>
              </CardFooter>
            </Card>
          </Flex>
        </Box>
      )}
    </Layout>
  );
}
