import {
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Image,
  Flex,
  Text,
  Heading,
  Center,
  Card,
  Stack,
  CardBody,
  Icon,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import Layout from "../../layouts/dashboard/Layout";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { routeObj } from "../../constants/routes";
import { useEffect, useState } from "react";
import { customStyle } from "../../utils/style";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActiveCourses,
  selectCompletedCourses,
  selectCourses,
  setActiveCourses,
  setCompletedCourses,
  setCourses,
} from "../../features/courseSlice";
import courseService from "../../app/services/courseService";
import ThreeCardSkeleton from "../../components/skeletons/ThreeCardSkeleton";
import { capitalizeFirstLetter } from "../../utils";
import EmptyState from "../../components/skeletons/EmptyState";
import { ExpiryModal } from "../../components/modal/ExpiryModal";

export default function Courses() {
  const [isLoading, setIsLoading] = useState(true);
  // const [hasExpired, setHasExpired] = useState(false);
  const { isOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const completedCourses = useSelector(selectCompletedCourses);
  const courses = useSelector(selectCourses);
  const activeCourses = useSelector(selectActiveCourses);
  const [topCourses, setTopCourses] = useState<CourseType[]>([]);
  const [standAloneCourses, setStandAloneCourses] = useState<CourseType[]>([]);

  useEffect(() => {
    // if (hasExpired) {
    //   onOpen();
    // }
    // console.log(activeCourses);

    if (!courses?.length) {
      setIsLoading(true);
      courseService
        .getCoursesForUser()
        .then((res) => {
          if (res) {
            dispatch(setCourses({ courses: res.data }));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
    if (!activeCourses) {
      setIsLoading(true);
      courseService
        .getActiveCourses()
        .then((res) => {
          if (res) {
            dispatch(setActiveCourses(res.data));
            setIsLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }

    if (!completedCourses) {
      setIsLoading(true);
      courseService
        .getCompletedCourses()
        .then((res) => {
          if (res) {
            dispatch(setCompletedCourses(res.data));
            setIsLoading(false);
            // console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
    if (courses?.length) {
      const top = courses?.filter((course) => course.course.type === "CAREER");
      const standalone = courses?.filter(
        (course) => course.course.type === "STANDALONE"
      );
      setTopCourses(top);
      // setStandAloneCourses(standalone);

      // console.log(top, standalone);
    }
  }, [courses?.length, activeCourses?.length, completedCourses?.length]);
  return (
    <Layout>
      <Box
        pt={{ base: "3rem", md: "146px" }}
        pe={{ base: "1rem", md: "30px" }}
        ps={{ base: "1rem", md: "70px" }}
      >
        <Tabs variant="unstyled">
          <TabList color="#939AA6" columnGap={{ base: "1.2rem", md: "42px" }}>
            <Tab
              padding={0}
              fontSize={{ base: "0.9rem", md: "20px" }}
              fontWeight="600"
              _selected={{
                color: "font.main",
                borderBottomColor: "#FDE82A",
                borderBottomWidth: "3px",
              }}
            >
              All courses{" "}
            </Tab>
            <Tab
              padding={0}
              fontSize={{ base: "0.9rem", md: "20px" }}
              fontWeight="600"
              _selected={{
                color: "font.main",
                borderBottomColor: "#FDE82A",
                borderBottomWidth: "3px",
              }}
            >
              Active courses{" "}
            </Tab>
            <Tab
              padding={0}
              fontSize={{ base: "0.9rem", md: "20px" }}
              fontWeight="600"
              _selected={{
                color: "font.main",
                borderBottomColor: "#FDE82A",
                borderBottomWidth: "3px",
              }}
            >
              Completed courses{" "}
            </Tab>
          </TabList>
          <TabPanels pt={{ base: "1rem", md: "59px" }}>
            <TabPanel px="0 !important">
              <Box>
                <Heading
                  fontSize={customStyle.fontSize.sectionHeader}
                  color="font.main"
                  mb="1rem"
                  fontWeight={"600"}
                >
                  Top courses
                </Heading>
                {isLoading ? (
                  <ThreeCardSkeleton />
                ) : topCourses?.length ? (
                  <Box display={{ base: "none", md: "block" }}>
                    <Flex
                      pt="3.5rem"
                      pb="5rem"
                      columnGap={"1.7rem"}
                      rowGap="1.7rem"
                      flexWrap="wrap"
                      justifyContent={"left"}
                      display={{ base: "none", md: "flex" }}
                      // minHeight={"40vh"}
                      // maxHeight="100vh"
                      // objectFit={"contain"}
                    >
                      {topCourses?.length &&
                        topCourses?.map((data) => (
                          <Link
                            to={`${routeObj.courseDetailDashboard}${data.course.id}`}
                            style={{ width: "31%" }}
                            key={data.course.id}
                          >
                            <Box
                              mb="10px"
                              borderRadius={"sm"}
                              boxShadow="lg"
                              w="100%"
                              position="relative"
                              h={{ base: "250px", md: "390px", lg: "485px" }}
                            >
                              <Image
                                src={`${data.course.image}`}
                                alt={`Picture of ${data.course.name}`}
                                w="100%"
                                h={{ base: "100px", md: "240px" }}
                                objectFit={"cover"}
                                objectPosition={"center"}
                              />

                              <Box
                                ps="20px"
                                pt="34px"
                                pb="46px"
                                pe="24px"
                                bg="#ffffff"
                              >
                                <Flex padding={0}>
                                  <Box
                                    fontSize="16px"
                                    color="font.main"
                                    fontWeight="700"
                                    as="h4"
                                    title={data.course.name}
                                  >
                                    {capitalizeFirstLetter(
                                      data.course.name?.substring(0, 30)
                                    )}
                                  </Box>
                                </Flex>

                                <Box
                                  pt="20px"
                                  color="font.dark"
                                  fontWeight="400"
                                  fontSize={"16px"}
                                >
                                  <Text>{`${new Date(
                                    data.course.updated_at
                                  ).toLocaleDateString()}`}</Text>
                                  {/* <Text mt="12px">{data.date}</Text> */}
                                  <Flex mt="12px" columnGap={"3px"}>
                                    <Text>Location: </Text>
                                    <Text>Online</Text>
                                  </Flex>
                                  {/* <Flex mt="12px" columnGap={"3px"}>
                                      <Text>Status: </Text>
                                      <Text color={"#2CA900"}>open</Text>
                                    </Flex> */}
                                </Box>
                              </Box>
                            </Box>
                          </Link>
                        ))}
                    </Flex>
                  </Box>
                ) : (
                  <Box display={{ base: "none", md: "block" }}>
                    <EmptyState message="All courses will appear here" />
                  </Box>
                )}
              </Box>
              {/* Mobile view */}
              <Box display={{ base: "block", md: "none" }}>
                {isLoading ? (
                  <ThreeCardSkeleton />
                ) : topCourses?.length ? (
                  <OwlCarousel className="owl-theme" items={1.1} margin={20}>
                    {topCourses?.length &&
                      topCourses?.map((data) => (
                        <Link
                          to={`${routeObj.courseDetailDashboard}${data.course.id}`}
                          key={data.course.id}
                        >
                          <Box
                            mb="10px"
                            borderRadius={"sm"}
                            boxShadow="lg"
                            w="100%"
                            position="relative"
                            h="400px"
                          >
                            <Image
                              src={data.course.image}
                              alt={`Picture of ${data.course.name}`}
                              w="100%"
                              h={{ base: "160px", md: "240px" }}
                              objectFit={"cover"}
                              objectPosition={"center"}
                            />

                            <Box
                              ps="20px"
                              pt="2rem"
                              pb="2rem"
                              pe="24px"
                              bg="#ffffff"
                            >
                              <Flex padding={0}>
                                <Box
                                  fontSize="0.9rem"
                                  color="font.main"
                                  fontWeight="700"
                                  as="h4"
                                >
                                  {capitalizeFirstLetter(data.course.name)}
                                </Box>
                              </Flex>

                              <Box
                                pt="20px"
                                color="font.dark"
                                fontWeight="400"
                                fontSize={"0.8rem"}
                              >
                                <Text>{`${data.course.updated_at}`}</Text>
                                {/* <Text mt="8px">{data.date}</Text> */}
                                <Flex mt="8px" columnGap={"3px"}>
                                  <Text>Location: </Text>
                                  <Text>Online</Text>
                                </Flex>
                                <Flex mt="8px" columnGap={"3px"}>
                                  <Text>Status: </Text>
                                  <Text color={"#2CA900"}>Open</Text>
                                </Flex>
                              </Box>
                            </Box>
                          </Box>
                        </Link>
                      ))}
                  </OwlCarousel>
                ) : (
                  <EmptyState message="All subscriptions will appear here" />
                )}
              </Box>
              {/* Standalone courses */}
              <Box pt={{ base: "70px", md: "100px" }}>
                <Heading
                  fontSize={customStyle.fontSize.sectionHeader}
                  color="font.main"
                  fontWeight={"600"}
                >
                  Standalone courses
                </Heading>
                <Text
                  fontSize={{ base: "0.7rem", md: "16px" }}
                  mt="9px"
                  color="font.dark"
                  fontWeight={"400"}
                >
                  Learn a new skill and upskill with our full packed courses{" "}
                </Text>

                <Box
                  pt={{ base: "2rem", md: "54px" }}
                  pb="180px"
                  display={{ base: "none", md: "block" }}
                >
                  <Flex
                    pt="3.5rem"
                    pb="5rem"
                    columnGap={"1.7rem"}
                    rowGap="1.7rem"
                    flexWrap="wrap"
                    justifyContent={"left"}
                    display={{ base: "none", md: "flex" }}
                  >
                    {isLoading ? (
                      <ThreeCardSkeleton />
                    ) : standAloneCourses?.length ? (
                      standAloneCourses?.length &&
                      standAloneCourses?.map((data) => (
                        <Link
                          to={`${routeObj.courseDetailDashboard}${data.course.id}`}
                          style={{ width: "31%" }}
                          key={data.course.id}
                        >
                          <Box
                            mb="10px"
                            borderRadius={"sm"}
                            boxShadow="lg"
                            w="100%"
                            position="relative"
                            h={{ base: "250px", md: "390px", lg: "485px" }}
                          >
                            <Image
                              src={`${data.course.image}`}
                              alt={`Picture of ${data.course.name}`}
                              w="100%"
                              h={{ base: "100px", md: "240px" }}
                              objectFit={"cover"}
                              objectPosition={"center"}
                            />

                            <Box
                              ps="20px"
                              pt="34px"
                              pb="46px"
                              pe="24px"
                              bg="#ffffff"
                            >
                              <Flex padding={0}>
                                <Box
                                  fontSize="16px"
                                  color="font.main"
                                  fontWeight="700"
                                  as="h4"
                                >
                                  {capitalizeFirstLetter(data.course.name)}
                                </Box>
                              </Flex>

                              <Box
                                pt="20px"
                                color="font.dark"
                                fontWeight="400"
                                fontSize={"16px"}
                              >
                                <Text>{`${new Date(
                                  data.course.updated_at
                                ).toLocaleDateString()}`}</Text>
                                {/* <Text mt="12px">{data.date}</Text> */}
                                <Flex mt="12px" columnGap={"3px"}>
                                  <Text>Location: </Text>
                                  <Text>Online</Text>
                                </Flex>
                                {/* <Flex mt="12px" columnGap={"3px"}>
                                <Text>Status: </Text>
                                <Text color={"#2CA900"}>open</Text>
                              </Flex> */}
                              </Box>
                            </Box>
                          </Box>
                        </Link>
                      ))
                    ) : (
                      <Box w="100%">
                        <EmptyState message="All courses will appear here" />
                      </Box>
                    )}
                  </Flex>
                </Box>
                {/* Mobile view */}
                <Box
                  pt="54px"
                  pb="180px"
                  display={{ base: "block", md: "none" }}
                >
                  {isLoading ? (
                    <ThreeCardSkeleton />
                  ) : standAloneCourses?.length ? (
                    <OwlCarousel className="owl-theme" items={1.1} margin={20}>
                      {standAloneCourses?.length &&
                        standAloneCourses?.map((data) => (
                          <Link
                            to={`${routeObj.courseDetailDashboard}${data.course.id}`}
                            key={data.course.id}
                          >
                            <Box
                              mb="10px"
                              borderRadius={"sm"}
                              boxShadow="lg"
                              w="100%"
                              h={"400px"}
                              position="relative"
                            >
                              <Image
                                src={data.course.image}
                                alt={`Picture of ${data.course.name}`}
                                w="100%"
                                h="150px"
                                objectFit={"cover"}
                                objectPosition={"center"}
                              />

                              <Box
                                ps="20px"
                                pt="2rem"
                                pb="2rem"
                                pe="24px"
                                bg="#ffffff"
                              >
                                <Flex padding={0}>
                                  <Box
                                    fontSize="0.9rem"
                                    color="font.main"
                                    fontWeight="700"
                                    as="h4"
                                  >
                                    {capitalizeFirstLetter(data.course.name)}
                                  </Box>
                                </Flex>

                                <Box
                                  pt="20px"
                                  color="font.dark"
                                  fontWeight="400"
                                  fontSize={"0.8rem"}
                                >
                                  <Text>{`${data.course.updated_at}`}</Text>
                                  {/* <Text mt="8px">{data.date}</Text> */}
                                  <Flex mt="8px" columnGap={"3px"}>
                                    <Text>Location: </Text>
                                    <Text>Online</Text>
                                  </Flex>
                                  {/* <Flex mt="8px" columnGap={"3px"}>
                            <Text>Status: </Text>
                            <Text color={"#2CA900"}>open</Text>
                          </Flex> */}
                                </Box>
                              </Box>
                            </Box>
                          </Link>
                        ))}
                    </OwlCarousel>
                  ) : (
                    <EmptyState message="All courses will appear here" />
                  )}
                </Box>
              </Box>
            </TabPanel>
            <TabPanel pb="350px" px="0 !important">
              {isLoading ? (
                <ThreeCardSkeleton />
              ) : activeCourses?.length ? (
                <>
                  {activeCourses?.map((course) => (
                    <Card
                      direction={"row"}
                      overflow="hidden"
                      key={course.name}
                      variant="elevated"
                      pt="18px"
                      ps={{ base: "10px", md: "20px" }}
                      pb="17px"
                      pe={{ base: "10px", md: "29px" }}
                      mt="40px"
                      backgroundColor={"white !important"}
                    >
                      <Image
                        objectFit="cover"
                        maxW={{ base: "5rem", sm: "200px" }}
                        src={`${course.image}`}
                        alt="Caffe Latte"
                      />

                      <Stack
                        ps={{ base: "0.6rem", md: "33px" }}
                        justifyContent="space-between"
                        flexDirection="row"
                        w="100%"
                      >
                        <CardBody padding={0}>
                          <Heading
                            fontSize={customStyle.fontSize.cardTitle}
                            fontWeight="600"
                            color="font.main"
                          >
                            {course.course_name}
                            {" - "}
                            {capitalizeFirstLetter(
                              capitalizeFirstLetter(
                                course.batch_name.replace("-", " ")
                              )
                            )}
                          </Heading>

                          <Text
                            fontSize={{ base: "12px", md: "16px" }}
                            fontWeight="400"
                            color="font.dark"
                          >
                            {`by ${course.first_name} ${course.last_name}`}
                          </Text>
                          <Text
                            fontSize={{ base: "12px", md: "16px" }}
                            fontWeight="400"
                            color="#CA0404"
                            mt={{ base: "1rem", md: "56px" }}
                          >
                            {course.status !== "EXPIRED" ? (
                              <>
                                {course?.sessionCompleted} lecture(s) Completed
                              </>
                            ) : (
                              course.status
                            )}
                          </Text>
                        </CardBody>
                        <Center>
                          <Button
                            _hover={{ bg: "blue.700", color: "gray.200" }}
                            transition="0.4s"
                            borderRadius={"none"}
                            bg="bg.main"
                            w={{ base: "100%", md: "203px" }}
                            h={{ base: "2.5rem", md: "56px" }}
                            color="#ffffff"
                            title={
                              course.status === "EXPIRED"
                                ? "Your subscription has expired, renew subscription to continue viewing lectures"
                                : "View lectures"
                            }
                            columnGap="8px"
                            cursor={"pointer"}
                            isDisabled={course.status === "EXPIRED"}
                            onClick={() => {
                              navigate(
                                `${routeObj.courseLectures}${course.id}`
                              );
                            }}
                            fontSize={{ base: "10px", md: "20px" }}
                            fontWeight="700"
                          >
                            View lectures
                            <Icon as={AiOutlineArrowRight} />
                          </Button>
                        </Center>
                      </Stack>
                    </Card>
                  ))}
                </>
              ) : (
                <EmptyState message="All active courses will appear here" />
              )}
            </TabPanel>
            <TabPanel pb="350px" px="0 !important">
              {isLoading ? (
                <ThreeCardSkeleton />
              ) : completedCourses?.length ? (
                <>
                  {completedCourses?.map((course) => (
                    <Card
                      direction={"row"}
                      overflow="hidden"
                      variant="elevated"
                      key={capitalizeFirstLetter(course.name)}
                      pt="18px"
                      ps={{ base: "10px", md: "20px" }}
                      pb="17px"
                      pe={{ base: "10px", md: "29px" }}
                      mt="40px"
                      backgroundColor={"white !important"}
                    >
                      <Image
                        objectFit="cover"
                        maxW={{ base: "5rem", sm: "200px" }}
                        src={`${course.image}`}
                        alt="Caffe Latte"
                      />

                      <Stack
                        ps={{ base: "0.6rem", md: "33px" }}
                        justifyContent="space-between"
                        flexDirection="row"
                        w="100%"
                      >
                        <CardBody padding={0}>
                          <Heading
                            fontSize={customStyle.fontSize.cardTitle}
                            fontWeight="600"
                            color="font.main"
                          >
                            {course.course_name}
                            {" - "}
                            {capitalizeFirstLetter(
                              course.name.replace("-", " ")
                            )}
                          </Heading>

                          <Text
                            fontSize={{ base: "12px", md: "16px" }}
                            color="font.dark"
                            fontWeight="400"
                          >
                            {`by ${course.first_name} ${course.last_name}`}
                          </Text>
                          <Text
                            fontSize={{ base: "12px", md: "16px" }}
                            fontWeight="400"
                            color="#407BFF"
                            mt={{ base: "1rem", md: "56px" }}
                          >
                            <Flex columnGap="8px" alignContent={"center"}>
                              Completed <Icon mt="3px" as={FaCheckCircle} />
                            </Flex>
                          </Text>
                        </CardBody>
                        <Center>
                          <Link
                            to={`${routeObj.certificationDetail}${course.id}`}
                          >
                            <Button
                              _hover={{ bg: "blue.700", color: "gray.200" }}
                              transition="0.4s"
                              borderRadius={"none"}
                              bg="bg.main"
                              w={{ base: "100%", md: "203px" }}
                              h={{ base: "2.5rem", md: "56px" }}
                              color="#ffffff"
                              columnGap="8px"
                              fontSize={{ base: "10px", md: "20px" }}
                              fontWeight="700"
                            >
                              View certificate
                              <Icon as={AiOutlineArrowRight} />
                            </Button>
                          </Link>
                        </Center>
                      </Stack>
                    </Card>
                  ))}
                </>
              ) : (
                <EmptyState message="All completed courses will appear here" />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
}
