import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Box,
  ModalOverlay,
  Heading,
  Text,
  Center,
  Flex,
  Icon,
  Card,
  CardBody,
  Avatar,
  Spinner,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { routeObj } from "../../constants/routes";
import { courseCard } from "../../utils/data";
import { BsArrowRight } from "react-icons/bs";
import { customStyle } from "../../utils/style";
import userService from "../../app/services/userService";
import { useCustomToast } from "../../utils/toast";
import { selectCurrentUser, setUser } from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export default function InterestModal(props: Props) {
  const [coursesData, setCoursesData] = useState<
    {
      id: number;
      name: string;
      imageUrl: string;
      hoverImg: string;
    }[]
  >();
  const [selectedId, setSelectedId] = useState(0);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const handleSelect = (index: number) => {
    setSelectedId(index + 1);
  };
  const handleSubmit = () => {
    if (!coursesData) {
      return;
    }
    // console.log();
    setIsLoading(true);
    userService
      .updateProfile(
        {
          interest: coursesData[selectedId].name,
        },
        user?.id!
      )
      .then((res) => {
        // console.log(res);
        notifySuccess("Interest updated successfully");
        dispatch(setUser(res));
        setIsLoading(false);
      })
      .catch((err) => {
        notifyError("Error occured");
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    setCoursesData(courseCard);
  }, []);
  return (
    <Modal
      isOpen={props.isOpen}
      size={{ base: "xs", md: "4xl" }}
      onClose={props.onClose}
      closeOnOverlayClick={false}
      scrollBehavior={"inside"}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(10deg)"
      />
      <ModalContent>
        {/* <ModalCloseButton color="font.main" /> */}
        <ModalBody
          bg="white"
          color="font.main"
          overflowX="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "4px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Box
            bgImage={"/assets/images/course-card-bg.png"}
            bgSize="contain"
            bgRepeat={"no-repeat"}
            px={{ base: "0.8rem", md: "1rem" }}
            pt="100px"
            pb="104px"
            color="font.main"
          >
            <Heading
              textAlign="center"
              fontSize={customStyle.fontSize.sectionHeader}
              fontWeight="600"
            >
              Select course you are interested in
            </Heading>
            <Flex
              columnGap="20px"
              rowGap="20px"
              flexWrap="wrap"
              pt={{ base: "30px", md: "4rem" }}
              pb="30px"
            >
              {coursesData?.map((item, index) => (
                <Card
                  key={index}
                  bg={selectedId === index + 1 ? "bg.main" : "white"}
                  color={selectedId === index + 1 ? "white" : "font.dark"}
                  w={{ base: "100%", md: "31%" }}
                  h={{ base: "120px", md: "198px" }}
                  transition="0.3s"
                  cursor="pointer"
                  onClick={() => handleSelect(index)}
                  lineHeight="31px"
                  boxShadow="lg"
                >
                  <CardBody
                    // px="4rem"
                    justifyContent="center"
                    alignContent={"center"}
                    display="flex"
                  >
                    <Center columnGap="15px">
                      <Avatar
                        boxSize={{ base: "2rem", md: "46px" }}
                        src={
                          selectedId === index + 1
                            ? item.hoverImg
                            : item.imageUrl
                        }
                      />
                      <Text
                        fontWeight="500"
                        fontSize={{ base: "0.9rem", md: "1.2rem" }}
                      >
                        {item.name}
                      </Text>
                    </Center>
                  </CardBody>
                </Card>
              ))}
            </Flex>
            <Center pt={{ base: "1.3rem", md: "2rem" }}>
              <Button
                display="flex"
                w={{ base: "100%", md: "1049px" }}
                _hover={{ bg: "blue.700", color: "gray.200" }}
                transition="0.6s"
                borderRadius={"sm"}
                isDisabled={selectedId < 1 || isLoading}
                bg="bg.main"
                py={{ base: "1.4rem", md: "2rem" }}
                color="#ffffff"
                onClick={handleSubmit}
                justifyContent={"center"}
                fontSize={{ base: "14px", md: "1.2rem" }}
              >
                <Center columnGap="20px">
                  {" "}
                  CONTINUE
                  <Icon as={BsArrowRight} />
                  <Spinner display={isLoading ? "inline-flex" : "none"} />
                </Center>
              </Button>
            </Center>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
