import {
  Box,
  Card,
  Heading,
  Text,
  Avatar,
  Flex,
  Center,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Spinner,
} from "@chakra-ui/react";
import Layout from "../../layouts/dashboard/Layout";
import { customStyle } from "../../utils/style";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/authSlice";
import activityServices from "../../app/services/activityServices";
import { momentsAgo } from "../../utils";
import {
  selectNotifications,
  setNotifications,
} from "../../features/notificationSlice";

export default function Notifications() {
  const user = useSelector(selectCurrentUser);
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();
  const [todayNotifications, setTodayNotifications] =
    useState<NotificationType[]>();
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date().getTime();
  const day = 86400 * 1000;
  useEffect(() => {
    setIsLoading(true);
    if (!notifications) {
      setIsLoading(true);
      activityServices
        .getNotifications(user?.id!)
        .then((res) => {
          // console.log(res);
          setIsLoading(false);
          dispatch(setNotifications(res.data.data));
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      const todayNot = notifications?.filter((data: any) => {
        const date = new Date(data.created_at);
        const difference = today - date.getTime();
        return difference < day ? data : null;
      });
      // console.log(todayNot);
      setTodayNotifications(todayNot);
    }
  }, [notifications]);
  return (
    <Layout>
      <Box
        pt="84px"
        pe={{ base: "1rem", md: "59px" }}
        pb="200px"
        ps={{ base: "1rem", md: "72px" }}
      >
        <Heading
          color="font.dark"
          fontSize={{ base: "2rem", md: "40px" }}
          fontWeight="600"
        >
          Notifications
        </Heading>

        <Card
          bg="white"
          mt="40px"
          variant="elevated"
          pt={{ base: "1.9rem", md: "40px" }}
          ps="24px"
          pb={{ base: "1.9rem", md: "41px" }}
        >
          <Heading
            color="font.main"
            fontSize={{ base: "1.2rem", md: "24px" }}
            fontWeight="600"
          >
            Today
          </Heading>
          <Accordion defaultIndex={[0]} allowMultiple>
            <Center display={isLoading ? "flex" : "none"}>
              <Spinner />
            </Center>
            {todayNotifications?.map((data, index) => (
              <AccordionItem
                borderBottomColor={"#C4C4C4"}
                borderBottomWidth="1px"
                key={index}
              >
                <AccordionButton
                  pt={{ base: "1.8rem", md: "40px" }}
                  pb={{ base: "1.5rem", md: "32px" }}
                  justifyContent={"space-between"}
                  display="flex"
                >
                  <Flex
                    ps={{ base: "0", md: "29.67px" }}
                    pe={{ base: "1rem", md: "26.5px" }}
                    columnGap={{ base: "1.4rem", md: "32px" }}
                    textAlign="left"
                  >
                    <Center>
                      <Avatar
                        boxSize={{ base: "2rem", md: "3rem" }}
                        src="/assets/images/icon.png"
                      />
                    </Center>
                    <Box color="font.dark">
                      <Text
                        mt={{ base: "0", md: "9px" }}
                        fontSize={{ base: "1rem", md: "20px" }}
                        fontWeight="400"
                        lineHeight={{ base: "1rem", md: "20px" }}
                      >
                        {data.title}
                      </Text>
                      <Text
                        mt="12px"
                        fontWeight={"500"}
                        fontSize="14px"
                        color="font.main"
                      >
                        {" "}
                        {momentsAgo(data.created_at!)}
                      </Text>
                    </Box>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel
                  pt={{ base: "1rem", md: "40px" }}
                  pb={{ base: "1rem", md: "32px" }}
                  px={{ base: "0.5rem", md: "27px" }}
                  fontSize={customStyle.fontSize.paragragh}
                >
                  {data.message}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
          <Text
            mt="16px"
            color="font.dark"
            fontSize={{ base: "0.8rem", md: "16px" }}
            fontWeight="400"
            display={todayNotifications?.length ? "none" : "block"}
          >
            No new notifications
          </Text>
        </Card>

        <Card
          bg="white"
          color="font.dark"
          mt={{ base: "2.6rem", md: "56px" }}
          variant="elevated"
          pt="40px"
        >
          <Heading
            color="font.main"
            fontSize={{ base: "1.2rem", md: "24px" }}
            fontWeight="600"
            ps="24px"
          >
            All (
            {isLoading ? <Spinner boxSize={"10px"} /> : notifications?.length}){" "}
          </Heading>
          <Box pt="10px">
            <Accordion defaultIndex={[0]} allowMultiple>
              <Center display={isLoading ? "flex" : "none"}>
                <Spinner />
              </Center>
              {notifications?.length &&
                notifications?.map((data, index) => (
                  <AccordionItem
                    borderBottomColor={"#C4C4C4"}
                    borderBottomWidth="1px"
                    key={index}
                  >
                    <AccordionButton
                      pt={{ base: "1.8rem", md: "40px" }}
                      pb={{ base: "1.5rem", md: "32px" }}
                      justifyContent={"space-between"}
                      display="flex"
                    >
                      <Flex
                        ps={{ base: "0", md: "29.67px" }}
                        pe={{ base: "1rem", md: "26.5px" }}
                        columnGap={{ base: "1.4rem", md: "32px" }}
                        textAlign="left"
                      >
                        <Center>
                          <Avatar
                            boxSize={{ base: "2rem", md: "3rem" }}
                            src="/assets/images/icon.png"
                          />
                        </Center>
                        <Box color="font.dark">
                          <Text
                            mt={{ base: "0", md: "9px" }}
                            fontSize={{ base: "1rem", md: "20px" }}
                            fontWeight="400"
                            lineHeight={{ base: "1rem", md: "20px" }}
                          >
                            {data.title}
                          </Text>
                          <Text
                            mt="12px"
                            fontWeight={"500"}
                            fontSize="14px"
                            color="font.main"
                          >
                            {" "}
                            {momentsAgo(data.created_at!)}
                          </Text>
                        </Box>
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>

                    <AccordionPanel
                      pt={{ base: "1rem", md: "40px" }}
                      pb={{ base: "1rem", md: "32px" }}
                      px={{ base: "0.5rem", md: "27px" }}
                      fontSize={customStyle.fontSize.paragragh}
                    >
                      {data.message}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
            </Accordion>
          </Box>
        </Card>
      </Box>
    </Layout>
  );
}
