import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { routeObj } from "../constants/routes";
import { Link } from "react-router-dom";
import { customStyle } from "../utils/style";

export default function Banner() {
  return (
    <Box>
      <Flex
        // backgroundImage={"/assets/images/home"}

        display={{ base: "none", md: "flex" }}
        w="100%"
        padding={0}
        h="100vh"
      >
        <Box flex={1} pt="10rem" ps={customStyle.padding.psHome}>
          <Stack>
            <Box>
              <Heading
                color="font.main"
                fontWeight={"600"}
                textOverflow={"ellipsis"}
                fontSize={customStyle.fontSize.bannerText}
                mb="1.5rem"
                lineHeight="4.5rem"
              >
                Online school for creatives seeking to learn a new skill
              </Heading>
              <Text>
                Our online school offers a flexible, and comprehensive learning
                experience that allows you to explore and unlock your creative
                potential.
              </Text>
            </Box>

            <Box pt="13px">
              <Link to={routeObj.register}>
                <Button
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.4s"
                  borderRadius={"none"}
                  bg="bg.main"
                  px="4rem"
                  py="1.8rem"
                  color="#ffffff"
                  columnGap="1rem"
                  fontSize="1.5rem"
                  fontWeight={customStyle.fontWeight.navItem}
                >
                  Get Started
                  <Icon as={AiOutlineArrowRight} />
                </Button>
              </Link>
            </Box>
          </Stack>
        </Box>
        <Box h="80vh" py="4rem" px="3rem" flex={1}>
          <Box
            bgRepeat="no-repeat"
            bgSize={"contain"}
            w="100%"
            h="100%"
            // zIndex={-1}
            bgImage="/assets/images/banner.png"
          />
        </Box>
      </Flex>
      <Stack
        zIndex={-1}
        bg="bg.subMain"
        display={{ base: "block", md: "none" }}
      >
        <Box
          w="100%"
          pb=".5rem"
          mb=".5rem"
          pt="1rem"
          style={{ backgroundSize: "cover" }}
          backgroundImage={"/assets/images/home-banner-mobile.png"}
          backgroundSize="cover"
          bgPosition={"center"}
          px="3rem"
        >
          <Heading
            textAlign="center"
            color="font.main"
            fontWeight={"600"}
            fontSize={"2rem"}
            py="1rem"
          >
            Online school for creatives seeking to learn a new skill
          </Heading>
          <Text fontWeight="400" fontSize="1rem" px="0.5rem">
            Our online school offers a flexible, and comprehensive learning
            experience that allows you to explore and unlock your creative
            potential.
          </Text>
        </Box>
        <Flex justifyContent="center">
          <Link to={routeObj.register}>
            <Button
              _hover={{ bg: "blue.700", color: "gray.200" }}
              transition="0.4s"
              borderRadius={"none"}
              bg="bg.main"
              w="300px"
              py="1.8rem"
              color="#ffffff"
              columnGap="1rem"
              fontSize="1rem"
              fontWeight={customStyle.fontWeight.navItem}
            >
              Get Started
              <Icon as={AiOutlineArrowRight} />
            </Button>
          </Link>
        </Flex>
        <Box h="80vh" px="1rem" py="1rem">
          <Box
            bgRepeat="no-repeat"
            bgSize={"cover"}
            w="100%"
            h="100%"
            // zIndex={-1}
            bgImage="/assets/images/banner-mobile.png"
          />
        </Box>
      </Stack>
    </Box>
  );
}
