import {
  Box,
  Center,
  Flex,
  FormControl,
  Heading,
  Input,
  Button,
  chakra,
  Icon,
  InputGroup,
  InputRightElement,
  IconButton,
  Text,
  Divider,
  Spinner,
  useDisclosure,
  Modal,
  ModalOverlay,
  Image,
} from "@chakra-ui/react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { BiShow, BiHide } from "react-icons/bi";
import { FcGoogle } from "react-icons/fc";
import { routeObj } from "../../constants/routes";
import useAuth from "../../hooks/useAuth";
import { useCustomToast } from "../../utils/toast";
import { useDispatch, useSelector } from "react-redux";
import authService from "../../app/services/authService";
import { selectCurrentUser, setCredentials } from "../../features/authSlice";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
export default function Login() {
  const [show, setShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const session = searchParams.get("sessionExpired");
  const redirect = searchParams.get("redirect");
  const inputFontSize = { base: "16px", md: "1.2rem" };
  const { notifyError, notifySuccess } = useCustomToast();
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const route = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const loginFormik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Must be 8 characters or above")
        .required("Required"),

      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      // console.log(JSON.stringify(values));
      authService
        .login(values)
        .then((data) => {
          // console.log(data);
          if (data?.data?.auth) {
            dispatch(setCredentials(data?.data));
            notifySuccess("Login success");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          notifyError(err.response.data.message);
        });
    },
  });
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      // console.log(codeResponse);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then(async (res) => {
          // console.log(res.data);
          setIsLoading(true);
          const resp = {
            email: res.data.email,
            password: res.data.id,
          };
          authService
            .login(resp)
            .then((data) => {
              // console.log(data);
              if (data?.data) {
                dispatch(setCredentials(data?.data));
              }
              setIsLoading(false);
              notifySuccess("Login success");
              onClose();
            })
            .catch((err) => {
              console.log(err);
              setIsLoading(false);
              notifyError(err.response.data.message);
              onClose();
            });
          onClose();
        })
        .catch((err) => {
          onClose();
          console.log(err);
          notifyError(err);
        });
    },
    onError: (error) => {
      onClose();
      console.log("Login Failed:", error);
    },
  });
  useEffect(() => {
    if (user) {
      if (redirect) {
        route(redirect);
      } else {
        route("/dashboard");
      }
    }
  }, [user]);
  useEffect(() => {
    if (session) {
      notifyError("Session expired, please login again");
    }
  }, [session]);
  return (
    <Box margin={0}>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(2px) hue-rotate(10deg)"
        />
      </Modal>
      <Flex
        bg="bg.subMain"
        minH="100vh"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Box
          bgImage={{
            base: "/assets/images/auth-mobile.png",
            md: "/assets/images/auth-bg.png",
          }}
          pos="relative"
          bgSize="cover"
          bgPosition={{ base: "bottom", md: "top" }}
          minH={{ base: "36vh", md: "100vh" }}
          w="100%"
          flex={1.2}
        >
          <Box pt="2.4rem" ps="2.5rem">
            {" "}
            <Link to={routeObj.home}>
              <Image
                // w={"100%"}
                src="/logo/logo.png"
                display={{ base: "none", md: "inline-flex" }}
              />
            </Link>
          </Box>
          <Heading
            color="white"
            pos="absolute"
            top="40%"
            left="10%"
            fontWeight="bold"
            fontSize="32px"
            display={{ base: "block", md: "none" }}
          >
            Welcome <br /> back !
          </Heading>
        </Box>
        <Box
          px={{ base: "20px", md: "3rem" }}
          py={{ base: "38px", md: "5rem" }}
          flex={1}
          color="font.dark"
          bg="bg.subMain"
        >
          <Heading
            display={{ base: "none", md: "block" }}
            color="font.dark"
            fontWeight="700"
            fontSize={{ base: "1.3rem", md: "2rem" }}
          >
            Welcome back!
          </Heading>
          <Box pt={{ base: "1rem", md: "2.5rem" }}>
            <form onSubmit={loginFormik.handleSubmit}>
              <FormControl
                // mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                py="0.9rem"
              >
                <Input
                  placeholder="Email"
                  borderColor="#C9C9C9"
                  name="email"
                  value={loginFormik.values.email}
                  onChange={loginFormik.handleChange}
                  onBlur={loginFormik.handleBlur}
                  color="font.dark"
                  _placeholder={{ color: "font.dark" }}
                  fontSize={inputFontSize}
                  fontWeight={"400"}
                  variant={"unstyled"}
                  isDisabled={isLoading}
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {loginFormik.errors.email && loginFormik.errors.email}
              </Text>

              <FormControl
                mt="1rem"
                bg={{ base: "white", md: "none" }}
                borderBottomColor={"#2a2b2c5e"}
                borderBottomWidth={{ base: "", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                py="0.9rem"
              >
                <InputGroup bg={{ base: "white", md: "none" }}>
                  <Input
                    fontSize={inputFontSize}
                    fontWeight={"400"}
                    variant={"unstyled"}
                    placeholder="Password"
                    name="password"
                    value={loginFormik.values.password}
                    onChange={loginFormik.handleChange}
                    onBlur={loginFormik.handleBlur}
                    borderColor="#C9C9C9"
                    color="font.dark"
                    _placeholder={{ color: "font.dark" }}
                    type={show ? "text" : "password"}
                    isDisabled={isLoading}
                    autoComplete="new-password"
                  />
                  <InputRightElement width="4.5rem">
                    <IconButton
                      bg={"none"}
                      fontSize={inputFontSize}
                      aria-label="Call Segun"
                      onClick={() => setShow(!show)}
                      icon={show ? <BiHide /> : <BiShow />}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Text color="red" fontSize="12px">
                {loginFormik.errors.password && loginFormik.errors.password}
              </Text>
              <Text
                color={"#407BFF"}
                textAlign="right"
                fontSize={{ base: "13px", md: "20px" }}
                mt="10px"
              >
                <Link to={routeObj.forgotPassword}>Forgot password?</Link>
              </Text>

              <Center mt="1.9rem">
                <Button
                  display="flex"
                  type="submit"
                  w={{ base: "100%", md: "100%" }}
                  alignSelf={"center"}
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.6s"
                  borderRadius={"sm"}
                  bg="bg.main"
                  h="4rem"
                  color="#ffffff"
                  justifyContent={"center"}
                  fontSize={"1.1rem"}
                  isDisabled={isLoading}
                >
                  <Center columnGap="20px">
                    SIGN IN{" "}
                    <Spinner display={isLoading ? "inline-flex" : "none"} />
                  </Center>
                </Button>
              </Center>
            </form>

            <Center mt="1.5rem" columnGap="20px">
              <Divider orientation="horizontal" borderColor="#C9C9C9" />
              <Text fontSize={inputFontSize}>or</Text>
              <Divider orientation="horizontal" borderColor="#C9C9C9" />
            </Center>
            {/* 
            <Center mt="1.5rem">
              <Button
                // href="#"
                onClick={() => {
                  onOpen();
                  googleLogin();
                }}
                display="flex"
                w={{ base: "100%", md: "100%" }}
                alignSelf={"center"}
                _hover={{ bg: "blue.700", color: "gray.200" }}
                transition="0.6s"
                borderRadius={"sm"}
                bg="none"
                borderColor="bg.main"
                borderWidth={"1px"}
                borderStyle="solid"
                // py="12px"
                px="50px"
                h="4rem"
                color="font.dark"
                justifyContent={"center"}
                isDisabled={isLoading}
                fontWeight="400"
              >
                <Center
                  columnGap={{ base: "10px", md: "1rem" }}
                  fontSize={inputFontSize}
                >
                  <Icon
                    as={FcGoogle}
                    fontSize={{ base: "30px", md: "2.5rem" }}
                  />
                  <Text>Signin with Google</Text>
                </Center>
              </Button>
            </Center> */}

            <Center mt="22px" columnGap="10px" fontSize={"16px"}>
              <Text>Don't have an account? </Text>
              <Text color="font.main">
                {" "}
                <Link to={routeObj.register}>Register</Link>{" "}
              </Text>
            </Center>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
