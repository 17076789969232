import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  Heading,
  Image,
  Input,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Card,
  CardBody,
  Spinner,
  IconButton,
  Skeleton,
  HStack,
  FormHelperText,
} from "@chakra-ui/react";
import Layout from "../../layouts/dashboard/Layout";
import { MdArrowDropDown } from "react-icons/md";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { customStyle } from "../../utils/style";
import moment from "moment";
import courseService from "../../app/services/courseService";
import { numberFormatter } from "../../utils";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/authSlice";
import { useCustomToast } from "../../utils/toast";
import ThreeCardSkeleton from "../../components/skeletons/ThreeCardSkeleton";
export default function PrivateSession() {
  const { id } = useParams<{ id: string }>();
  const date = new Date();
  date.setDate(date.getDate() + 7);
  const user = useSelector(selectCurrentUser);
  const startDate = moment(date.getTime()).format("YYYY-MM-DD");
  const [timeSelectesId, setTimeSelectedId] = useState<number>(0);
  const days = ["mon", "tue", "wed", "thu", "fri", "sat"];
  const time = [
    {
      id: 1,
      from_time: "08:00",
      to_time: "09:00",
    },
    {
      id: 2,
      from_time: "09:00",
      to_time: "10:00",
    },
    {
      id: 3,
      from_time: "10:00",
      to_time: "11:00",
    },
    {
      id: 4,
      from_time: "11:00",
      to_time: "12:00",
    },
    {
      id: 5,
      from_time: "12:00",
      to_time: "13:00",
    },
    {
      id: 6,
      from_time: "13:00",
      to_time: "14:00",
    },
    {
      id: 7,
      from_time: "14:00",
      to_time: "15:00",
    },
    {
      id: 8,
      from_time: "15:00",
      to_time: "16:00",
    },
    {
      id: 9,
      from_time: "16:00",
      to_time: "17:00",
    },
    {
      id: 10,
      from_time: "17:00",
      to_time: "18:00",
    },
  ];
  const [day1, setDay1] = useState("mon");
  const [day2, setDay2] = useState("wed");
  const [day3, setDay3] = useState("fri");
  const [courseData, setCourseData] = useState<Course>();
  const [availableDate, setAvailableDate] = useState<string>();
  const [canProceed, setCanProceed] = useState<boolean>(false);
  const [payLoading, setPayLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { notifyError, notifySuccess } = useCustomToast();
  const [coupon, setCoupon] = useState("");
  const [validateLoading, setValidateLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [couponVerified, setCouponVerified] = useState(false);
  const [couponError, setCouponError] = useState("");

  const handleCoupon = () => {
    setCouponError("");
    if (coupon.length < 8) {
      setCouponError("Coupon code must be at least 8 characters long");
      return;
    }
    setValidateLoading(true);
    courseService
      .validateCoupon(coupon)
      .then((res) => {
        // setAmount(res.data.discounted_price);
        if (res.status) {
          let price = courseData?.private_price! * (res.data.percentage / 100);
          price = courseData?.private_price! - price;
          setAmount(price);
          setCouponVerified(true);
          setCouponError("");
          notifySuccess("Coupon applied successfully");
        } else {
          setCouponError(res.message);
        }
        setValidateLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setCouponError(err.response.data.message);
        setValidateLoading(false);
      });
  };

  useEffect(() => {
    if (availableDate && timeSelectesId !== 0) {
      // console.log("can proceed");
      setCanProceed(true);
    } else {
      // console.log("can not proceed");
      setCanProceed(false);
    }
  }, [availableDate, timeSelectesId]);
  useEffect(() => {
    if (id) {
      courseService
        .getCourseById(id!)
        .then((res) => {
          setCourseData(res.data.course);
          setAmount(res.data.course.private_price);
          // console.log(res);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          notifyError(err.response.data.message);
          setIsLoading(false);
        });
    }
  }, []);

  const handlePay = () => {
    setPayLoading(true);
    const data = {
      email: user?.email,
      days: [day1, day2, day3],
      time: time[timeSelectesId - 1].from_time,
      start_date: availableDate,
      [couponVerified ? "coupon_code" : ""]: coupon,
    };
    // console.log(parseInt(id!), data);
    courseService
      .registerPrivateBatch(id!, data)
      .then((res) => {
        if (res.status) {
          window.location.href = res.data.PaymentDetails.authorization_url;
        }
        // setPayLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPayLoading(false);
        notifyError(err.response.data.message);
      });
  };
  return (
    <Layout>
      <Box>
        <Box
          pt={{ base: "3rem", md: "150px" }}
          px={{ base: "1rem", md: "60px" }}
          pb={"230px"}
          color="font.main"
          bg={"white"}
        >
          <Heading
            fontSize={{ base: "1.5rem", md: "2rem" }}
            fontWeight={"600"}
            color="font.main"
          >
            {isLoading ? <Skeleton /> : courseData?.name}
          </Heading>
          <Divider
            mt={{ base: "4px", md: "10px" }}
            orientation="horizontal"
            borderColor="#FDE82A"
            borderWidth={{ base: "2px", md: "5px" }}
            w={{ base: "100px", md: "190px" }}
          />
          {isLoading ? (
            <ThreeCardSkeleton />
          ) : (
            <Flex
              w="100%"
              pt={"70px"}
              pb={"20px"}
              columnGap="38px"
              rowGap="18px"
              flexDirection={{ base: "column", md: "row" }}
            >
              <Box flex={0.9}>
                <Image
                  h={{ base: "", md: "316px" }}
                  w={{ base: "90%", md: "" }}
                  src={`${courseData?.image}`}
                />
              </Box>
              <Box
                fontSize={{ base: "0.9rem", md: "1.3rem" }}
                fontWeight="600"
                flex={1}
                // ps={{ base: "2.2rem", md: "0" }}
              >
                <Heading
                  fontSize={{ base: "1.3rem", md: "1.8rem" }}
                  fontWeight="700"
                  textAlign={{ base: "center", md: "left" }}
                  color="font.dark"
                >
                  Course details
                </Heading>
                <Flex
                  alignContent={"center"}
                  columnGap="32px"
                  mt={{ base: "1rem", md: "2rem" }}
                >
                  <Text>Course duration</Text>
                  <Text fontWeight="400" color="font.dark">
                    6months
                  </Text>
                </Flex>
                <Flex
                  alignContent={"center"}
                  mt={{ base: "0.8rem", md: "1.3rem" }}
                  columnGap="32px"
                >
                  <Text>Course frequency</Text>
                  <Text fontWeight="400" color="font.dark">
                    3 days per week <Text color="font.main">(1hr per day)</Text>
                  </Text>
                </Flex>
                <Flex
                  alignContent={"center"}
                  mt={{ base: "0.8rem", md: "1.3rem" }}
                  columnGap="32px"
                >
                  <Text>Coupon</Text>
                  <HStack alignItems={"start"}>
                    <FormControl>
                      <Input
                        type="text"
                        placeholder="Enter coupon code (optional)"
                        value={coupon}
                        outline="1px solid #999FAA"
                        color={"#999FAA"}
                        isDisabled={couponVerified}
                        _placeholder={{ color: "#999FAA" }}
                        onChange={(e) => setCoupon(e.target.value)}
                      />
                      <FormHelperText color="red.500">
                        {""}
                        {couponError}
                      </FormHelperText>
                    </FormControl>
                    <Button
                      _hover={{}}
                      onClick={handleCoupon}
                      bg="bg.main"
                      color="white"
                      columnGap={2}
                      isDisabled={validateLoading || couponVerified}
                    >
                      {" "}
                      Apply
                      <Spinner display={validateLoading ? "block" : "none"} />
                    </Button>
                  </HStack>
                </Flex>
                {/* <Flex
                alignContent={"center"}
                mt={{ base: "0.8rem", md: "1.3rem" }}
                columnGap="32px"
              >
                <Text>Course Instructor</Text>
                <Text fontWeight="400" color="font.dark">
                  Thomas stephen Greg
                </Text>
              </Flex> */}
                <Flex
                  alignContent={"center"}
                  mt={{ base: "0.8rem", md: "1.3rem" }}
                  columnGap="32px"
                >
                  <Text>Course Price</Text>
                  <Text fontWeight="400" color="font.dark">
                    ₦{numberFormatter(amount!)}/per month
                  </Text>
                </Flex>
              </Box>
            </Flex>
          )}

          <Box
            pt={{ base: "3rem", md: "90px" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              fontSize={customStyle.fontSize.sectionHeader}
              fontWeight="600"
            >
              Select your availabilty
            </Heading>
            <Text
              color="font.dark"
              fontSize={{ base: "0.9rem", md: "1.2rem" }}
              fontWeight="400"
            >
              pick a date suitable for you to begin classes
            </Text>

            {isLoading ? (
              <ThreeCardSkeleton />
            ) : (
              <Box pt={{ base: "1.2rem", md: "50px" }}>
                <Flex justifyContent={{ base: "center", md: "left" }}>
                  {" "}
                  <FormControl
                    w={{ base: "180px", md: "300px" }}
                    py={{ base: "0.7rem", md: "1rem" }}
                    borderColor={"font.main"}
                    borderWidth="1px"
                    px={{ base: "1rem", md: "2.3rem" }}
                  >
                    <Input
                      type="date"
                      fontSize={{ base: "0.8rem", md: "1.5rem" }}
                      min={startDate}
                      value={availableDate}
                      onChange={(e) => setAvailableDate(e.target.value)}
                      variant="unstyled"
                      color="font.main !important"
                      colorScheme="white"
                    />
                  </FormControl>
                </Flex>
              </Box>
            )}
          </Box>

          <Box
            pt={{ base: "3rem", md: "90px" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              fontSize={customStyle.fontSize.sectionHeader}
              fontWeight="600"
              // textAlign="center"
            >
              Select your availabilty
            </Heading>
            <Text
              // textAlign="center"
              color="font.dark"
              fontSize={{ base: "0.9rem", md: "1.2rem" }}
              fontWeight="400"
            >
              pick a date suitable for you to begin classes
            </Text>

            {isLoading ? (
              <ThreeCardSkeleton />
            ) : (
              <Box pt={{ base: "3rem", md: "72px" }}>
                <Center
                  flexDirection={{ base: "column", md: "row" }}
                  rowGap="20px"
                  columnGap="64px"
                  bg="white !important"
                >
                  <Box
                    display={"flex"}
                    flexDirection={{ base: "row", md: "column" }}
                    columnGap="0.6rem"
                    rowGap="1.2rem"
                  >
                    <Heading
                      fontSize={{ base: "1rem", md: "1.8rem" }}
                      fontWeight="600"
                      color="font.dark"
                      alignContent="center"
                      display="flex"
                      flexWrap="wrap"
                      justifyContent={"center"}
                    >
                      Day 01
                    </Heading>
                    <Menu>
                      <MenuButton
                        as={Button}
                        w={{ base: "150px", md: "260px" }}
                        bg="none"
                        fontSize={{ base: "0.9rem", md: "1.6rem" }}
                        fontWeight="400"
                        borderColor="bg.main"
                        borderWidth="1px"
                        // w="250px"
                        borderRadius="none"
                        py={{ base: "1.5rem", md: "39px" }}
                        px={{ base: "1.5rem", md: "50px" }}
                        rightIcon={<MdArrowDropDown />}
                      >
                        {day1}
                      </MenuButton>
                      <MenuList
                        w={{ base: "100px", md: "300px" }}
                        px={{ base: "1rem", md: "56px" }}
                        py={{ base: "1.5rem", md: "72px" }}
                        lineHeight={{ base: "1.6rem", md: "36px" }}
                        fontSize={{ base: "1rem", md: "2rem" }}
                        fontWeight="400"
                      >
                        {days.map((value) => (
                          <MenuItem
                            color={day1 === value ? "#6563FF" : "font.dark"}
                            isDisabled={
                              value === day2 || value === day3 ? true : false
                            }
                            onClick={() => setDay1(value)}
                            _hover={{ color: "#6563FF", bg: "none" }}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={{ base: "row", md: "column" }}
                    columnGap="0.6rem"
                    rowGap="1.2rem"
                  >
                    <Heading
                      fontSize={{ base: "1rem", md: "1.8rem" }}
                      fontWeight="600"
                      color="font.dark"
                      alignContent="center"
                      display="flex"
                      flexWrap="wrap"
                      justifyContent={"center"}
                    >
                      Day 02
                    </Heading>
                    <Menu>
                      <MenuButton
                        as={Button}
                        w={{ base: "150px", md: "260px" }}
                        bg="none"
                        fontSize={{ base: "0.9rem", md: "1.6rem" }}
                        fontWeight="400"
                        borderColor="bg.main"
                        borderWidth="1px"
                        // w="250px"
                        borderRadius="none"
                        py={{ base: "1.5rem", md: "39px" }}
                        px={{ base: "1.5rem", md: "50px" }}
                        rightIcon={<MdArrowDropDown />}
                      >
                        {day2}
                      </MenuButton>
                      <MenuList
                        w={{ base: "100px", md: "300px" }}
                        px={{ base: "1rem", md: "56px" }}
                        py={{ base: "1.5rem", md: "72px" }}
                        lineHeight={{ base: "1.6rem", md: "36px" }}
                        fontSize={{ base: "1rem", md: "2rem" }}
                        fontWeight="400"
                      >
                        {days.map((value) => (
                          <MenuItem
                            color={day2 === value ? "#6563FF" : "font.dark"}
                            onClick={() => setDay2(value)}
                            isDisabled={
                              value === day1 || value === day3 ? true : false
                            }
                            _hover={{ color: "#6563FF", bg: "none" }}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </Box>

                  <Box
                    display={"flex"}
                    flexDirection={{ base: "row", md: "column" }}
                    columnGap="0.6rem"
                    rowGap="1.2rem"
                  >
                    <Heading
                      fontSize={{ base: "1rem", md: "1.8rem" }}
                      fontWeight="600"
                      color="font.dark"
                      alignContent="center"
                      display="flex"
                      flexWrap="wrap"
                      justifyContent={"center"}
                    >
                      Day 03
                    </Heading>
                    <Menu>
                      <MenuButton
                        as={Button}
                        w={{ base: "150px", md: "260px" }}
                        bg="none"
                        fontSize={{ base: "0.9rem", md: "1.6rem" }}
                        fontWeight="400"
                        borderColor="bg.main"
                        borderWidth="1px"
                        // w="250px"
                        borderRadius="none"
                        py={{ base: "1.5rem", md: "39px" }}
                        px={{ base: "1.5rem", md: "50px" }}
                        rightIcon={<MdArrowDropDown />}
                      >
                        {day3}
                      </MenuButton>
                      <MenuList
                        w={{ base: "100px", md: "300px" }}
                        px={{ base: "1rem", md: "56px" }}
                        py={{ base: "1.5rem", md: "72px" }}
                        lineHeight={{ base: "1.6rem", md: "36px" }}
                        fontSize={{ base: "1rem", md: "2rem" }}
                        fontWeight="400"
                      >
                        {days.map((value) => (
                          <MenuItem
                            color={day3 === value ? "#6563FF" : "font.dark"}
                            onClick={() => setDay3(value)}
                            isDisabled={
                              value === day2 || value === day1 ? true : false
                            }
                            _hover={{ color: "#6563FF", bg: "none" }}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                  </Box>
                </Center>
              </Box>
            )}
          </Box>

          <Box
            pt={{ base: "3rem", md: "90px" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Heading
              fontSize={customStyle.fontSize.sectionHeader}
              fontWeight="600"
            >
              Select your time
            </Heading>
            <Text
              color="font.dark"
              fontSize={{ base: "0.9rem", md: "1.2rem" }}
              fontWeight="400"
            >
              pick a day in a week for your classes
            </Text>

            <Box pt={{ base: "3rem", md: "72px" }}>
              {isLoading ? (
                <ThreeCardSkeleton />
              ) : (
                <Flex
                  flexWrap={"wrap"}
                  // flexDirection={{ base: "column", md: "row" }}
                  columnGap="64px"
                  w="100%"
                  rowGap={"32px"}
                  justifyContent={{ base: "center", md: "left" }}
                >
                  {time.map((value) => (
                    <Card
                      variant="elevated"
                      key={value.id}
                      w={{ base: "80%", md: "46%" }}
                    >
                      <CardBody
                        ps={{ base: "1rem", md: "29px" }}
                        pe={{ base: "1rem", md: "46px" }}
                        py={{ base: "1.2rem", md: "28px" }}
                      >
                        <Center columnGap={{ base: "1rem", md: "56px" }}>
                          <Box>
                            <Heading
                              fontSize={{ base: "1.2rem", md: "1.6rem" }}
                              fontWeight="500"
                            >
                              {value.from_time} - {value.to_time}
                            </Heading>
                            <Text
                              color="font.main"
                              fontSize={{ base: "0.9rem", md: "24px" }}
                              fontWeight="400"
                              mt={{ base: "0.8rem", md: "23px" }}
                            >
                              1hr per day
                            </Text>
                          </Box>
                          <IconButton
                            aria-label="Add button"
                            bg="none"
                            fontSize={{ base: "2.5rem", md: "66px" }}
                            color="font.main"
                            opacity={timeSelectesId === value.id ? 0.5 : 1}
                            icon={
                              timeSelectesId === value.id ? (
                                <AiFillMinusCircle />
                              ) : (
                                <AiFillPlusCircle />
                              )
                            }
                            onClick={() => {
                              if (timeSelectesId === value.id) {
                                setTimeSelectedId(0);
                              } else {
                                setTimeSelectedId(value.id);
                              }
                              // setModalOpen(true);
                            }}
                          />
                        </Center>
                      </CardBody>
                    </Card>
                  ))}
                </Flex>
              )}

              <Flex
                justifyContent={{ base: "center", md: "right" }}
                flexWrap="wrap"
                pt={{ base: "2rem", md: "48px" }}
                w="100%"
              >
                <Button
                  bg="bg.main"
                  w={{ base: "80%", md: "306px" }}
                  h={{ base: "2.6rem", md: "56px" }}
                  color="white"
                  fontSize={{ base: "0.9rem", md: "24px" }}
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.6s"
                  borderRadius={"none"}
                  isDisabled={!canProceed || payLoading}
                  onClick={handlePay}
                >
                  PROCEED TO PAY{" "}
                  <Spinner display={payLoading ? "inline-flex" : "none"} />
                </Button>
              </Flex>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
