import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Center,
  Flex,
  Heading,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Navbar from "../layouts/Navbar";
import Banner from "../components/Banner";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "swiper/swiper.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Footer from "../layouts/Footer";
import { customStyle } from "../utils/style";
import ThreeCardSkeleton from "../components/skeletons/ThreeCardSkeleton";
import courseService from "../app/services/courseService";
import { routeObj } from "../constants/routes";
import { Link } from "react-router-dom";
import { capitalizeFirstLetter } from "../utils";
import moment from "moment";
export default function Home() {
  const [isLoading, setIsLoading] = useState(true);
  const [topCourses, setTopCourses] = useState<CourseType[]>([]);

  const [standAloneCourses, setStandAloneCourses] = useState<CourseType[]>([]);
  useEffect(() => {
    courseService
      .getCourses()
      .then((res) => {
        if (res.data.length) {
          const standAlone = res.data.filter((course: any) => {
            return course.course.type === "STANDALONE";
          });
          const top = res.data.filter((course: any) => {
            return course.course.type === "CAREER";
          });
          setTopCourses(top);
          setStandAloneCourses(standAlone);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, []);
  return (
    <Box>
      <Box
      // bgImage={{ base: "", md: "/assets/images/home-banner.png" }}
      // bgRepeat="no-repeat"
      // bgSize="cover"
      >
        <Navbar />
        <Banner />
      </Box>

      <Box
        pt={{ base: "2rem", md: "7rem" }}
        pb="6rem"
        pe={customStyle.padding.peHome}
        ps={customStyle.padding.psHome}
        bg="bg.subMain"
        color="font.main"
        textAlign={{ base: "center", md: "left" }}
      >
        <AnimationOnScroll animateIn="animate__slideInUp">
          <Heading
            fontSize={customStyle.fontSize.sectionHeader}
            fontWeight={customStyle.fontWeight.navItem}
          >
            Why you should learn with us
          </Heading>
          <Flex
            columnGap={"10rem"}
            rowGap={"3rem"}
            pt={{ base: "20px", md: "3rem" }}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Stack
              flex={1.2}
              color="font.main"
              rowGap={{ base: "1rem", md: "2rem" }}
              lineHeight={{ base: "1rem", md: "1.5rem" }}
            >
              <Box>
                <Heading
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  fontWeight="400"
                >
                  SEASONED TUTORS
                </Heading>
                <Text
                  mt={{ base: "1rem", md: "1rem" }}
                  fontSize={customStyle.fontSize.paragragh}
                  fontWeight="500"
                  color={{ base: "font.main", md: "font.dark" }}
                >
                  We have a professional team of great tutors that knows the
                  best way to take you from just a starter to a confident
                  software engineer
                </Text>
              </Box>

              <Box>
                <Heading
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  fontWeight="400"
                >
                  LIVE TUTORING
                </Heading>
                <Text
                  mt={{ base: "1rem", md: "1.5rem" }}
                  fontSize={customStyle.fontSize.paragragh}
                  fontWeight="500"
                  color={{ base: "font.main", md: "font.dark" }}
                >
                  We have a professional team of great tutors that knows the
                  best way to take you from just a starter to a confident
                  software
                </Text>
              </Box>
              <Box>
                <Heading
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  fontWeight="400"
                >
                  EFFECTIVE LEARNING
                </Heading>
                <Text
                  mt={{ base: "1rem", md: "1.5rem" }}
                  fontSize={customStyle.fontSize.paragragh}
                  fontWeight="500"
                  color={{ base: "font.main", md: "font.dark" }}
                >
                  We have a professional team of great tutors that knows the
                  best way
                </Text>
              </Box>
            </Stack>
            <Box flex={2}>
              <Center>
                <Image
                  w={{ base: "350px", md: "80%" }}
                  src="/assets/images/learn.png"
                />
              </Center>
            </Box>
          </Flex>
        </AnimationOnScroll>
      </Box>

      {/* Top coursed we over  */}
      {/* <AnimationOnScroll animateIn="animate__slideInUp"> */}
      <Box
        id="courses"
        color="font.main"
        // bg="bg.subMain"
        px={customStyle.padding.psHome}
        pt={{ base: "1rem", md: "3rem" }}
      >
        <Heading
          fontSize={customStyle.fontSize.sectionHeader}
          fontWeight={customStyle.fontWeight.navItem}
          textAlign={{ base: "center", md: "left" }}
        >
          Top Courses We Offer
        </Heading>
        <Text
          fontSize={{ base: "0.8rem", md: "1.5rem" }}
          mt={{ base: "0.3rem", md: "1rem" }}
          color={{ base: "font.main", md: "font.dark" }}
          fontWeight={"400"}
          textAlign={{ base: "center", md: "left" }}
        >
          Learn a new skill and upskill with our full packed courses{" "}
        </Text>
        <Flex
          pt="3.5rem"
          pb="5rem"
          columnGap={"1.7rem"}
          rowGap="1.7rem"
          flexWrap="wrap"
          justifyContent={"left"}
          display={{ base: "none", md: "flex" }}
        >
          {isLoading ? (
            <ThreeCardSkeleton />
          ) : (
            topCourses?.map((data) => (
              <Link
                to={`${routeObj.courseDetail}${data.course.id}`}
                style={{ width: "31.5%" }}
                key={data.course.id}
              >
                <Box
                  mb="10px"
                  borderRadius={"sm"}
                  boxShadow="lg"
                  w="100%"
                  h={{ base: "250px", md: "390px", lg: "485px" }}
                  position="relative"
                >
                  <Image
                    src={`${data.course.image}`}
                    alt={`Picture of ${data.course.name}`}
                    w="100%"
                    h={{ base: "100px", md: "240px" }}
                    objectFit={"cover"}
                    objectPosition={"center"}
                  />

                  <Box ps="20px" pt="34px" pb="46px" pe="24px" bg="#ffffff">
                    <Flex padding={0}>
                      <Box
                        fontSize="16px"
                        color="font.main"
                        fontWeight="700"
                        as="h4"
                      >
                        {capitalizeFirstLetter(data.course?.name)}
                      </Box>
                    </Flex>

                    <Box
                      pt="20px"
                      color="font.dark"
                      fontWeight="400"
                      fontSize={"16px"}
                    >
                      <Text>{`${new Date(
                        data.course.updated_at
                      ).toLocaleDateString()}`}</Text>
                      {/* <Text mt="12px">{data.date}</Text> */}
                      <Flex mt="12px" columnGap={"3px"}>
                        <Text>Location: </Text>
                        <Text>Online</Text>
                      </Flex>
                      {/* <Flex mt="12px" columnGap={"3px"}>
                        <Text>Status: </Text>
                        <Text color={"#2CA900"}>open</Text>
                      </Flex> */}
                    </Box>
                  </Box>
                </Box>
              </Link>
            ))
          )}
        </Flex>
        {/* mobile view  */}
        <Box pt="1rem" display={{ base: "block", md: "none" }}>
          {isLoading ? (
            <ThreeCardSkeleton />
          ) : (
            <OwlCarousel className="owl-theme" items={1.1} margin={20} nav>
              {topCourses.map((data) => (
                <Link
                  to={`${routeObj.courseDetail}${data.course.id}`}
                  key={data.course.id}
                >
                  <Box
                    mb="10px"
                    borderRadius={"sm"}
                    boxShadow="lg"
                    w="100%"
                    position="relative"
                  >
                    <Image
                      src={data.course.image}
                      alt={`Picture of ${data.course.name}`}
                      w="100%"
                    />

                    <Box ps="20px" pt="2rem" pb="2rem" pe="24px" bg="#ffffff">
                      <Flex padding={0}>
                        <Box
                          fontSize="0.9rem"
                          color="font.main"
                          fontWeight="700"
                          as="h4"
                        >
                          {capitalizeFirstLetter(data.course?.name)}
                        </Box>
                      </Flex>

                      <Box
                        pt="20px"
                        color="font.dark"
                        fontWeight="400"
                        fontSize={"0.8rem"}
                      >
                        <Text>{`${data.course.updated_at}`}</Text>
                        {/* <Text mt="8px">{data.date}</Text> */}
                        <Flex mt="8px" columnGap={"3px"}>
                          <Text>Location: </Text>
                          <Text>online</Text>
                        </Flex>
                        {/* <Flex mt="8px" columnGap={"3px"}>
                          <Text>Status: </Text>
                          <Text color={"#2CA900"}>open</Text>
                        </Flex> */}
                      </Box>
                    </Box>
                  </Box>
                </Link>
              ))}
            </OwlCarousel>
          )}
        </Box>
      </Box>
      {/* </AnimationOnScroll> */}

      {/*Standalone Courses */}
      {!isLoading && standAloneCourses?.length > 0 && (
        <AnimationOnScroll animateIn="animate__slideInUp">
          <Box
            color="font.main"
            bg="bg.subMain"
            px={customStyle.padding.psHome}
          >
            <Heading
              fontSize={customStyle.fontSize.sectionHeader}
              fontWeight={customStyle.fontWeight.navItem}
              textAlign={{ base: "center", md: "left" }}
            >
              Standalone courses
            </Heading>
            <Text
              fontSize={{ base: "0.8rem", md: "1.5rem" }}
              mt={{ base: "0.3rem", md: "1rem" }}
              color={{ base: "font.main", md: "font.dark" }}
              fontWeight={"400"}
              textAlign={{ base: "center", md: "left" }}
            >
              Learn a new skill and upskill with our full packed courses{" "}
            </Text>
            <Box
              display={{ base: "none", md: "block" }}
              pt="72px"
              pe="80px"
              pb="104px"
              columnGap={"32px"}
            >
              {isLoading ? (
                <ThreeCardSkeleton />
              ) : (
                <OwlCarousel className="owl-theme" items={3} margin={20}>
                  {standAloneCourses?.length > 0 &&
                    standAloneCourses?.map((data) => (
                      <Link
                        to={`${routeObj.courseDetail}${data.course.id}`}
                        style={{ width: "46%" }}
                        key={data.course.id}
                      >
                        <Box
                          mb="10px"
                          borderRadius={"sm"}
                          boxShadow="lg"
                          w="100%"
                          position="relative"
                        >
                          <Image
                            src={`${data.course.image}`}
                            alt={`Picture of ${data.course.name}`}
                            w="100%"
                            h={{ base: "100px", md: "200px" }}
                            objectFit={"cover"}
                          />

                          <Box
                            ps="20px"
                            pt="34px"
                            pb="46px"
                            pe="24px"
                            bg="#ffffff"
                          >
                            <Flex padding={0}>
                              <Box
                                fontSize="16px"
                                color="font.main"
                                fontWeight="700"
                                as="h4"
                              >
                                {capitalizeFirstLetter(data.course?.name)}
                              </Box>
                            </Flex>

                            <Box
                              pt="20px"
                              color="font.dark"
                              fontWeight="400"
                              fontSize={"16px"}
                            >
                              <Text>{`${new Date(
                                data.course.updated_at
                              ).toLocaleDateString()}`}</Text>
                              {/* <Text mt="12px">{data.date}</Text> */}
                              <Flex mt="12px" columnGap={"3px"}>
                                <Text>Location: </Text>
                                <Text>online</Text>
                              </Flex>
                              {/* <Flex mt="12px" columnGap={"3px"}>
                              <Text>Status: </Text>
                              <Text color={"#2CA900"}>open</Text>
                            </Flex> */}
                            </Box>
                          </Box>
                        </Box>
                      </Link>
                    ))}
                </OwlCarousel>
              )}
            </Box>
            {/* mobile view  */}
            <Box pt="1rem" display={{ base: "block", md: "none" }} pb="2rem">
              {isLoading ? (
                <ThreeCardSkeleton />
              ) : (
                <OwlCarousel className="owl-theme" items={1.1} margin={20}>
                  {standAloneCourses?.length > 0 &&
                    standAloneCourses?.map((data) => (
                      <Link
                        to={`${routeObj.courseDetail}${data.course.id}`}
                        key={data.course.id}
                      >
                        <Box
                          mb="10px"
                          borderRadius={"sm"}
                          boxShadow="lg"
                          w="100%"
                          position="relative"
                        >
                          <Image
                            src={data.course.image}
                            alt={`Picture of ${data.course.name}`}
                            w="100%"
                            height={{ base: "100px", md: "250px" }}
                            objectFit={"cover"}
                          />

                          <Box
                            ps="20px"
                            pt="2rem"
                            pb="2rem"
                            pe="24px"
                            bg="#ffffff"
                          >
                            <Flex padding={0}>
                              <Box
                                fontSize="0.9rem"
                                color="font.main"
                                fontWeight="700"
                                as="h4"
                              >
                                {capitalizeFirstLetter(data.course?.name)}
                              </Box>
                            </Flex>

                            <Box
                              pt="20px"
                              color="font.dark"
                              fontWeight="400"
                              fontSize={"0.8rem"}
                            >
                              <Text>{`${data.course.updated_at}`}</Text>
                              {/* <Text mt="8px">{data.date}</Text> */}
                              <Flex mt="8px" columnGap={"3px"}>
                                <Text>Location: </Text>
                                <Text>online</Text>
                              </Flex>
                              {/* <Flex mt="8px" columnGap={"3px"}>
                              <Text>Status: </Text>
                              <Text color={"#2CA900"}>open</Text>
                            </Flex> */}
                            </Box>
                          </Box>
                        </Box>
                      </Link>
                    ))}
                </OwlCarousel>
              )}
            </Box>
          </Box>
        </AnimationOnScroll>
      )}

      {/* Inspiration section */}

      <AnimationOnScroll animateIn="animate__slideInUp">
        <Box
          // bg="bg.subMain"
          pt="50px"
          // display={{ base: "block", md: "none" }}
          color="font.main"
        >
          <Heading
            fontSize={customStyle.fontSize.sectionHeader}
            fontWeight={customStyle.fontWeight.navItem}
            textAlign="center"
            mb="1rem"
          >
            What Our Team Message
          </Heading>
          <Flex
            bg={{ base: "#ffffff", md: "bg.main" }}
            p={0}
            alignItems={"start"}
            flexDirection={{ base: "column", md: "row" }}
          >
            <Box
              w="100%"
              minH="50vh"
              pt="5rem"
              px={{ base: "30px", md: "2rem" }}
              fontWeight={"600"}
              color={{ base: "font.main", md: "#ffffff" }}
              textAlign={{ base: "left", md: "center" }}
              flex={1}
            >
              <Heading
                fontSize={customStyle.fontSize.paragragh}
                fontStyle={"italic"}
              >
                Dear techie,
              </Heading>
              <Box my="20px">
                <Text
                  fontSize={customStyle.fontSize.paragragh}
                  px={{ base: "0", md: "6rem" }}
                >
                  You're a champ, and you're not afraid to tell us. You don't
                  care if people think you're weird or that you're too smart for
                  your own good, because you know that there are people out
                  there who need to be told how to do things. And the rest of
                  us? We want to be able to do what you do, but we haven't had
                  the training or the experience that you've had. So when we
                  look at your code, we can't always understand it—it's so
                  complicated! That's why we hired you.
                </Text>
              </Box>
              <Heading
                fontSize={customStyle.fontSize.paragragh}
                fontStyle={"italic"}
              >
                Your’s sincerely,
              </Heading>
              <Heading
                fontSize={customStyle.fontSize.paragragh}
                fontStyle={"italic"}
                color={{ base: "inherit", md: "#FDE82A" }}
                mt="0.3rem"
              >
                Codelandcs team
              </Heading>
            </Box>
            <Flex
              // pt="0.4rem"
              h={{ base: "30vh", md: "60vh" }}
              justify={"center"}
              w="100%"
              flex={1}
            >
              <Box display={{ base: "inline-flex", md: "none" }}>
                <Image w={"100%"} src="/assets/images/inspire.png" />
              </Box>
              <Box
                display={{ base: "none", md: "inline-flex" }}
                bgImage="/assets/images/inspire-desktop.png"
                w="100%"
                h="100%"
                bgRepeat="no-repeat"
                bgSize="cover"
              >
                {/* <Image w={"100%"} src="" /> */}
              </Box>
            </Flex>
          </Flex>
        </Box>
      </AnimationOnScroll>

      {/* Community section */}
      {/* <Box
        bg="bg.subMain"
        py={{ base: "30px", md: "104px" }}
        color={"font.main"}
      > */}
      {/* <Heading
          fontSize={customStyle.fontSize.sectionHeader}
          fontWeight={customStyle.fontWeight.navItem}
          textAlign={"center"}
        >
          What the community is saying
        </Heading> */}
      {/* Desktop view */}
      {/* <Box pt="72px" px={"3rem"} display={{ base: "none", md: "block" }}>
          <OwlCarousel className="owl-theme" items={2}  margin={19} nav>
            <Box
              bg="white"
              w="600px"
              minH={"5rem"}
              px={customStyle.padding.psHome}
              pt="2rem"
              pb="2rem"
            >
              <Flex alignItems={"center"} columnGap={"15px"}>
                <Box boxSize={"3.5rem"}>
                  <Avatar
                    borderRadius="full"
                    boxSize="3.5rem"
                    src="https://bit.ly/dan-abramov"
                  />
                </Box>
                <Box>
                  <Center>
                    <Text
                      color="font.dark"
                      fontSize={customStyle.fontSize.cardTitle}
                      fontWeight={"600"}
                    >
                      Omehe Godwin
                    </Text>
                  </Center>

                  <Text fontSize={"1.2"} fontWeight={"400"}>
                    Web development
                  </Text>
                </Box>
              </Flex>
              <Box pt="2rem">
                <Text color={"font.dark"} fontSize="1rem" fontWeight={"400"}>
                  I took a web development course and that’s the best decision i
                  ever made, i have greatly learnt alot. i have also gotten a
                  job based on my experience , Thank you so much Tutor clerkson.
                </Text>
              </Box>
            </Box>
            <Box
              bg="white"
              w="600px"
              minH={"5rem"}
              px={customStyle.padding.psHome}
              pt="2rem"
              pb="2rem"
            >
              <Flex alignItems={"center"} columnGap={"15px"}>
                <Box boxSize={"3.5rem"}>
                  <Avatar
                    borderRadius="full"
                    boxSize="3.5rem"
                    src="https://bit.ly/dan-abramov"
                  />
                </Box>
                <Box>
                  <Center>
                    <Text
                      color="font.dark"
                      fontSize={customStyle.fontSize.cardTitle}
                      fontWeight={"600"}
                    >
                      Omehe Godwin
                    </Text>
                  </Center>

                  <Text fontSize={"1.2"} fontWeight={"400"}>
                    Web development
                  </Text>
                </Box>
              </Flex>
              <Box pt="2rem">
                <Text color={"font.dark"} fontSize="1rem" fontWeight={"400"}>
                  I took a web development course and that’s the best decision i
                  ever made, i have greatly learnt alot. i have also gotten a
                  job based on my experience , Thank you so much Tutor clerkson.
                </Text>
              </Box>
            </Box>
          </OwlCarousel>
        </Box> */}

      {/* Mobile view carousel */}
      {/* <Box py="20px" px="25px" display={{ base: "block", md: "none" }}>
          <OwlCarousel className="owl-theme" items={1} margin={20} nav>
            <Box bg="white" px="15px" py="15px">
              <Flex alignItems={"center"} columnGap={"15px"}>
                <Box boxSize={"50px"}>
                  <Image
                    borderRadius="full"
                    boxSize="50px"
                    src="https://bit.ly/dan-abramov"
                    alt="Dan Abramov"
                  />
                </Box>
                <Box>
                  <Center>
                    <Text
                      color="font.dark"
                      fontSize={"16px"}
                      fontWeight={"semibold"}
                    >
                      Omehe Godwin
                    </Text>
                  </Center>

                  <Text fontSize={"12px"} fontWeight={"medium"}>
                    Web development
                  </Text>
                </Box>
              </Flex>
              <Box py="10px">
                <Text color={"font.dark"} pt="10px" fontSize="12px">
                  I took a web development course and that’s the best decision i
                  ever made, i have greatly learnt alot. i have also gotten a
                  job based on my experience , Thank you so much Tutor clerkson.
                </Text>
              </Box>
            </Box>
          </OwlCarousel>
        </Box> */}
      {/* </Box> */}

      {/* FAQ section  */}
      <Box
        id="faq"
        color={"font.dark"}
        pb={{ base: "3rem", md: "104px" }}
        bg="bg.subMain"
        pt={{ base: "1.5rem", md: "3rem" }}
      >
        <Heading
          fontSize={customStyle.fontSize.sectionHeader}
          fontWeight={customStyle.fontWeight.navItem}
          color="font.main"
          textAlign={"center"}
        >
          Frequently asked questions
        </Heading>
        <Box
          display={"block"}
          pt={{ base: 10, md: "40px" }}
          px={customStyle.padding.psHome}
        >
          <Accordion allowToggle>
            <AccordionItem borderBottom={"solid #A1A1A1 1px"} borderTop="none">
              <AccordionButton
                fontSize={{ base: "12px", md: "1.5rem" }}
                _expanded={
                  {
                    // bg: "bg.main",
                    // color: "white",
                  }
                }
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  pb={{ base: "0.8rem", md: "1.5rem" }}
                >
                  What do i get to recieve after studying at Codelandcs ?
                </Box>
                <AccordionIcon fontSize={{ base: "0.8rem", md: "1.5rem" }} />
              </AccordionButton>

              <AccordionPanel fontSize={customStyle.fontSize.paragragh}>
                After studying at CodelandCS, you will receive a valuable
                certification that serves as proof of your successful completion
                of our courses. This certification demonstrates your dedication
                and expertise in the field of coding, setting you apart from the
                competition. Additionally, we provide a comprehensive
                performance tracking system that allows you to monitor your
                progress throughout your studies. You will have access to
                detailed assignment ratings, enabling you to gauge your
                strengths and areas for improvement. As an added benefit, with
                your consent, we offer the opportunity to connect you with
                reputable recruiters actively seeking talented individuals with
                the skills you have acquired. At CodelandCS, we empower you to
                showcase your abilities and unlock exciting career prospects in
                the tech industry.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={"solid #A1A1A1 1px"} borderTop="none">
              <AccordionButton
                fontSize={{ base: "12px", md: "1.5rem" }}
                _expanded={
                  {
                    // bg: "bg.main",
                    // color: "white",
                  }
                }
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  pb={{ base: "0.8rem", md: "1.5rem" }}
                >
                  How do I enroll in a course at CodelandCS?
                </Box>
                <AccordionIcon fontSize={{ base: "0.8rem", md: "1.5rem" }} />
              </AccordionButton>

              <AccordionPanel fontSize={customStyle.fontSize.paragragh}>
                Enrolling in a course at CodelandCS is easy. Simply browse our
                course catalog, select the desired course, and follow the
                registration process on our website. Once registered, you will
                gain access to the course materials and resources.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={"solid #A1A1A1 1px"} borderTop="none">
              <AccordionButton
                fontSize={{ base: "12px", md: "1.5rem" }}
                _expanded={
                  {
                    // bg: "bg.main",
                    // color: "white",
                  }
                }
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  pb={{ base: "0.8rem", md: "1.5rem" }}
                >
                  What is the duration of the courses at CodelandCS?
                </Box>
                <AccordionIcon fontSize={{ base: "0.8rem", md: "1.5rem" }} />
              </AccordionButton>

              <AccordionPanel fontSize={customStyle.fontSize.paragragh}>
                The duration of each course at CodelandCS varies depending on
                the specific program. Generally, courses span several months,
                allowing you to delve deep into the subject matter and gain a
                comprehensive understanding of the topic.
              </AccordionPanel>
            </AccordionItem>

            <AccordionItem borderBottom={"solid #A1A1A1 1px"} borderTop="none">
              <AccordionButton
                fontSize={{ base: "12px", md: "1.5rem" }}
                _expanded={
                  {
                    // bg: "bg.main",
                    // color: "white",
                  }
                }
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  pb={{ base: "0.8rem", md: "1.5rem" }}
                >
                  Are there any prerequisites for enrolling in a course?
                </Box>
                <AccordionIcon fontSize={{ base: "0.8rem", md: "1.5rem" }} />
              </AccordionButton>

              <AccordionPanel fontSize={customStyle.fontSize.paragragh}>
                The prerequisites for each course may vary. Some courses may
                have specific prerequisites, such as prior programming knowledge
                or familiarity with certain concepts. However, we offer courses
                suitable for beginners as well. Please refer to the individual
                course descriptions for detailed information on prerequisites.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={"solid #A1A1A1 1px"} borderTop="none">
              <AccordionButton
                fontSize={{ base: "12px", md: "1.5rem" }}
                _expanded={
                  {
                    // bg: "bg.main",
                    // color: "white",
                  }
                }
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  pb={{ base: "0.8rem", md: "1.5rem" }}
                >
                  Can I access the course materials at any time?
                </Box>
                <AccordionIcon fontSize={{ base: "0.8rem", md: "1.5rem" }} />
              </AccordionButton>

              <AccordionPanel fontSize={customStyle.fontSize.paragragh}>
                Yes, once enrolled in a course, you will have 24/7 access to the
                course materials and resources. You can learn at your own pace
                and revisit the content as needed to reinforce your
                understanding.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={"solid #A1A1A1 1px"} borderTop="none">
              <AccordionButton
                fontSize={{ base: "12px", md: "1.5rem" }}
                _expanded={
                  {
                    // bg: "bg.main",
                    // color: "white",
                  }
                }
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  pb={{ base: "0.8rem", md: "1.5rem" }}
                >
                  Will I receive support from instructors during the course?
                </Box>
                <AccordionIcon fontSize={{ base: "0.8rem", md: "1.5rem" }} />
              </AccordionButton>

              <AccordionPanel fontSize={customStyle.fontSize.paragragh}>
                Absolutely! Our instructors are highly experienced professionals
                dedicated to your success. They are available to provide
                guidance, clarify concepts, and answer your questions throughout
                the course via discussion boards, live chat sessions, or email.
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem borderBottom={"solid #A1A1A1 1px"} borderTop="none">
              <AccordionButton
                fontSize={{ base: "12px", md: "1.5rem" }}
                _expanded={
                  {
                    // bg: "bg.main",
                    // color: "white",
                  }
                }
              >
                <Box
                  as="span"
                  flex="1"
                  textAlign="left"
                  pb={{ base: "0.8rem", md: "1.5rem" }}
                >
                  What happens if I cannot complete the course within the
                  specified duration?
                </Box>
                <AccordionIcon fontSize={{ base: "0.8rem", md: "1.5rem" }} />
              </AccordionButton>

              <AccordionPanel fontSize={customStyle.fontSize.paragragh}>
                No worries if you can't complete the course within the specified
                duration! At CodelandCS, we understand that life can get busy
                sometimes. The cool thing is, we've got you covered. If you find
                yourself unable to finish the course within the given timeframe,
                you can seamlessly switch to another session that is currently
                at a stage where you can jump right back in. This flexibility
                allows you to pick up where you left off and continue your
                learning journey without missing a beat. So, take a breather,
                regroup, and when you're ready, we'll be here to support you
                every step of the way!
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>

      <Footer />
    </Box>
  );
}
