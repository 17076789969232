import request from "./httpService";

const courseService = {
  getCourses() {
    return request.get("/course");
  },
  getCoursesForUser() {
    return request.get("/courses/user");
  },
  getCourseById(id: string) {
    return request.get(`/course/${id}`);
  },
  getBatchByCourse(id: string) {
    return request.get(`/course/batches/course/onlybatch/${id}`);
  },
  getBatchById(id: string) {
    return request.get(`/courses/batches/${id}`);
  },
  getActiveCourses() {
    return request.get(`/user/active-courses`);
  },
  getCompletedCourses() {
    return request.get(`/user/completed-courses`);
  },
  getFinishedCourses() {
    return request.get(`/user/certificate-courses`);
  },
  registerCourseByBatch(id: string, body: object) {
    return request.post(`/courses/batch/${id}/register`, body);
  },
  manualRegisterCourse(body: object) {
    return request.post(`/transaction/manual-payment`, body);
  },
  registerPrivateBatch(id: string, body: object) {
    return request.post(`/courses/${id}/register/private`, body);
  },
  getLecturesByBatch(id: string, page?: number) {
    return request.get(`/lectures/batch/${id}?page=${page}`);
  },
  getLectureById(id: string) {
    return request.get(`/lectures/${id}`);
  },
  joinClass(id: string) {
    return request.post(`/attendance/take/${id}`, {});
  },
  getCurrentCourses() {
    return request.get(`/courses/user/active/expired`);
  },
  getTransactionHistory() {
    return request.get(`/transaction`);
  },
  renewCoursePayment(id: string) {
    return request.patch(`/courses/renew-payment/${id}`);
  },
  validateCoupon(coupon: string) {
    return request.get(`/coupons/${coupon}`);
  },
  submitAssignment(body: object) {
    return request.post(`/assignment/create`, body);
  },
};

export default courseService;
