import { extendTheme } from "@chakra-ui/react";
import type { StyleFunctionProps } from "@chakra-ui/styled-system";
const theme = {
  config: {
    initialColorMode: "light",
    useSystemColorMode: true,
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      html: {
        "font-size": "16px",
        "font-style": "normal",
        bg: "white",
      },
      body: {
        margin: 0,
        padding: 0,
        "box-sizing": "border-box",
        "font-family": "Hellix",
        bg: "#ffffff",
      },
      "*": {
        margin: 0,
        padding: 0,
        "box-sizing": "border-box",
        "font-family": "Hellix !important",
      },
    }),
  },
  colors: {
    bg: {
      main: "#00296B",
      subMain: "#F2F5FA",
    },
    font: {
      main: "#00296B",
      dark: "#2A2B2C",
    },
  },
};

export const customStyle = {
  fontSize: {
    navItem: { base: "0.7rem", md: "1rem" },
    sideItem: { base: "0.7rem", md: "1.2rem" },
    logo: { base: "1rem", md: "1.5rem" },
    logoText: { base: "1rem", md: "1.4rem" },
    bannerText: { base: "1.5rem", md: "3.2rem" },
    sectionHeader: { base: "1rem", md: "2rem" },
    paragragh: { base: "0.7rem", md: "1rem" },
    cardTitle: { base: "0.9rem", md: "1.4rem" },
  },
  fontWeight: {
    navItem: { base: "600", md: "600" },
    cardTitle: { base: "700", md: "700" },
  },
  padding: {
    psHome: { base: "1rem", md: "4rem" },
    peHome: { base: "1rem", md: "4rem" },
  },
};

export default extendTheme(theme);
