import {
  Box,
  Divider,
  Heading,
  Image,
  Card,
  CardBody,
  Text,
  Button,
  Flex,
  Icon,
  Center,
  useDisclosure,
  Spinner,
  SkeletonText,
  Skeleton,
} from "@chakra-ui/react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import SessionModal from "../modal/SessionModal";
import CourseCountDown from "./CourseCountdown";
import { customStyle } from "../../utils/style";
import {
  capitalizeFirstLetter,
  dateFormatter,
  dateTimeFormatter,
  escapeHtml,
  numberFormatter,
  formatRealTime,
} from "../../utils";
import ThreeCardSkeleton from "../skeletons/ThreeCardSkeleton";
import { BsArrowLeft } from "react-icons/bs";
import { routeObj } from "../../constants/routes";
import moment from "moment";

interface Props {
  course: Course;
  batches: Batches[];
  isLoading: boolean;
}
export default function CourseDetailLayout(props: Props) {
  const pathName = useLocation().pathname;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dashboardBaseUrl = "/dashboard";
  const route = useNavigate();
  const [batchID, setBatchId] = useState<string>();
  const [courseID, setCourseId] = useState<string>();
  const specifyValue = (dahsboardValue: any, guestvalue: any) =>
    pathName.includes(dashboardBaseUrl) ? dahsboardValue : guestvalue;
  // console.log(props.batches);
  useEffect(() => {
    // console.log(props.course);
    // console.log(props.batches);
    if (props.batches) {
      setBatchId(props.batches[0]?.id);
      setCourseId(props.course.id);
      // setIsLoading(false);
    }
  }, [props.batches]);

  return (
    <Box>
      <SessionModal
        id={batchID!}
        courseId={courseID!}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Box
        pt={{ base: "3rem", md: "90px" }}
        px={{ base: "1rem", md: "79px" }}
        pb={specifyValue("30px", "0")}
        color="font.main"
        bg={specifyValue("white", "bg.subMain")}
      >
        <Link to={specifyValue(routeObj.courses, routeObj.home)}>
          <Button
            bg="none"
            columnGap={{ base: "0.4rem", md: "0.8rem" }}
            fontSize="14px"
            fontWeight="600"
            color="font.dark"
            _hover={{}}
            mb="2rem"
          >
            <Icon fontSize="18px" as={BsArrowLeft} />
            Back
          </Button>
        </Link>
        {/* Inform the user the he has already joined this course base on some conditions */}
        {props.course?.hasjoined && (
          <Text fontSize="1.2rem" fontWeight="600" color="font.dark">
            {" "}
            You have already joined this course!!!{" "}
            <Link
              to={`${routeObj.courseLectures}${props.course.course_batch_id}`}
            >
              <Text
                color="font.main"
                textDecoration={"underline"}
                display={"inline-block"}
              >
                GO TO COURSE LECTURES
              </Text>
            </Link>
          </Text>
        )}
        <Heading
          fontSize={customStyle.fontSize.bannerText}
          fontWeight={specifyValue("700", "500")}
          color="font.main"
          textTransform={"capitalize"}
        >
          {props.isLoading ? <Skeleton /> : props.course?.name}
        </Heading>
        <Divider
          mt={"8px"}
          orientation="horizontal"
          borderColor="#FDE82A"
          borderWidth={"5px"}
          w={{ base: "100px", md: "175px" }}
        />

        <Box
          w="100%"
          pt={{ base: "2rem", md: "70px" }}
          pb={specifyValue("64px", "40px")}
          display={"flex"}
          justifyContent="center"
        >
          {props.isLoading ? (
            <ThreeCardSkeleton />
          ) : (
            <>
              {!props.course.introVideo && (
                <Image
                  w={"100%"}
                  height={{ base: "300px", md: "500px" }}
                  objectFit={"cover"}
                  src={props.course?.image}
                />
              )}
              {props.course.introVideo && (
                <iframe
                  src={`${props.course?.introVideo}?rel=0`}
                  // src={"https://www.youtube.com/embed/k6eE3c70hgg"}
                  width="100%"
                  height="500"
                  title="Intro video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              )}
            </>
          )}
        </Box>
        {/* desktop */}
        {props.course?.hasjoined !== true && (
          <>
            <Box display={{ base: "none", md: "block" }}>
              {props.batches?.length > 0 && (
                <Heading
                  color="font.main"
                  fontSize={customStyle.fontSize.sectionHeader}
                  // textAlign={"center"}
                  pb="1.5rem"
                >
                  Available batches for this course
                </Heading>
              )}
              {props.isLoading ? (
                <ThreeCardSkeleton />
              ) : (
                <OwlCarousel
                  className="owl-theme"
                  items={specifyValue(1.9, 2.2)}
                  margin={20}
                  nav
                >
                  {props.batches?.length > 0 &&
                    props.batches?.map((batch, index) => (
                      <>
                        {index === 0 ? (
                          <Card
                            m="12px"
                            key={batch.name}
                            pt="2rem"
                            pb={specifyValue("2rem", "32px")}
                            pe="1.5rem"
                            ps={specifyValue("1.5rem", "32px")}
                            w={"500px"}
                            bg="white"
                            onClick={onOpen}
                            variant="elevated"
                          >
                            <CardBody bg="white" color="font.dark">
                              <Heading
                                fontWeight={"700"}
                                fontSize={customStyle.fontSize.cardTitle}
                                color="font.main"
                                textTransform={"capitalize"}
                              >
                                {capitalizeFirstLetter(
                                  batch.name.replace("-", " ")
                                )}
                              </Heading>
                              <Box
                                color="font.dark"
                                fontSize={customStyle.fontSize.paragragh}
                                lineHeight="2rem"
                                pt="1.6rem"
                              >
                                <Text fontWeight="400" pb="6px">
                                  {dateFormatter(batch.start_date)}
                                </Text>
                                <Text fontWeight="400" py="6px">
                                  {batch &&
                                  typeof JSON.parse(batch.days) !== "string"
                                    ? JSON.parse(batch.days)?.map(
                                        (days: string, index: number) =>
                                          `${days} ${index !== 2 ? "/" : ""}`
                                      )
                                    : ""}
                                  {/* {batch && batch.days} */}
                                </Text>
                                <Flex py="2px" columnGap={"6px"}>
                                  <Text>Time: </Text>
                                  <Text>{formatRealTime(batch.time)}</Text>
                                </Flex>
                                <Flex py="6px" columnGap={"6px"}>
                                  <Text>Location: </Text>
                                  <Text>Online</Text>
                                </Flex>
                                <Flex py="6px" columnGap={"6px"}>
                                  <Text>Status: </Text>
                                  <Text
                                    color={
                                      batch.still_active == "TRUE" &&
                                      batch.status == "ACTIVE"
                                        ? "red"
                                        : "#2CA900"
                                    }
                                  >
                                    {batch.still_active == "TRUE" &&
                                    batch.status == "ACTIVE"
                                      ? "Recently Started"
                                      : batch.status}
                                  </Text>
                                </Flex>
                                <Flex py="10px" columnGap={"6px"}>
                                  <Text color="font.main">PRICE: </Text>
                                  <Text>
                                    <b>
                                      {" "}
                                      ₦
                                      {numberFormatter(
                                        props.course.batch_price
                                      )}
                                      {" Monthly"}
                                    </b>
                                  </Text>
                                </Flex>
                              </Box>
                              <CourseCountDown real_date={batch.start_date} />
                              <Button
                                mt="32px"
                                w="100%"
                                h="79px"
                                bg="bg.main"
                                _hover={{}}
                                color="white"
                                // py={"50px"}
                                borderRadius="none"
                                fontSize={"1rem"}
                                onClick={() => {
                                  route(
                                    `${routeObj.checkout}session/${batch.id}`
                                  );
                                }}
                              >
                                <Center columnGap={"30px"}>
                                  GET ENROLLED <Icon as={AiOutlineArrowRight} />
                                </Center>
                              </Button>
                            </CardBody>
                          </Card>
                        ) : (
                          <Card
                            m="12px"
                            key={batch.name}
                            pt="2rem"
                            pb={specifyValue("2rem", "32px")}
                            pe="1.5rem"
                            ps={specifyValue("1.5rem", "32px")}
                            w={"500px"}
                            bg="white"
                            onClick={onOpen}
                            variant="elevated"
                          >
                            <CardBody
                              bg="white"
                              color="font.dark"
                              opacity={"0.5"}
                            >
                              <Heading
                                fontWeight={"700"}
                                fontSize={customStyle.fontSize.cardTitle}
                                color="font.main"
                                textTransform={"capitalize"}
                              >
                                {capitalizeFirstLetter(
                                  batch.name.replace("-", " ")
                                )}
                              </Heading>
                              <Box
                                color="font.dark"
                                fontSize={customStyle.fontSize.paragragh}
                                lineHeight="2rem"
                                pt="1.6rem"
                              >
                                <Text fontWeight="400" pb="6px">
                                  {dateFormatter(batch.start_date)}
                                </Text>
                                <Text fontWeight="400" py="6px">
                                  {batch &&
                                  typeof JSON.parse(batch.days) !== "string"
                                    ? JSON.parse(batch.days)?.map(
                                        (days: string, index: number) =>
                                          `${days} ${index !== 2 ? "/" : ""}`
                                      )
                                    : ""}
                                  {/* {batch && batch.days} */}
                                </Text>
                                <Flex py="2px" columnGap={"6px"}>
                                  <Text>Time: </Text>
                                  <Text>{formatRealTime(batch.time)}</Text>
                                </Flex>
                                <Flex py="6px" columnGap={"6px"}>
                                  <Text>Location: </Text>
                                  <Text>Online</Text>
                                </Flex>
                                <Flex py="6px" columnGap={"6px"}>
                                  <Text>Status: </Text>
                                  <Text color={"#2CA900"}>{batch.status}</Text>
                                </Flex>
                                <Flex py="10px" columnGap={"6px"}>
                                  <Text color="font.main">PRICE: </Text>
                                  <Text>
                                    <b>
                                      {" "}
                                      ₦
                                      {numberFormatter(
                                        props.course.batch_price
                                      )}
                                      {" Monthly"}
                                    </b>
                                  </Text>
                                </Flex>
                              </Box>
                              <CourseCountDown real_date={batch.start_date} />
                              <Button
                                mt="32px"
                                w="100%"
                                h="79px"
                                bg="bg.main"
                                _hover={{}}
                                color="white"
                                // py={"50px"}
                                borderRadius="none"
                                fontSize={"1rem"}
                                onClick={onOpen}
                                isDisabled
                              >
                                <Center columnGap={"30px"}>
                                  GET ENROLLED <Icon as={AiOutlineArrowRight} />
                                </Center>
                              </Button>
                            </CardBody>
                          </Card>
                        )}
                      </>
                    ))}
                </OwlCarousel>
              )}
            </Box>
            <Box display={{ base: "block", md: "none" }}>
              {props.isLoading ? (
                <ThreeCardSkeleton />
              ) : (
                <OwlCarousel className="owl-theme" items={1} margin={20} nav>
                  {props.batches?.length &&
                    props.batches?.map((batch, index) => (
                      <>
                        {index === 0 ? (
                          <Card
                            m="12px"
                            key={batch.name}
                            pt="1.2rem"
                            pb={"1.2rem"}
                            pe="1rem"
                            ps={1}
                            w={"100%"}
                            bg="white"
                            variant="elevated"
                          >
                            <CardBody bg="white" color="font.dark">
                              <Heading
                                fontWeight={"700"}
                                fontSize={customStyle.fontSize.cardTitle}
                                color="font.main"
                              >
                                {capitalizeFirstLetter(
                                  batch.name.replace("-", " ")
                                )}
                              </Heading>
                              <Box
                                color="font.dark"
                                fontSize={customStyle.fontSize.paragragh}
                                lineHeight="2rem"
                                pt="0.6rem"
                              >
                                <Text fontWeight="400" pb="2px">
                                  {dateFormatter(batch.start_date)}
                                </Text>
                                <Text fontWeight="400" py="2px">
                                  {batch &&
                                  typeof JSON.parse(batch.days) !== "string"
                                    ? JSON.parse(batch.days)?.map(
                                        (days: string, index: number) =>
                                          `${days} ${index !== 2 ? "/" : ""}`
                                      )
                                    : ""}
                                  {/* {batch && batch.days} */}
                                </Text>
                                <Flex py="2px" columnGap={"6px"}>
                                  <Text>Time: </Text>
                                  <Text>{formatRealTime(batch.time)}</Text>
                                </Flex>
                                <Flex py="2px" columnGap={"6px"}>
                                  <Text>Location: </Text>
                                  <Text>Online</Text>
                                </Flex>
                                <Flex py="2px" columnGap={"2px"}>
                                  <Text>Status: </Text>
                                  <Text
                                    color={
                                      batch.still_active == "TRUE" &&
                                      batch.status == "ACTIVE"
                                        ? "red"
                                        : "#2CA900"
                                    }
                                  >
                                    {" "}
                                    {batch.still_active == "TRUE" &&
                                    batch.status == "ACTIVE"
                                      ? "Recently Started"
                                      : batch.status}
                                  </Text>
                                </Flex>
                                <Flex py="4px" columnGap={"6px"}>
                                  <Text color="font.main">PRICE: </Text>
                                  <Text>
                                    <b> #{props.course.batch_price}/per mo</b>
                                  </Text>
                                </Flex>
                              </Box>
                              <CourseCountDown real_date={batch.start_date} />
                              <Button
                                mt="32px"
                                w="100%"
                                h={{ base: "3rem", md: "79px" }}
                                bg="bg.main"
                                _hover={{}}
                                color="white"
                                // py={"50px"}
                                borderRadius="none"
                                fontSize={{ base: "0.8rem", md: "1rem" }}
                                onClick={onOpen}
                              >
                                <Center columnGap={"30px"}>
                                  GET ENROLLED <Icon as={AiOutlineArrowRight} />
                                </Center>
                              </Button>
                            </CardBody>
                          </Card>
                        ) : (
                          <Card
                            m="12px"
                            key={batch.name}
                            pt="1.2rem"
                            pb={"1.2rem"}
                            pe="1rem"
                            ps={1}
                            w={"100%"}
                            bg="white"
                            variant="elevated"
                          >
                            <CardBody
                              bg="white"
                              color="font.dark"
                              opacity={"0.5"}
                            >
                              <Heading
                                fontWeight={"700"}
                                fontSize={customStyle.fontSize.cardTitle}
                                color="font.main"
                              >
                                {capitalizeFirstLetter(
                                  batch.name.replace("-", " ")
                                )}
                              </Heading>
                              <Box
                                color="font.dark"
                                fontSize={customStyle.fontSize.paragragh}
                                lineHeight="2rem"
                                pt="0.6rem"
                              >
                                <Text fontWeight="400" pb="2px">
                                  {dateFormatter(batch.start_date)}
                                </Text>
                                <Text fontWeight="400" py="2px">
                                  {batch &&
                                  typeof JSON.parse(batch.days) !== "string"
                                    ? JSON.parse(batch.days)?.map(
                                        (days: string, index: number) =>
                                          `${days} ${index !== 2 ? "/" : ""}`
                                      )
                                    : ""}
                                  {/* {batch && batch.days} */}
                                </Text>
                                <Flex py="2px" columnGap={"6px"}>
                                  <Text>Time: </Text>
                                  <Text>{formatRealTime(batch.time)}</Text>
                                </Flex>
                                <Flex py="2px" columnGap={"6px"}>
                                  <Text>Location: </Text>
                                  <Text>Online</Text>
                                </Flex>
                                <Flex py="2px" columnGap={"2px"}>
                                  <Text>Status: </Text>
                                  <Text color={"#2CA900"}>{batch.status}</Text>
                                </Flex>
                                <Flex py="4px" columnGap={"6px"}>
                                  <Text color="font.main">PRICE: </Text>
                                  <Text>
                                    <b> #{props.course.batch_price}/per mo</b>
                                  </Text>
                                </Flex>
                              </Box>
                              <CourseCountDown real_date={batch.start_date} />
                              <Button
                                mt="32px"
                                w="100%"
                                h={{ base: "3rem", md: "79px" }}
                                bg="bg.main"
                                _hover={{}}
                                color="white"
                                // py={"50px"}
                                borderRadius="none"
                                fontSize={{ base: "0.8rem", md: "1rem" }}
                                isDisabled
                              >
                                <Center columnGap={"30px"}>
                                  GET ENROLLED <Icon as={AiOutlineArrowRight} />
                                </Center>
                              </Button>
                            </CardBody>
                          </Card>
                        )}
                      </>
                    ))}
                </OwlCarousel>
              )}
            </Box>
          </>
        )}

        <Box pt={{ base: "3rem", md: "100px" }}>
          <Heading
            color="font.main"
            fontSize={customStyle.fontSize.sectionHeader}
          >
            More about this course
          </Heading>
          {props.isLoading ? (
            <SkeletonText mt="4" noOfLines={4} spacing="4" />
          ) : (
            <Box
              pb="5rem"
              pt={{ base: "2rem", md: "2.5rem" }}
              lineHeight={{ base: "1.7rem", md: "3rem" }}
              color="font.dark"
              fontSize={{ base: "0.7rem", md: "1.5rem" }}
            >
              {/* <Text pb={{ base: "0.5rem", md: "20px" }}>
                {escapeHtml(props.course?.description)}
              </Text> */}
              <Box
                pb={{ base: "0.5rem", md: "20px" }}
                fontSize={{ base: "0.9rem", md: "1.2rem" }}
                dangerouslySetInnerHTML={{ __html: props.course?.description }}
              ></Box>
            </Box>
          )}
        </Box>

        <Box
          pt={{ base: "0.4rem", md: "30px" }}
          pb={{ base: "5rem", md: "104px" }}
        >
          <Heading
            color="font.main"
            fontSize={customStyle.fontSize.sectionHeader}
          >
            Syllabuls
          </Heading>
          <Box
            pt={{ base: "2rem", md: "2.5rem" }}
            lineHeight={{ base: "1.7rem", md: "3rem" }}
            color="font.dark"
            fontSize={{ base: "0.7rem", md: "1.5rem" }}
          >
            <Text
              dangerouslySetInnerHTML={{ __html: props.course?.syllabus }}
            ></Text>
          </Box>
        </Box>
        <Box
          pt={{ base: "0.4rem", md: "30px" }}
          pb={{ base: "5rem", md: "104px" }}
        >
          <Heading
            color="font.main"
            fontSize={customStyle.fontSize.sectionHeader}
          >
            Prerequisite
          </Heading>
          <Box
            pt={{ base: "2rem", md: "2.5rem" }}
            lineHeight={{ base: "1.7rem", md: "3rem" }}
            color="font.dark"
            fontSize={{ base: "0.7rem", md: "1.5rem" }}
          >
            <Box
              pb={{ base: "0.5rem", md: "20px" }}
              dangerouslySetInnerHTML={{ __html: props.course?.prerequisites }}
            ></Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
