import {
  Stack,
  Box,
  Flex,
  Image,
  Button,
  chakra,
  IconButton,
  Collapse,
  useDisclosure,
  Divider,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { routeObj } from "../constants/routes";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { customStyle } from "../utils/style";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../features/authSlice";
import { useEffect } from "react";
export default function Navbar() {
  const { isOpen, onToggle } = useDisclosure();
  const pathName = useLocation().pathname;
  const user = useSelector(selectCurrentUser);
  const { pathname } = useLocation();
  // Make page scroll back to the top on render
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0 });
    }, 3000);
  }, [pathname]);
  return (
    <Box>
      <Flex
        fontWeight={customStyle.fontWeight.navItem}
        ps={customStyle.padding.psHome}
        h={{ base: "70px", md: "104px" }}
        justifyContent={{ base: "space-between", md: "space-around" }}
        alignItems={"center"}
        bg={{ base: "bg.subMain", md: "bg.main" }}
        color={"#FFFFFF"}
        fontSize={customStyle.fontSize.navItem}
        pe={customStyle.padding.peHome}
      >
        <Flex
          flex={1}
          transition={"0.6s"}
          alignItems="center"
          columnGap="5px"
          fontSize={customStyle.fontSize.logoText}
          _hover={{}}
          color={pathName === "/" ? "font.main" : "#FFFFFF"}
        >
          <Link to={routeObj.home}>
            <Image
              boxSize={"100%"}
              src="/logo/logo.png"
              display={{ base: "none", md: "inline-flex" }}
            />
            <Image
              src="/logo/logo-dark.png"
              w="40%"
              display={{ base: "inline-flex", md: "none" }}
            />
          </Link>
        </Flex>
        <IconButton
          onClick={onToggle}
          display={{ base: "inline-flex", md: "none" }}
          color={pathName === "/" ? "font.main" : "white"}
          variant="none"
          fontSize="1.2rem"
          icon={isOpen ? <MdClose /> : <FiMenu />}
          aria-label="Menu button"
        />

        <Flex
          display={{ base: "none", md: "flex" }}
          flex={1}
          justifyContent={"right"}
          columnGap="40px"
          fontWeight={"600"}
        >
          <chakra.a
            _hover={{}}
            alignItems={"center"}
            display="flex"
            transition="0.6s"
            href="#courses"
          >
            Courses
          </chakra.a>
          <chakra.a
            href="#faq"
            alignItems={"center"}
            display="flex"
            _hover={{}}
            transition="0.6s"
          >
            FAQ
          </chakra.a>
          <Link to={user ? routeObj.courses : routeObj.login}>
            <Button
              alignItems={"center"}
              cursor="pointer"
              _hover={{}}
              transition="0.6s"
              borderRadius={"sm"}
              bg="#FDE82A"
              py="1rem"
              px="2rem"
              color="font.main"
            >
              {user ? "Dashbord" : "Login"}
            </Button>
          </Link>
        </Flex>
      </Flex>
      <Collapse
        in={isOpen}
        animateOpacity
        style={{ marginTop: "0!important", transition: "0.6s" }}
      >
        <MobileNav />
      </Collapse>
    </Box>
  );
}

function MobileNav() {
  const user = useSelector(selectCurrentUser);
  return (
    <Stack
      py="20px"
      margin={0}
      minH="25vh"
      display={{ base: "block", md: "none" }}
      bg="white"
      justifyContent={"right"}
      columnGap="40px"
      fontWeight={"600"}
      color="font.dark"
      px="1rem"
      zIndex={3}
      fontSize={"12px"}
    >
      <chakra.a
        px="30px"
        _hover={{}}
        alignItems={"center"}
        display="flex"
        transition="0.6s"
      >
        <a href="#courses">Courses</a>
      </chakra.a>
      <Divider orientation="horizontal" borderColor="#00000029" />
      <chakra.a
        href="#faq"
        px="30px"
        alignItems={"center"}
        display="flex"
        _hover={{}}
        transition="0.6s"
      >
        FAQ
      </chakra.a>
      <Divider orientation="horizontal" borderColor="#00000029" />
      <Link to={user ? routeObj.courses : routeObj.login}>
        <Text
          alignItems={"center"}
          cursor="pointer"
          _hover={{ bg: "yellow.300", fontSize: "17px" }}
          transition="0.6s"
          borderRadius={"sm"}
          py="2px"
          px="30px"
          color="font.main"
        >
          {user ? "Dashboard" : "Login"}
        </Text>
      </Link>
    </Stack>
  );
}
