// export const topCourses: course = [
//   {
//     id: 1,
//     imageURL: "/assets/images/programming.png",
//     name: "WEB DESIGN",
//     status: "open",
//     location: "online",
//     date: " 20 Jan - 18 Dec 2023",
//     time: "6:00pm-77:30pm PT  Wed, Thur & Fri.",
//   },
//   {
//     id: 2,
//     imageURL: "/assets/images/programming.png",
//     name: "UI UX DESIGN",
//     status: "open",
//     location: "online",
//     date: " 20 Jan - 18 Dec 2023",
//     time: "6:00pm-77:30pm PT  Wed, Thur & Fri.",
//   },
//   {
//     id: 3,
//     imageURL: "/assets/images/programming.png",
//     name: "WEB DEVELOPMENT",
//     status: "open",
//     location: "online",
//     date: "20 Jan - 18 Dec 2023",
//     time: "6:00pm-77:30pm PT  Wed, Thur & Fri.",
//   },
//   {
//     id: 4,
//     imageURL: "/assets/images/programming.png",
//     name: "BLOCKCHAIN DEVELOPMENT",
//     status: "open",
//     location: "online",
//     date: " 20 Jan - 18 Dec 2023",
//     time: "6:00pm-77:30pm PT  Wed, Thur & Fri.",
//   },
// ];

// export const standAloneCourses: course = [
//   {
//     id: 1,
//     imageURL: "/assets/images/programming.png",
//     name: "HTML & CSS",
//     status: "open",
//     location: "online",
//     date: " 20 Jan - 18 Dec 2023",
//     time: "6:00pm-77:30pm PT  Wed, Thur & Fri.",
//   },
//   {
//     id: 2,
//     imageURL: "/assets/images/programming.png",
//     name: "REACT JS",
//     status: "open",
//     location: "online",
//     date: " 20 Jan - 18 Dec 2023",
//     time: "6:00pm-77:30pm PT  Wed, Thur & Fri.",
//   },
//   {
//     id: 3,
//     imageURL: "/assets/images/programming.png",
//     name: "NODEJS & EXPRESS",
//     status: "open",
//     location: "online",
//     date: " 20 Jan - 18 Dec 2023",
//     time: "6:00pm-77:30pm PT  Wed, Thur & Fri.",
//   },
// ];

export const courseCard = [
  {
    id: 1,
    name: "Website development",
    imageUrl: "/assets/images/web-avatar.png",
    hoverImg: "/assets/images/white-cardlogo/webdev.png",
  },
  {
    id: 2,
    name: "Product design",
    imageUrl: "/assets/images/product-design.png",
    hoverImg: "/assets/images/white-cardlogo/product-design.png",
  },
  {
    id: 3,
    name: "Blockchain development",
    imageUrl: "/assets/images/blockchain.png",
    hoverImg: "/assets/images/white-cardlogo/blockchain.png",
  },
  {
    id: 4,
    name: "Website design",
    imageUrl: "/assets/images/web-design.png",
    hoverImg: "/assets/images/white-cardlogo/webdesign.png",
  },
  {
    id: 5,
    name: "Html & Css",
    imageUrl: "/assets/images/html.png",
    hoverImg: "/assets/images/white-cardlogo/webdev.png",
  },
  {
    id: 6,
    name: "React JS",
    imageUrl: "/assets/images/react.png",
    hoverImg: "/assets/images/white-cardlogo/react.png",
  },
  {
    id: 7,
    name: "NodeJS & Express",
    imageUrl: "/assets/images/node.png",
    hoverImg: "/assets/images/white-cardlogo/node.png",
  },
  {
    id: 8,
    name: "UI design",
    imageUrl: "/assets/images/ui.png",
    hoverImg: "/assets/images/white-cardlogo/ui.png",
  },
];

export const courseSessions = [
  {
    name: "Session 01",
    date: " 15 June -23 August 2024",
    time: " 6:00pm - 7:00pm PT Thur/Fri",
    location: "Live online",
    status: "open",
    price: "10,000",
  },

  {
    name: "Session 02",
    date: " 15 June -23 August 2024",
    time: " 6:00pm - 7:00pm PT Thur/Fri",
    location: "Live online",
    status: "open",
    price: "10,000",
  },
  {
    name: "Session 03",
    date: " 15 June -23 August 2024",
    time: " 6:00pm - 7:00pm PT Thur/Fri",
    location: "Live online",
    status: "open",
    price: "10,000",
  },
];

// export const activeCourses: activeCoursesT = [
//   {
//     id: 1,
//     name: "React js",
//     instructor: "Williams Kelechi KC",
//     imageUrl: "/assets/images/blockchain1.png",
//   },

//   {
//     id: 2,
//     name: "Html & Css",
//     instructor: "Williams Kelechi KC",
//     imageUrl: "/assets/images/blockchain1.png",
//   },

//   {
//     id: 3,
//     name: "Introduction to webdesign",
//     instructor: "Williams Kelechi KC",
//     imageUrl: "/assets/images/blockchain1.png",
//   },
// ];
