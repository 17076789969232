import {
  Box,
  Heading,
  Button,
  Flex,
  Icon,
  Text,
  chakra,
  Avatar,
  Spinner,
  useDisclosure,
  Center,
  Image,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Modal,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { HiDownload } from "react-icons/hi";
import { MdClass } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import courseService from "../../app/services/courseService";
import AssignmentModal from "../../components/modal/Assignment";
import ThreeCardSkeleton from "../../components/skeletons/ThreeCardSkeleton";
import { routeObj } from "../../constants/routes";
import Layout from "../../layouts/dashboard/Layout";
import { useCustomToast } from "../../utils/toast";
import { customStyle } from "../../utils/style";
import moment from "moment";

export default function CourseLectureView() {
  const { id } = useParams();
  const [lecture, setLecture] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { notifyError, notifySuccess } = useCustomToast();
  const [classLoading, setClassLoading] = useState(false);
  const [lectureLink, setLectureLink] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const joinClass = () => {
    setClassLoading(true);
    courseService
      .joinClass(id!)
      .then((res) => {
        // console.log(res);
        if (
          res.data.message_type === "TAKEN" ||
          res.data.message_type === "ALREADY_TAKEN"
        ) {
          notifySuccess("joining class...");
          window.open(lecture?.lecture_link);
        } else if (res.data.message_type === "TOO_EARLY") {
          setLectureLink(lecture?.lecture_link);
          onOpen2();
          notifySuccess("too early to Enter classroom");
        } else {
          notifyError("Unable to Enter classroom");
        }
        setClassLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setClassLoading(false);
      });
  };
  const startTime = moment(lecture?.lecture_time);
  const now = moment(new Date());
  const duration = moment.duration(startTime.diff(now));
  const diff = duration.asMinutes();
  useEffect(() => {
    courseService
      .getLectureById(id!)
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        setLecture(res.data);
        if (Object.keys(res.data).length === 0) {
          navigate(routeObj.courses);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [id]);
  return (
    <Layout>
      <Modal
        isOpen={isOpen2}
        size={{ base: "xs", md: "4xl" }}
        onClose={onClose2}
        closeOnOverlayClick={false}
        scrollBehavior={"inside"}
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(10deg)"
        />
        <ModalContent>
          <ModalCloseButton color="font.main" />
          <ModalBody
            bg="white"
            color="font.main"
            overflowX="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#C4C4C4",
                borderRadius: "24px",
              },
            }}
          >
            <Box
              bgSize="contain"
              bgRepeat={"no-repeat"}
              px={{ base: "0.8rem", md: "1rem" }}
              pt="2rem"
              pb="2rem"
              color="font.main"
            >
              <Heading
                textAlign="center"
                fontSize={customStyle.fontSize.sectionHeader}
                fontWeight="600"
              >
                Class Not Started
              </Heading>
              <Box
                bg="white"
                columnGap="20px"
                rowGap="20px"
                flexWrap="wrap"
                pt={{ base: "30px", md: "3rem" }}
                pb="30px"
              >
                <Text textAlign={"center"}>
                  {" "}
                  This class has not started and instructors are not available
                  yet.{" "}
                </Text>
                <Center mt="1.9rem">
                  <Button
                    display="flex"
                    type="submit"
                    w={{ base: "100%", md: "100%" }}
                    alignSelf={"center"}
                    _hover={{ bg: "blue.700", color: "gray.200" }}
                    transition="0.6s"
                    borderRadius={"sm"}
                    bg="bg.main"
                    h="4rem"
                    color="#ffffff"
                    justifyContent={"center"}
                    fontSize={"1.1rem"}
                    isDisabled={isLoading}
                    onClick={() => {
                      window.open(lecture?.lecture_link);
                      onClose2();
                    }}
                  >
                    <Center columnGap="20px">
                      JOIN ANYWAYS?{" "}
                      <Spinner display={isLoading ? "inline-flex" : "none"} />
                    </Center>
                  </Button>
                </Center>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box
        pt={{ base: "3rem", md: "108px" }}
        pb="124px"
        px={{ base: "1rem", md: "57px" }}
      >
        <Link to={`${routeObj.courseLectures}${lecture?.course_batch_id}`}>
          <Button
            bg="none"
            columnGap={{ base: "0.4rem", md: "0.8rem" }}
            fontSize="14px"
            fontWeight="600"
            color="font.dark"
            _hover={{}}
          >
            <Icon fontSize="18px" as={BsArrowLeft} />
            Back
          </Button>
        </Link>
        <Heading
          color="font.dark"
          textAlign="center"
          fontSize={{ base: "1.8rem", md: "40px" }}
          fontWeight="600"
        >
          {lecture?.title}
        </Heading>

        {isLoading ? (
          <ThreeCardSkeleton />
        ) : (
          <>
            {lecture?.video && (
              <Box pt={{ base: "2.5rem", md: "72px" }}>
                <iframe
                  // src={course?.introVideo}
                  src={`${lecture?.video}?rel=0`}
                  width="100%"
                  height="500"
                  title="Intro video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
                {/* <iframe width="875" height="492" src="https://www.youtube.com/embed/k6eE3c70hgg" title="Omah Lay -  soso (Official Music Video)" frameborder="0" ></iframe> */}
              </Box>
            )}
            {!lecture?.video && (
              <Box pt="2rem">
                <Box
                  color="white"
                  pt="29px"
                  ps="29px"
                  w="100%"
                  h={{ base: "310px", md: "520px" }}
                  bg="bg.main"
                  mt={{ base: "1.2rem", md: "2rem" }}
                >
                  <Text>
                    {" "}
                    <Flex columnGap={"6px"} alignContent="center">
                      <Center>
                        <Image
                          w={{ base: "1rem", md: "50%" }}
                          src="/logo/logo.png"
                        />
                      </Center>
                    </Flex>
                  </Text>
                  <Box
                    px={{ base: "1rem", md: "120px" }}
                    pt={{ base: "0.9rem", md: "3rem" }}
                  >
                    <Heading
                      fontSize={{ base: "1.2rem", md: "2.2rem" }}
                      fontWeight="500"
                      textAlign="center"
                      lineHeight={{ base: "1.8rem", md: "57.61px" }}
                    >
                      Hey, video will be uploaded here after the live class
                    </Heading>
                    <Center pt={{ base: "1.8rem", md: "49px" }}>
                      <Image
                        boxSize={{ base: "140px", md: "220px" }}
                        src="/assets/images/thumb-up.png"
                      />
                    </Center>
                  </Box>
                </Box>
              </Box>
            )}
          </>
        )}

        <Flex
          pt={{ base: "2rem", md: "83px" }}
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          rowGap="1rem"
          display={diff < -60 || diff > 60 ? "none" : "flex"}
        >
          <Box>
            {" "}
            <Button
              bg="bg.main"
              w={{ base: "100%", md: "223px" }}
              h={{ base: "2.1rem", md: "47px" }}
              color="white"
              fontSize={{ base: "0.9rem", md: "24px" }}
              _hover={{ bg: "blue.700", color: "gray.200" }}
              transition="0.6s"
              borderRadius={"none"}
              columnGap={{ base: "0.6rem", md: "20px" }}
              title="Enter classroom When It Starts"
              onClick={joinClass}
            >
              Enter classroom
              <Icon as={MdClass} />
              <Spinner display={classLoading ? "block" : "none"} />
            </Button>
          </Box>
        </Flex>
        <Box pt={{ base: "3rem", md: "20px" }} color="font.dark">
          {/* <Box>
            <Heading fontSize={{ base: "1.3rem", md: "32px" }} fontWeight="600">
              Submit your tasks and assignments{" "}
            </Heading>

            <Box mt="16px">
              {" "}
              <Button
                bg="bg.main"
                w={{ base: "100%", md: "223px" }}
                h={{ base: "2.1rem", md: "47px" }}
                color="white"
                fontSize={{ base: "0.9rem", md: "24px" }}
                _hover={{ bg: "blue.700", color: "gray.200" }}
                transition="0.6s"
                borderRadius={"none"}
                columnGap={{ base: "0.6rem", md: "20px" }}
                onClick={onOpen}
                isDisabled={!lecture?.assignment}
                title="Submit Assignment After Class"
              >
                Click To Submit
              </Button>
              <AssignmentModal
                isOpen={isOpen}
                data={{
                  lecture_id: lecture?.id,
                  course_batch_id: lecture?.course_batch_id,
                  course_id: lecture?.course_id,
                }}
                onClose={onClose}
              />
            </Box>
          </Box> */}

          <Box pt={{ base: "2rem", md: "52px" }}>
            <Heading fontSize={{ base: "1.3rem", md: "32px" }} fontWeight="600">
              Course Content Link{" "}
            </Heading>
            <chakra.a
              fontSize={{ base: "0.9rem", md: "1.5rem" }}
              fontWeight="400"
              mt="16px"
              color="#0479EE"
              href={lecture?.assignment}
              target="_blank"
            >
              {lecture?.assignment
                ? lecture?.assignment
                : "Any course content used by instructor will show here"}
            </chakra.a>
          </Box>
          <Box pt={{ base: "2rem", md: "52px" }}>
            <Heading fontSize={{ base: "1.3rem", md: "32px" }} fontWeight="600">
              Classwork{" "}
            </Heading>
            <chakra.a
              fontSize={{ base: "0.9rem", md: "1.5rem" }}
              fontWeight="400"
              mt="16px"
              color="#0479EE"
              href={lecture?.classwork}
              target="_blank"
            >
              {lecture?.classwork
                ? lecture?.classwork
                : "Link to the classwork by instructor will show here"}
            </chakra.a>
          </Box>
          {/* <Box pt={{ base: "2rem", md: "52px" }}>
            <Heading fontSize={{ base: "1.3rem", md: "32px" }} fontWeight="600">
              Learning resources
            </Heading>
            <chakra.a
              fontSize={{ base: "0.9rem", md: "1.5rem" }}
              fontWeight="400"
              mt="16px"
              color="#0479EE"
              href="https://docs.google.com/document/d/1buWYPmo-gKEMN6j0vrww4/edit"
            >
              https://docs.google.com/document/d/1buWYPmo-gKEMN6j0vrww4/edit
            </chakra.a>
          </Box> */}

          {/* <Box pt={{ base: "2rem", md: "85px" }}>
            <Heading fontSize={{ base: "1.3rem", md: "32px" }} fontWeight="600">
              Instructor’s comment
            </Heading>
            <Box pt={{ base: "2rem", md: "80px" }}>
              <Avatar
                ms={{ base: "1.8rem", md: "31px" }}
                boxSize={{ base: "50px", md: "107px" }}
                src="/assets/images/instructor-1.png"
              />
              <Text
                fontSize={{ base: "0.8rem", md: "20px" }}
                fontWeight="500"
                mt="10px"
              >
                Williams Kelechi KC
              </Text>
            </Box>
            <Box pt="43px">
              <Text
                bg="#F8FAFF"
                fontSize={{ base: "0.9rem", md: "1.5rem" }}
                fontWeight="400"
                py={{ base: "2rem", md: "41px" }}
                px={{ base: "1rem", md: "40px" }}
              >
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </Text>
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Layout>
  );
}
