import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";

const slice = createSlice({
  name: "constant",
  initialState: {} as { compliant: CompliantType[] },
  reducers: {
    setCompliants: (
      state,
      { payload: { compliant } }: PayloadAction<{ compliant: CompliantType[] }>
    ) => {
      state.compliant = compliant;
    },
    reset: (state) => {
      state.compliant = [];
    },
  },
  extraReducers: (builder) => {},
});

export const { setCompliants, reset } = slice.actions;

export default slice.reducer;

export const selectCompliant = (state: RootState) => state.compliants.compliant;
