import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Box,
  ModalOverlay,
  Heading,
  Text,
  Spinner,
  Divider,
  Input,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import userService from "../../app/services/userService";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser, setUser } from "../../features/authSlice";
import { useCustomToast } from "../../utils/toast";
import { on } from "events";
type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
};
export default function EditModal(props: Props) {
  const [file, setFile] = useState<FileList>();
  const uploadUrl = process.env.REACT_APP_CLOUDINARY_URL;
  const upload_Preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
  const user = useSelector(selectCurrentUser);
  const { notifyError, notifySuccess } = useCustomToast();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const uploadURL = uploadUrl;
    const uploadPreset = upload_Preset;
    if (file) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", file[0]);
      formData.append("upload_preset", `${uploadPreset}`);
      axios({
        url: uploadURL,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then((res) => {
          // console.log(res.data.secure_url);
          props.onClose();
          userService
            .updateProfile(
              {
                [props.title === "cover" ? "cover_photo" : "image"]:
                  res.data.secure_url,
              },
              user?.id!
            )
            .then((res) => {
              // console.log(res);
              notifySuccess("Update Successfull");
              dispatch(setUser(res));
              setIsLoading(false);
            })
            .catch((err) => {
              notifyError("Error occured");
              console.log(err);
              setIsLoading(false);
            });
        })
        .catch((err) => console.log(err));
    }
  }, [file]);
  const handleRemovePhoto = () => {
    setIsLoading(true);
    userService
      .updateProfile(
        {
          [props.title === "cover" ? "cover_photo" : "image"]: null,
        },
        user?.id!
      )
      .then((res) => {
        // console.log(res);
        notifySuccess("photo remove successful");
        dispatch(setUser(res));
        setIsLoading(false);
        props.onClose();
      })
      .catch((err) => {
        notifyError("Error occured");
        console.log(err);
        setIsLoading(false);
        props.onClose();
      });
  };
  return (
    <Modal
      isOpen={props.isOpen}
      size={{ base: "xs", md: "lg" }}
      onClose={props.onClose}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(10deg)"
      />
      <ModalContent>
        <ModalCloseButton color="font.main" />
        <ModalBody padding={0} bg="white">
          <Box pt={{ base: "3rem", md: "114px" }} textAlign="center">
            <Heading
              fontSize={{ base: "1rem", md: "32px" }}
              fontWeight="700"
              color="font.dark"
            >
              Change {props.title} photo
            </Heading>
            <Center>
              <Spinner display={isLoading ? "inline-flex" : "none"} />
            </Center>
            <Divider
              borderBottomWidth={{ base: "1px", md: "2px" }}
              borderColor="#C4C4C4"
              mt={{ base: "2.5rem", md: "63px" }}
            />
            <Text
              py={{ base: "0.8rem", md: "24px" }}
              color="#407BFF"
              fontSize={{ base: "0.8rem", md: "24px" }}
              fontWeight="500"
              cursor="pointer"
              onClick={() => {
                if (!isLoading) {
                  document.getElementById("upload-btn")!.click();
                }
                // props.onClose();
              }}
            >
              Upload photo
              <Input
                type="file"
                onChange={(e) => {
                  if (e.target.files) {
                    // console.log(e.target.files);
                    setFile(e.target.files!);
                  }
                }}
                hidden
                id="upload-btn"
              />
            </Text>
            <Divider
              borderBottomWidth={{ base: "1px", md: "2px" }}
              borderColor="#C4C4C4"
            />
            <Text
              py={{ base: "0.8rem", md: "24px" }}
              color="#D00000"
              fontSize={{ base: "0.8rem", md: "24px" }}
              fontWeight="500"
              cursor="pointer"
              onClick={handleRemovePhoto}
            >
              Remove Current Photo
            </Text>
            <Divider
              borderBottomWidth={{ base: "1px", md: "2px" }}
              borderColor="#C4C4C4"
            />
            <Text
              py={{ base: "0.8rem", md: "24px" }}
              color="font.dark"
              fontSize={{ base: "0.8rem", md: "24px" }}
              fontWeight="500"
              cursor="pointer"
              onClick={props.onClose}
            >
              Cancel
            </Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
