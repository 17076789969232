import Home from "../pages/Home";
import CourseDetail from "../pages/CourseDetail";
import Register from "../pages/auth/Register";
import Login from "../pages/auth/Login";
import VerifyEmail from "../pages/auth/VerifyEmail";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ResetPassword from "../pages/auth/ResetPassword";
import Courses from "../pages/dashboard/Courses";
import Notifications from "../pages/dashboard/Notifications";
import CourseDetailDashboard from "../pages/dashboard/CourseDetailDashboard";
import Checkout from "../pages/dashboard/Checkout";
import Profile from "../pages/dashboard/Profile";
import Billing from "../pages/dashboard/Billing";
import Compliant from "../pages/dashboard/Compliant";
import Certification from "../pages/dashboard/Certification";
import EditProfile from "../pages/dashboard/EditProfile";
import PrivateSession from "../pages/dashboard/PrivateSession";
import CertificationDetail from "../pages/dashboard/CertificationDetail";
import CertificationView from "../pages/dashboard/CertificationView";
import CourseWelcome from "../pages/dashboard/CourseWelcome";
import CourseLectures from "../pages/dashboard/CourseLectures";
import CourseLectureView from "../pages/dashboard/CourseLectureView";
import Privacy from "../pages/company/Privacy";
import Terms from "../pages/company/Terms";

export const routeObj = {
  home: "/",
  terms: "/terms-and-condition",
  privacy: "/privacy-policy",
  login: "/auth/login",
  register: "/auth/register",
  courseDetail: "/course/",
  forgotPassword: "/auth/forgot-password",
  verifyEmail: "/auth/verify-email",
  resetPassword: "/auth/reset-password/",
  courses: "/dashboard/courses",
  courseDetailDashboard: "/dashboard/courses/",
  courseWelcome: "/dashboard/courses/welcome/",
  courseLectures: "/dashboard/courses/lectures/",
  courseLectureView: "/dashboard/courses/lectures/view/",
  notifications: "/dashboard/notifications",
  checkout: "/dashboard/courses/checkout/",
  profile: "/dashboard/profile",
  editProfile: "/dashboard/profile/edit",
  billing: "/dashboard/billing",
  certification: "/dashboard/certification",
  compliant: "/dashboard/compliant",
  privateSession: "/dashboard/courses/private-session/",
  certificationDetail: "/dashboard/certification/",
  certificationView: "/dashboard/certification/view/",
};

export const protectedRoutes: routeList = [
  {
    path: routeObj.courses,
    component: Courses,
  },

  {
    path: `${routeObj.courseDetailDashboard}:code`,
    component: CourseDetailDashboard,
  },
  {
    path: `${routeObj.courseWelcome}:batchId/:courseId`,
    component: CourseWelcome,
  },
  {
    path: `${routeObj.courseLectures}:batchId`,
    component: CourseLectures,
  },
  {
    path: `${routeObj.courseLectureView}:id`,
    component: CourseLectureView,
  },
  {
    path: `${routeObj.privateSession}:id`,
    component: PrivateSession,
  },
  {
    path: routeObj.notifications,
    component: Notifications,
  },
  {
    path: `${routeObj.checkout}:type/:code`,
    component: Checkout,
  },
  {
    path: routeObj.profile,
    component: Profile,
  },
  {
    path: routeObj.editProfile,
    component: EditProfile,
  },
  {
    path: routeObj.billing,
    component: Billing,
  },
  {
    path: routeObj.compliant,
    component: Compliant,
  },
  {
    path: routeObj.certification,
    component: Certification,
  },
  {
    path: `${routeObj.certificationDetail}:id`,
    component: CertificationDetail,
  },
  {
    path: `${routeObj.certificationView}:id`,
    component: CertificationView,
  },
];
export const unProtectedRoutes: routeList = [
  {
    path: routeObj.home,
    component: Home,
  },
  {
    path: routeObj.privacy,
    component: Privacy,
  },
  {
    path: routeObj.terms,
    component: Terms,
  },
  {
    path: `${routeObj.courseDetail}:code`,
    component: CourseDetail,
  },
  {
    path: routeObj.verifyEmail,
    component: VerifyEmail,
  },
];

export const authRoute: routeList = [
  {
    path: routeObj.register,
    component: Register,
  },
  {
    path: routeObj.login,
    component: Login,
  },

  {
    path: routeObj.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: `${routeObj.resetPassword}:code`,
    component: ResetPassword,
  },
];
