import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Box,
  ModalOverlay,
  Heading,
  Text,
  Center,
  Flex,
  Divider,
  Avatar,
  Spinner,
} from "@chakra-ui/react";
import moment from "moment";
import { Link } from "react-router-dom";
import { momentsAgo } from "../../utils";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  notifications: NotificationType[] | undefined;
  isLoading: boolean;
};
export default function NotificationModal(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      size={{ base: "sm", md: "lg" }}
      onClose={props.onClose}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(10deg)"
      />
      <ModalContent>
        <ModalCloseButton color="font.main" />
        <ModalBody bg="white" padding={0}>
          <Box pt="53px">
            <Heading
              ps="42px"
              fontSize={{ base: "1.2rem", md: "24px" }}
              fontWeight="600"
              color="font.dark"
            >
              Notifications
            </Heading>
            <Flex
              justifyContent="space-between"
              ps="36px"
              pe="15px"
              pb="13.5px"
              mt="37.5px"
              fontSize={{ base: "0.9rem", md: "1.1rem" }}
            >
              <Text>
                All (
                {props.isLoading ? (
                  <Spinner boxSize={"10px"} />
                ) : (
                  props.notifications?.length
                )}
                ){" "}
              </Text>
              {/* <Text
                _active={{ color: "black" }}
                color="#407BFF"
                cursor={"pointer"}
              >
                Mark as all read
              </Text> */}
            </Flex>
            <Divider
              borderBottomColor={"#C4C4C4"}
              borderBottomWidth={{ base: "1px", md: "2px" }}
            />
          </Box>
          <Box pt="10px">
            <Center display={props.isLoading ? "flex" : "none"}>
              <Spinner />
            </Center>
            {props.notifications?.length &&
              props.notifications?.map((data, index) => {
                if (index < 3) {
                  return (
                    <Box
                      pt="16.16px"
                      pb="16px"
                      borderBottomColor={"#C4C4C4"}
                      borderBottomWidth="1px"
                      key={index}
                    >
                      <Flex ps="29.67px" pe="26.5px" columnGap={"12px"}>
                        <Center>
                          <Avatar src="/assets/images/icon.png" />
                        </Center>
                        <Box color="font.dark">
                          <Heading fontSize="14px" fontWeight={"600"}>
                            {data.title}
                          </Heading>
                          <Text
                            mt="9px"
                            fontSize={"10px"}
                            fontWeight="400"
                            lineHeight="20px"
                          >
                            {data.message}
                          </Text>
                          <Text
                            mt=""
                            fontWeight={"500"}
                            fontSize="10px"
                            color="font.main"
                          >
                            {" "}
                            {momentsAgo(data.created_at!)}
                          </Text>
                        </Box>
                      </Flex>
                    </Box>
                  );
                } else {
                  return;
                }
              })}
          </Box>
          {/* 
          <Box
            pt="11px"
            pb="13px"
            ps="32px"
            display={props.isLoading ? "none" : "block"}
          >
            <Link to="/dashboard/notifications">
              <Heading fontSize={"14px"} fontWeight="600" color="font.main">
                See all Notifications
              </Heading>
            </Link>
          </Box> */}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
