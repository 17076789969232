import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";

const slice = createSlice({
  name: "constant",
  initialState: {} as ConstantType,
  reducers: {
    setConstants: (
      state,
      {
        payload: { company, codes, countries, states, banks, gender },
      }: PayloadAction<ConstantType>
    ) => {
      state.company = company;
      state.codes = codes;
      state.countries = countries;
      state.states = states;
      state.banks = banks;
      state.gender = gender;
    },
  },
  extraReducers: (builder) => {},
});

export const { setConstants } = slice.actions;

export default slice.reducer;

export const selectConstants = (state: RootState) => state.constant;
