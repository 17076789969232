import {
  Box,
  Center,
  FormControl,
  Input,
  Text,
  Heading,
  Button,
  Spinner,
  Image,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { selectCurrentUser } from "../../features/authSlice";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import authService from "../../app/services/authService";
import { useCustomToast } from "../../utils/toast";
import { routeObj } from "../../constants/routes";
export default function ForgotPassword() {
  const inputFontSize = { base: "16px", md: "1.2rem" };
  const user = useSelector(selectCurrentUser);
  const route = useNavigate();
  const [isSent, setIsSent] = useState(false);
  const { notifyError, notifySuccess } = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const forgotPasswordFormik = useFormik({
    initialValues: {
      email: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      authService
        .forgotPassword(values)
        .then((res) => {
          // console.log(res);
          setIsLoading(false);
          notifySuccess(res.message);
          setIsSent(true);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          notifyError(err.response.data.message);
        });
    },
  });
  useEffect(() => {
    if (user) {
      route("/dashboard/courses");
    }
  }, [user]);
  return (
    <>
      <Box bg={isSent ? "none" : "bg.subMain"}>
        <Box pt="2.4rem" ps="2.5rem">
          {" "}
          <Link to={routeObj.home}>
            <Image
              // w={"100%"}
              w="220px"
              src="/logo/logo-dark.png"
              display={{ base: "none", md: "inline-flex" }}
            />
          </Link>
        </Box>
        <Box
          px={{ base: "7%", md: "20%" }}
          pt={{ base: "4rem", md: "5rem" }}
          color="font.dark"
          h="100vh"
          display={isSent ? "none" : "block"}
        >
          <Box textAlign={"center"}>
            <Heading fontSize={{ base: "1.5rem", md: "2rem" }} fontWeight="700">
              Trouble logging in?
            </Heading>
            <Text mt="1.2rem" fontSize={{ base: "14px", md: "1.2rem" }}>
              Enter your email, and we’ll send a link to get <br /> back into
              your account.
            </Text>
          </Box>
          <Box pt={{ base: "2rem", md: "3rem" }} pb="20px">
            <form onSubmit={forgotPasswordFormik.handleSubmit}>
              <FormControl
                // mt="25px"
                borderColor={"font.dark"}
                borderWidth={{ base: "1px", md: "1px" }}
                px={{ base: "10px", md: "" }}
                // borderBottomStyle={"solid"}
                py={{ base: "10px", md: "0.8rem" }}
                w={{ base: "100%", md: "450px" }}
                mx="auto"
                // h="70px"
              >
                <Input
                  fontSize={inputFontSize}
                  fontWeight={"400"}
                  name="email"
                  value={forgotPasswordFormik.values.email}
                  onChange={forgotPasswordFormik.handleChange}
                  onBlur={forgotPasswordFormik.handleBlur}
                  variant={"unstyled"}
                  placeholder="Email address"
                  borderColor={"font.main"}
                  color="font.dark"
                  _placeholder={{ color: "font.dark" }}
                  px="1rem"
                  isDisabled={isLoading}
                />
              </FormControl>
              <Box mx="auto" w={{ base: "100%", md: "450px" }}>
                {" "}
                <Text color="red" fontSize="12px">
                  {forgotPasswordFormik.errors.email &&
                    forgotPasswordFormik.errors.email}
                </Text>
              </Box>
              <Center mt={{ base: "1.2rem", md: "1.9rem" }}>
                <Button
                  type="submit"
                  display="flex"
                  w={{ base: "100%", md: "450px" }}
                  alignSelf={"center"}
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.6s"
                  borderRadius={"sm"}
                  bg="bg.main"
                  h="4rem"
                  color="#ffffff"
                  justifyContent={"center"}
                  fontSize={{ base: "0.8rem", md: "1.1rem" }}
                  isDisabled={isLoading}
                >
                  <Center columnGap="20px">
                    SUBMIT{" "}
                    <Spinner display={isLoading ? "inline-flex" : "none"} />
                  </Center>
                </Button>
              </Center>
            </form>
          </Box>
        </Box>
        <Box
          bg="white"
          w="100%"
          color="font.dark"
          px={{ base: "1.2rem", md: "2rem" }}
          textAlign={"center"}
          py="4rem"
          display={isSent ? "block" : "none"}
        >
          <Center>
            <Heading fontSize={{ base: "1.5rem", md: "2rem" }}>
              Link sent successfully
            </Heading>
          </Center>
          <Center mt={{ base: "1.5rem", md: "3rem" }}>
            <Image
              boxSize={{ base: "5rem", md: "8rem" }}
              src="/assets/images/checked.png"
            />
          </Center>

          <Center mt={{ base: "1.5rem", md: "3rem" }}>
            <Text fontSize={{ base: "0.8rem", md: "1rem" }}>
              {" "}
              You should soon receive an email allowing you to reset your
              password. Please make sure to check your spam and trash if you
              can't find the email.
            </Text>
          </Center>
        </Box>
      </Box>
    </>
  );
}
