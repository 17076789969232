import {
  Box,
  Flex,
  Center,
  Icon,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Image,
  Button,
  Text,
  Select,
  Spinner,
} from "@chakra-ui/react";
import Layout from "../../layouts/dashboard/Layout";
// import { FaGithub, FaLinkedin, FaTelegram } from "react-icons/fa";
import useProfileUpdate from "../../hooks/useProfileUpdate";
import { Link, useNavigate } from "react-router-dom";
import { routeObj } from "../../constants/routes";
import { useSelector } from "react-redux";
import { selectConstants } from "../../features/constantSlice";
import { BsArrowLeft } from "react-icons/bs";
export default function EditProfile() {
  const { profileFormik, isLoading } = useProfileUpdate();
  const route = useNavigate();
  const constants = useSelector(selectConstants);

  return (
    <Layout>
      <Box
        pt={{ base: "3rem", md: "84px" }}
        pe={{ base: "1rem", md: "59px" }}
        pb={{ base: "140px", md: "238px" }}
        ps={{ base: "1rem", md: "100px" }}
        color="font.dark"
      >
        <Link to={routeObj.profile}>
          <Button
            bg="none"
            columnGap={{ base: "0.4rem", md: "0.8rem" }}
            fontSize="14px"
            fontWeight="600"
            color="font.dark"
            _hover={{}}
            mb="2rem"
          >
            <Icon fontSize="18px" as={BsArrowLeft} />
            Back
          </Button>
        </Link>
        <Heading fontSize={{ base: "1.2rem", md: "32px" }} fontWeight="600">
          Edit Profile
        </Heading>

        <Box pt={{ base: "2.5rem", md: "72px" }}>
          <form onSubmit={profileFormik.handleSubmit}>
            <FormControl
              py={{ base: "0.9rem", md: "25px" }}
              px={{ base: "1rem", md: "34px" }}
              w="100%"
              bg="bg.subMain"
            >
              <FormLabel
                color="#999FAA"
                fontSize={{ base: "0.8rem", md: "20px" }}
                fontWeight="400"
              >
                First name
              </FormLabel>
              <Input
                variant="unstyled"
                placeholder="First name"
                name="first_name"
                value={profileFormik.values.first_name}
                onChange={profileFormik.handleChange}
                onBlur={profileFormik.handleBlur}
                color="font.dark"
                fontSize={{ base: "0.9rem", md: "24px" }}
                fontWeight="500"
                isDisabled={isLoading}
              />
            </FormControl>
            <Text color="red" fontSize="12px">
              {profileFormik.errors.first_name &&
                profileFormik.errors.first_name}
            </Text>
            <FormControl
              py={{ base: "0.9rem", md: "25px" }}
              px={{ base: "1rem", md: "34px" }}
              w="100%"
              bg="bg.subMain"
              mt={{ base: "1.5rem", md: "32px" }}
            >
              <FormLabel
                color="#999FAA"
                fontSize={{ base: "0.8rem", md: "20px" }}
                fontWeight="400"
              >
                Last name
              </FormLabel>
              <Input
                variant="unstyled"
                placeholder="Last name"
                name="last_name"
                value={profileFormik.values.last_name}
                onChange={profileFormik.handleChange}
                onBlur={profileFormik.handleBlur}
                color="font.dark"
                fontSize={{ base: "0.9rem", md: "24px" }}
                fontWeight="500"
                isDisabled={isLoading}
              />
            </FormControl>
            <Text color="red" fontSize="12px">
              {profileFormik.errors.last_name && profileFormik.errors.last_name}
            </Text>

            <FormControl
              py={{ base: "0.9rem", md: "25px" }}
              px={{ base: "1rem", md: "34px" }}
              w="100%"
              bg="bg.subMain"
              mt={{ base: "1.5rem", md: "32px" }}
            >
              <FormLabel
                color="#999FAA"
                fontSize={{ base: "0.8rem", md: "20px" }}
                fontWeight="400"
              >
                Phone number
              </FormLabel>
              <Input
                variant="unstyled"
                placeholder="Tel"
                name="phone"
                value={profileFormik.values.phone}
                onChange={profileFormik.handleChange}
                onBlur={profileFormik.handleBlur}
                type="tel"
                color="font.dark"
                fontSize={{ base: "0.9rem", md: "24px" }}
                fontWeight="500"
                isDisabled={isLoading}
              />
            </FormControl>
            <Text color="red" fontSize="12px">
              {profileFormik.errors.phone && profileFormik.errors.phone}
            </Text>
            <FormControl
              py={{ base: "0.9rem", md: "25px" }}
              px={{ base: "1rem", md: "34px" }}
              w="100%"
              bg="bg.subMain"
              mt={{ base: "1.5rem", md: "32px" }}
            >
              <FormLabel
                color="#999FAA"
                fontSize={{ base: "0.8rem", md: "20px" }}
                fontWeight="400"
              >
                Country
              </FormLabel>
              <Select
                variant="unstyled"
                placeholder="country"
                name="country"
                value={profileFormik.values.country}
                onChange={profileFormik.handleChange}
                onBlur={profileFormik.handleBlur}
                color="font.dark"
                fontSize={{ base: "0.9rem", md: "24px" }}
                fontWeight="500"
                isDisabled={isLoading}
              >
                {constants?.countries
                  ? Object.entries(constants?.countries!).map((item) => (
                      <option key={item[0]} value={item[0]}>
                        {item[1]}
                      </option>
                    ))
                  : ""}
              </Select>
            </FormControl>
            <Text color="red" fontSize="12px">
              {profileFormik.errors.country && profileFormik.errors.country}
            </Text>
            <FormControl
              py={{ base: "0.9rem", md: "25px" }}
              px={{ base: "1rem", md: "34px" }}
              w="100%"
              bg="bg.subMain"
              mt={{ base: "1.5rem", md: "32px" }}
              display={profileFormik.values.country === "NG" ? "block" : "none"}
            >
              <FormLabel
                color="#999FAA"
                fontSize={{ base: "0.8rem", md: "20px" }}
                fontWeight="400"
              >
                State
              </FormLabel>
              <Select
                variant="unstyled"
                placeholder="State"
                name="state"
                value={profileFormik.values.state}
                onChange={profileFormik.handleChange}
                onBlur={profileFormik.handleBlur}
                color="font.dark"
                fontSize={{ base: "0.9rem", md: "24px" }}
                fontWeight="500"
                isDisabled={isLoading}
              >
                {constants?.states
                  ? Object.entries(constants.states!).map((item) => (
                      <option key={item[0]} value={item[0]}>
                        {item[1]}
                      </option>
                    ))
                  : ""}
              </Select>
            </FormControl>
            <FormControl
              py={{ base: "0.9rem", md: "25px" }}
              px={{ base: "1rem", md: "34px" }}
              w="100%"
              bg="bg.subMain"
              mt={{ base: "1.5rem", md: "32px" }}
            >
              <FormLabel
                color="#999FAA"
                fontSize={{ base: "0.8rem", md: "20px" }}
                fontWeight="400"
              >
                Add a bio
              </FormLabel>
              <Textarea
                variant="unstyled"
                placeholder=""
                name="bio"
                value={profileFormik.values.bio}
                onChange={profileFormik.handleChange}
                onBlur={profileFormik.handleBlur}
                color="font.dark"
                fontSize={{ base: "0.9rem", md: "24px" }}
                fontWeight="500"
                size="sm"
                isDisabled={isLoading}
              />
            </FormControl>
            <Text color="red" fontSize="12px">
              {profileFormik.errors.bio && profileFormik.errors.bio}
            </Text>
            <Box pt={{ base: "3rem", md: "72px" }}>
              <Heading
                fontSize={{ base: "1.2rem", md: "32px" }}
                fontWeight="600"
                color="font.dark"
              >
                Contact info
              </Heading>
              <FormControl
                py={{ base: "0.9rem", md: "25px" }}
                px={{ base: "1rem", md: "34px" }}
                w="100%"
                bg="bg.subMain"
                mt={{ base: "1.5rem", md: "32px" }}
              >
                <FormLabel
                  color="#999FAA"
                  fontSize={{ base: "0.8rem", md: "20px" }}
                  fontWeight="400"
                >
                  Github
                </FormLabel>
                <Input
                  variant="unstyled"
                  placeholder="type your github url"
                  name="github"
                  value={profileFormik.values.github}
                  onChange={profileFormik.handleChange}
                  onBlur={profileFormik.handleBlur}
                  type="url"
                  color="font.dark"
                  fontSize={{ base: "0.9rem", md: "24px" }}
                  fontWeight="500"
                  isDisabled={isLoading}
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {profileFormik.errors.github && profileFormik.errors.github}
              </Text>
              <FormControl
                py={{ base: "0.9rem", md: "25px" }}
                px={{ base: "1rem", md: "34px" }}
                w="100%"
                bg="bg.subMain"
                mt={{ base: "1.5rem", md: "32px" }}
              >
                <FormLabel
                  color="#999FAA"
                  fontSize={{ base: "0.8rem", md: "20px" }}
                  fontWeight="400"
                >
                  Linkedin
                </FormLabel>
                <Input
                  variant="unstyled"
                  placeholder="type your linkedin url"
                  name="linkedin"
                  value={profileFormik.values.linkedin}
                  onChange={profileFormik.handleChange}
                  onBlur={profileFormik.handleBlur}
                  type="url"
                  color="font.dark"
                  fontSize={{ base: "0.9rem", md: "24px" }}
                  fontWeight="500"
                  isDisabled={isLoading}
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {profileFormik.errors.linkedin && profileFormik.errors.linkedin}
              </Text>
              <FormControl
                py={{ base: "0.9rem", md: "25px" }}
                px={{ base: "1rem", md: "34px" }}
                w="100%"
                bg="bg.subMain"
                mt={{ base: "1.5rem", md: "32px" }}
              >
                <FormLabel
                  color="#999FAA"
                  fontSize={{ base: "0.8rem", md: "20px" }}
                  fontWeight="400"
                >
                  Telegram
                </FormLabel>
                <Input
                  variant="unstyled"
                  placeholder="type your telegram username"
                  name="telegram"
                  value={profileFormik.values.telegram}
                  onChange={profileFormik.handleChange}
                  onBlur={profileFormik.handleBlur}
                  type="url"
                  color="font.dark"
                  fontSize={{ base: "0.9rem", md: "24px" }}
                  fontWeight="500"
                  isDisabled={isLoading}
                />
              </FormControl>
              <Text color="red" fontSize="12px">
                {profileFormik.errors.telegram && profileFormik.errors.telegram}
              </Text>

              <Flex
                justifyContent="right"
                columnGap={{ base: "1rem", md: "68px" }}
                pt={{ base: "2rem", md: "45px" }}
              >
                <Button
                  onClick={() => {
                    route(routeObj.profile);
                  }}
                  borderColor="bg.main"
                  borderWidth="1px"
                  w={{ base: "104px", md: "206px" }}
                  h={{ base: "2.2rem", md: "56px" }}
                  fontSize={{ base: "1rem", md: "24px" }}
                  bg="none"
                  borderRadius={"none"}
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.6s"
                >
                  Cancel
                </Button>
                <Button
                  bg="bg.main"
                  type="submit"
                  w={{ base: "104px", md: "206px" }}
                  h={{ base: "2.2rem", md: "56px" }}
                  fontSize={{ base: "1rem", md: "24px" }}
                  color="white"
                  _hover={{ bg: "blue.700", color: "gray.200" }}
                  transition="0.6s"
                  borderRadius={"none"}
                  isDisabled={isLoading}
                  columnGap="20px"
                >
                  Save
                  <Spinner display={isLoading ? "inline-flex" : "none"} />
                </Button>
              </Flex>
            </Box>
          </form>
        </Box>
      </Box>
    </Layout>
  );
}
