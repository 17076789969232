import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  Icon,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import courseService from "../../app/services/courseService";
import ThreeCardSkeleton from "../../components/skeletons/ThreeCardSkeleton";
import { routeObj } from "../../constants/routes";
import {
  selectCompletedCourses,
  setCompletedCourses,
} from "../../features/courseSlice";
import Layout from "../../layouts/dashboard/Layout";
import { dateFormatter } from "../../utils";

export default function CertificationDetail() {
  const [courseData, setCourseData] = useState<ActiveBatches>();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const completedCourses = useSelector(selectCompletedCourses);
  const { id } = useParams();
  useEffect(() => {
    if (!completedCourses) {
      setIsLoading(true);
      courseService
        .getFinishedCourses()
        .then((res) => {
          if (res) {
            dispatch(setCompletedCourses(res.data));
            setIsLoading(false);
            // console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      const data = completedCourses.find((course) => course.id === id!);
      if (data) {
        setCourseData(data);
        // console.log(data);
        setIsLoading(false);
      }
    }
  }, [completedCourses]);
  return (
    <Layout>
      <Box
        px={{ base: "1rem", md: "62px" }}
        pt={{ base: "3rem", md: "64px" }}
        pb="127px"
      >
        {isLoading ? (
          <ThreeCardSkeleton />
        ) : (
          <Center
            bg="bg.subMain"
            pt={{ base: "3rem", md: "137.56px" }}
            pb="56px"
            ps={{ base: "1.5rem", md: "59px" }}
            pe={{ base: "1.5rem", md: "105px" }}
          >
            <Box w="100%">
              <Heading
                color="font.main"
                fontSize={{ base: "1.5rem", md: "40px" }}
                textAlign="center"
              >
                Certification details
              </Heading>
              <Box
                pt={{ base: "2.3rem", md: "66.41px" }}
                w="100%"
                color="font.dark"
              >
                <Flex
                  fontWeight="400"
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text>Course name</Text>
                  <Text>{courseData?.name}</Text>
                </Flex>
                <Flex
                  pt={{ base: "1.2rem", md: "32px" }}
                  fontWeight="400"
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text>Instructor:</Text>
                  <Text>{`${courseData?.first_name} ${courseData?.last_name}`}</Text>
                </Flex>
                <Flex
                  pt={{ base: "1.2rem", md: "32px" }}
                  fontWeight="400"
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text>Start date:</Text>
                  <Text> {dateFormatter(courseData?.start_date!)}</Text>
                </Flex>

                <Flex
                  pt={{ base: "1.2rem", md: "32px" }}
                  fontWeight="400"
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text>End date</Text>
                  <Text>{dateFormatter(courseData?.end_date!)}</Text>
                </Flex>

                <Flex
                  pt={{ base: "1.2rem", md: "32px" }}
                  justifyContent="space-between"
                  pb={{ base: "1rem", md: "2rem" }}
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                  borderBottomColor="#999FAA"
                  borderBottomWidth={"1px"}
                  fontWeight="400"
                >
                  <Text fontWeight="400">Status</Text>
                  <Text color="#2CA900">Completed</Text>
                </Flex>

                <Flex
                  pt={{ base: "1.2rem", md: "32px" }}
                  justifyContent="space-between"
                  fontSize={{ base: "1rem", md: "1.5rem" }}
                >
                  <Text fontWeight="400">Action</Text>
                  <Text fontWeight="500">
                    {" "}
                    <Link to={`${routeObj.certificationView}${courseData?.id}`}>
                      {" "}
                      <Button
                        bg="bg.main"
                        w="130.77px"
                        h={{ base: "1.9rem", md: "39px" }}
                        color="white"
                        fontSize={{ base: "0.8rem", md: "20px" }}
                        _hover={{ bg: "blue.700", color: "gray.200" }}
                        transition="0.6s"
                        borderRadius={"none"}
                      >
                        View
                      </Button>
                    </Link>
                  </Text>
                </Flex>
              </Box>
            </Box>
          </Center>
        )}
      </Box>
    </Layout>
  );
}
