import { Box, Heading, Button, Flex, Image } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import courseService from "../../app/services/courseService";
import { routeObj } from "../../constants/routes";
import { selectCourses } from "../../features/courseSlice";

import Layout from "../../layouts/dashboard/Layout";

export default function CourseWelcome() {
  const navigate = useNavigate();

  const [course, setCourse] = useState<Course>();
  const { courseId, batchId } = useParams();
  useEffect(() => {
    courseService
      .getCourseById(courseId!)
      .then((res) => {
        // console.log(res.data);
        setCourse(res.data.course);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Layout>
      <Box
        pt={{ base: "3rem", md: "104px" }}
        pb={{ base: "10px", md: "104px" }}
        px={{ base: "1rem", md: "68px" }}
      >
        <Heading
          color="font.main"
          textAlign="center"
          fontSize={{ base: "18px", md: "40px" }}
          fontWeight="500"
        >
          Welcome to this course
        </Heading>

        <Box pt={{ base: "1rem", md: "32px" }}>
          {/* <video
            id="my-video"
            className="video-js"
            controls
            title="Codelandcs"
            preload="auto"
            poster="/assets/images/blockchain1.png"
            data-setup="{}"
          >
            <source src="/assets/images/howto.mp4" type="video/mp4" />

            <p className="vjs-no-js">
              To view this video please enable JavaScript, and consider
              upgrading to a web browser that
              <a
                href="https://videojs.com/html5-video-support/"
                target="_blank"
                rel="noreferrer"
              >
                supports HTML5 video
              </a>
            </p>
          </video> */}
          {!course?.welcomeVideo && (
            <Image
              w={"100%"}
              height={{ base: "300px", md: "500px" }}
              objectFit={"cover"}
              src={course?.image}
            />
          )}
          {course?.welcomeVideo && (
            <iframe
              src={`${course?.welcomeVideo}?rel=0`}
              width="100%"
              height="500"
              title="Intro video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          )}
        </Box>
        <Flex
          pt={{ base: "1rem", md: "32px" }}
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          rowGap="1rem"
        >
          <Box>
            {" "}
            <Button
              bg="bg.main"
              w={{ base: "100%", md: "177px" }}
              h="47px"
              color="white"
              fontSize={{ base: "0.8rem", md: "16px" }}
              _hover={{ bg: "blue.700", color: "gray.200" }}
              transition="0.6s"
              borderRadius={"none"}
              onClick={() => {
                navigate(`${routeObj.courseLectures}${batchId}`);
              }}
            >
              CONTINUE
            </Button>
          </Box>

          <Button
            borderColor="#DAE4F3"
            color="font.main"
            onClick={() => {
              navigate(`/dashboard/courses`);
            }}
            borderWidth="1px"
            w={{ base: "100%", md: "130px" }}
            h="47px"
            fontSize={{ base: "0.8rem", md: "16px" }}
            bg="none"
            borderRadius={"none"}
            _hover={{ bg: "blue.700", color: "gray.200" }}
            transition="0.6s"
          >
            SKIP
          </Button>
        </Flex>
      </Box>
    </Layout>
  );
}
