import { Box } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCourses, setCourses } from "../features/courseSlice";
import CourseDetailLayout from "../components/courses/CourseDetailLayout";
import courseService from "../app/services/courseService";

export default function CourseDetail() {
  const { code } = useParams();
  const courses = useSelector(selectCourses);
  const [course, setCourse] = useState<Course>();
  const [batches, setBatches] = useState<Batches[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const dispatch = useDispatch();
  useEffect(() => {
    if (courses?.length) {
      // console.log(parseInt(code!));
      const i = courses.filter((item) => item.course.id === code!);
      setCourse(i[0].course);
      courseService
        .getBatchByCourse(i[0].course.id)
        .then((res) => {
          // console.log(res);
          setBatches(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      courseService
        .getCourses()
        .then((res) => {
          dispatch(setCourses({ courses: res.data }));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [courses?.length]);
  return (
    <Box>
      <Navbar />
      <CourseDetailLayout
        batches={batches!}
        course={course!}
        isLoading={isLoading}
      />
      <Footer />
    </Box>
  );
}
