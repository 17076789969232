import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../app/store";

const slice = createSlice({
  name: "courses",
  initialState: {} as {
    courses: CourseType[] | null;
    activeCourses: ActiveBatches[] | null;
    completedCourses: ActiveBatches[] | null;
  },
  reducers: {
    setCourses: (
      state,
      { payload: { courses } }: PayloadAction<{ courses: CourseType[] }>
    ) => {
      state.courses = courses;
    },
    setActiveCourses: (state, { payload }: PayloadAction<ActiveBatches[]>) => {
      state.activeCourses = payload;
    },
    setCompletedCourses: (state, { payload }: PayloadAction<ActiveBatches[]>) => {
      state.completedCourses = payload;
    },
    reset: (state) => {
      state.activeCourses = null;
      state.courses = null;
      state.completedCourses = null;
    },
  },
  extraReducers: (builder) => {},
});

export const { setCourses, setActiveCourses, setCompletedCourses } =
  slice.actions;

export default slice.reducer;

export const selectCourses = (state: RootState) => state.courses.courses;
export const selectCompletedCourses = (state: RootState) =>
  state.courses.completedCourses;
export const selectActiveCourses = (state: RootState) =>
  state.courses.activeCourses;
