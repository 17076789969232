import { Box, TabList, TabPanels, TabPanel, Tab, Tabs } from "@chakra-ui/react";
import courseService from "../../app/services/courseService";
import Layout from "../../layouts/dashboard/Layout";
import { useEffect, useState } from "react";
import { useCustomToast } from "../../utils/toast";
import BillingTable from "../../components/billing/BillingTable";
import TransactionTable from "../../components/billing/TransactionTable";

export default function Billing() {
  const [billing, setBilling] = useState<BillingType[]>();
  const [transactions, setTransactions] = useState<TransactionType[]>();
  const [renewLoading, setRenewLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { notifyError } = useCustomToast();
  const getBillings = async () => {
    setIsLoading(true);
    courseService
      .getCurrentCourses()
      .then((res) => {
        // console.log(res.data);
        setBilling(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const getTransactions = async () => {
    setIsLoading(true);
    courseService
      .getTransactionHistory()
      .then((res) => {
        // console.log(res.data);
        setTransactions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getBillings();
    getTransactions();
    return () => {
      setBilling([]);
      setTransactions([]);
    };
  }, [renewLoading]);

  return (
    <Layout>
      {/* <Text color="#FA0000" fontSize="14px" fontWeight="400">
          You have 1 active auto-renewal services Enable auto-renewal
        </Text> */}
      <Box
        pt={{ base: "1rem", md: "5rem" }}
        pe={{ base: "1rem", md: "30px" }}
        ps={{ base: "1rem", md: "70px" }}
      >
        <Tabs variant={"unstyled"}>
          <TabList color="#939AA6" columnGap={{ base: "1.2rem", md: "42px" }}>
            <Tab
              padding={0}
              fontSize={{ base: "0.9rem", md: "20px" }}
              fontWeight="600"
              _selected={{
                color: "font.main",
                borderBottomColor: "#FDE82A",
                borderBottomWidth: "3px",
              }}
            >
              My Subscriptions{" "}
            </Tab>
            <Tab
              padding={0}
              fontSize={{ base: "0.9rem", md: "20px" }}
              fontWeight="600"
              _selected={{
                color: "font.main",
                borderBottomColor: "#FDE82A",
                borderBottomWidth: "3px",
              }}
            >
              Billing History
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel px="0px !important">
              <BillingTable
                data={billing!}
                handleFetch={getBillings}
                loading={isLoading}
              />
            </TabPanel>
            <TabPanel px="0px !important">
              <TransactionTable data={transactions!} loading={isLoading} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Layout>
  );
}
