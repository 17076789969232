import axios, { AxiosInstance, AxiosResponse } from "axios";
import { useDispatch } from "react-redux";
import { logout } from "../../features/authSlice";
import { useCustomToast } from "../../utils/toast";
import { store } from "../store";

const instance: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  transformRequest: [
    (data) => {
      return JSON.stringify(data);
    },
  ],
});
const listener = () => {
  let userInfo: UserInfo;
  userInfo = store.getState().auth;
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  instance.defaults.headers.common["Access-Control-Allow-Methods"] =
    "GET,PUT,POST,DELETE,PATCH,OPTIONS";
  instance.defaults.headers.common["Authorization"] = userInfo
    ? `Bearer ${userInfo.token}`
    : undefined;
};
store.subscribe(listener);
// Add a request interceptor

// console.log(process.env.API_BASE_URL);
const responseBody = (response: AxiosResponse) => {
  // console.log(response);
  if (response.data === "unauthorized access") {
    store.dispatch(logout());
    window.location.href = "/auth/login?sessionExpired=true";
  }
  return response.data;
};
const errBody = (err: any) => {
  if (err.response.data === "unauthorized access") {
    // delete data in store
    console.log(err);
    store.dispatch(logout());
    window.location.href =
      "/auth/login?sessionExpired=true&redirect=" + window.location.pathname;
  }
  throw err;
};

const requests = {
  get: (url: string, body?: object) =>
    instance.get(url, body).then(responseBody, errBody),

  post: (url: string, body: object) =>
    instance.post(url, body).then(responseBody, errBody),

  put: (url: string, body: object) =>
    instance.put(url, body).then(responseBody, errBody),

  patch: (url: string, body?: object) =>
    instance.patch(url, body).then(responseBody, errBody),
};

export default requests;
