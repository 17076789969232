import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Spinner,
  Center,
  Text,
  Flex,
  Box,
  Card,
  Divider,
  Image,
} from "@chakra-ui/react";
import { dateFormatter, momentsAgo, numberFormatter } from "../../utils";
import EmptyState from "../skeletons/EmptyState";
import { textSpanIntersectsWithPosition } from "typescript";

type Props = {
  loading: boolean;
  data: TransactionType[];
};
export default function TransactionTable(props: Props) {
  return (
    <>
      <Box pt="3rem" pb="200px" display={{ base: "none", md: "block" }}>
        <TableContainer
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="unstyled">
            <Thead
              bg="bg.main"
              h="107px"
              color="white"
              fontSize={"16px"}
              fontWeight="600"
              ps="61px"
            >
              <Tr color="white">
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Type
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Reference
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Amount
                </Th>

                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Description
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Status
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Date
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize="14px" fontWeight="400" color="font.dark" pt="7px">
              {props.data?.length ? (
                props.data?.map((tnx) => (
                  <Tr py="10px" key={tnx.id}>
                    <Td>{tnx.tx_type}</Td>
                    <Td>{tnx.reference?.toUpperCase()}</Td>
                    <Td>₦ {numberFormatter(tnx.amount)}</Td>
                    <Td title={tnx.description}>
                      {tnx.description.substring(0, 30)}...
                    </Td>
                    <Td>
                      <Text
                        color={
                          tnx.status === "APPROVED" ? "#2CA900" : "#FA0000"
                        }
                      >
                        {tnx.status}
                      </Text>
                    </Td>
                    <Td>{dateFormatter(tnx.created_at)}</Td>
                  </Tr>
                ))
              ) : props.loading ? (
                <Tr>
                  <Td colSpan={6}>
                    <Center w="100%">
                      <Spinner />
                    </Center>
                  </Td>
                </Tr>
              ) : (
                <Tr pt="2rem">
                  <Td colSpan={6}>
                    <EmptyState message="Nothing to see for now" />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      {/* Mobile view */}
      <Box pb="200px" px={"1rem"} display={{ base: "block", md: "none" }}>
        <Box>
          <Box pt="1.8rem">
            {props.data?.length ? (
              props.data?.map((tnx) => (
                <Card variant="elevated" key={tnx.id}>
                  <Flex
                    px="1rem"
                    py="1rem"
                    columnGap={"1rem"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Flex columnGap={"5px"}>
                        <Center
                          boxSize="30px"
                          borderWidth={"1px"}
                          borderColor="#000000"
                          borderRadius={"full"}
                        >
                          <Image
                            boxSize="10px"
                            src="/assets/images/product-design.png"
                          />
                        </Center>
                        <Text fontSize={"0.9rem"} fontWeight="600">
                          Description:
                        </Text>
                      </Flex>
                      <Flex pt="8px" columnGap={"12px"} ps="1rem">
                        <Divider
                          borderColor={"#2CA900"}
                          borderWidth="2px"
                          h="55px"
                          orientation="vertical"
                        />
                        <Box fontSize="12px" fontWeight="400">
                          <Flex columnGap={"5px"}>
                            <Text>Price:</Text>
                            <Text fontSize="14px" fontWeight="700">
                              ₦{numberFormatter(tnx.amount)}
                            </Text>
                          </Flex>
                          <Flex columnGap={"5px"}>
                            <Text>Reference:</Text>
                            <Text fontWeight={"700"}>{tnx.reference}</Text>
                          </Flex>
                          <Flex columnGap={"5px"}>
                            <Text> Date:</Text>
                            <Text>{dateFormatter(tnx.created_at)}</Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection={"column"}
                      rowGap="12px"
                      fontWeight="400"
                      textAlign={"right"}
                    >
                      <Text fontSize={"0.8rem"} title={tnx.description}>
                        {tnx.description.substring(0, 15)}...
                      </Text>
                      <Text
                        fontSize="12px"
                        color={tnx.status === "APPROVED" ? "#2CA900" : "red"}
                      >
                        {tnx.status}
                      </Text>
                    </Box>
                  </Flex>
                </Card>
              ))
            ) : props.loading ? (
              <Center w="100%">
                <Spinner />
              </Center>
            ) : (
              <EmptyState message="Nothing to see for now" />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
