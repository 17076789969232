import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Box,
  ModalOverlay,
  Center,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export default function SuccessView(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      size={{ base: "xs", md: "lg" }}
      onClose={props.onClose}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(10deg)"
      />
      <ModalContent>
        <ModalCloseButton color="font.main" />
        <ModalBody padding={0} bg="bg.subMain" color="font.main">
          <Box
            bg="white"
            w="100%"
            color="font.dark"
            px={{ base: "1.2rem", md: "2rem" }}
            textAlign={"center"}
            py="4rem"
          >
            <Center>
              <Heading fontSize={{ base: "1.5rem", md: "2rem" }}>
                Email verification successful
              </Heading>
            </Center>
            <Center mt={{ base: "1.5rem", md: "3rem" }}>
              <Image
                boxSize={{ base: "5rem", md: "8rem" }}
                src="/assets/images/checked.png"
              />
            </Center>

            <Center mt={{ base: "1.5rem", md: "3rem" }}>
              <Text fontSize={{ base: "0.8rem", md: "1rem" }}>
                {" "}
                You should soon receive an email allowing you to reset your
                password. Please make sure to check your spam and trash if you
                can't find the email.
              </Text>
            </Center>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
