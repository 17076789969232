import React, { ReactNode } from "react";
import {
  IconButton,
  Avatar,
  Box,
  chakra,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Image,
  Center,
  InputGroup,
  Input,
  InputLeftAddon,
  Circle,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { FiMenu, FiBell, FiLogOut } from "react-icons/fi";
import { BsFillBookmarkFill } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import { MdSmsFailed } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { GoVerified } from "react-icons/go";
import { BiSearchAlt2 } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import { IconType } from "react-icons";
import { ReactText } from "react";
import { routeObj } from "../../constants/routes";
import { customStyle } from "../../utils/style";
import {
  logout,
  selectCurrentUser,
  selectLayoutHasLoaded,
  setLayoutHasLoaded,
} from "../../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import NotificationModal from "../../components/modal/NotificationMoadal";
import { selectConstants, setConstants } from "../../features/constantSlice";
import activityServices from "../../app/services/activityServices";
import { reset } from "../../features/compliantSlice";
import InterestModal from "../../components/modal/InterestModal";
import {
  selectNotifications,
  setNotifications,
} from "../../features/notificationSlice";
import courseService from "../../app/services/courseService";
import { setCourses } from "../../features/courseSlice";

interface LinkItemProps {
  name: string;
  url: string;
  icon: IconType;
}

export default function Layout({ children }: { children: ReactNode }) {
  // refactor code

  const { isOpen, onOpen, onClose } = useDisclosure();
  const pathname = useLocation();
  const {
    isOpen: isOpenInterestModal,
    onOpen: onOpenInterestModal,
    onClose: onCloseInterestModal,
  } = useDisclosure();
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const constants = useSelector(selectConstants);
  const hasLoaded = useSelector(selectLayoutHasLoaded);
  const [isLoading, setIsLoading] = React.useState(!hasLoaded);
  React.useEffect(() => {
    // check if user has any interest

    if (!user?.interest) {
      onOpenInterestModal();
    } else {
      onCloseInterestModal();
    }
    setIsLoading(false);
    dispatch(setLayoutHasLoaded());
    courseService
      .getCoursesForUser()
      .then((res) => {
        if (res) {
          dispatch(setCourses({ courses: res.data }));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);
  React.useEffect(() => {
    if (!constants.countries) {
      activityServices.getConstants().then((res) => {
        if (res) {
          dispatch(setConstants(res.data));
        }
      });
    }
  }, [constants]);
  // Make page scroll back to the top on render
  React.useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0 });
    }, 3000);
  }, [pathname]);
  return (
    <>
      {isLoading ? (
        <Center pt={{ base: "40%", md: "25%" }}>
          <Spinner size={{ base: "md", md: "xl" }} />
        </Center>
      ) : (
        <Box minH="100vh" bg={"white"}>
          <InterestModal
            isOpen={isOpenInterestModal}
            onClose={onCloseInterestModal}
          />
          <SidebarContent
            onClose={() => onClose}
            display={{ base: "none", md: "block" }}
          />
          <Drawer
            autoFocus={false}
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            returnFocusOnClose={false}
            onOverlayClick={onClose}
            size="full"
          >
            <DrawerContent>
              <SidebarContent onClose={onClose} />
            </DrawerContent>
          </Drawer>
          {/* Nav */}
          <Nav onOpenDrawer={onOpen} />
          <Box
            ml={{ base: 0, md: "260px" }}
            pos="relative"
            minH="calc(100vh)"
            // overflowY={"scroll"}
            // maxH="200vh"
          >
            {/* add a loader before this sections is displayed */}

            {children}

            <Box
              pos="absolute"
              bottom={"0"}
              left="0"
              bg="bg.subMain"
              py="18px"
              px={{ base: "1rem", md: "67px" }}
              w="100%"
            >
              <Flex
                w="100%"
                justifyContent="space-between"
                color="font.main"
                fontSize={{ base: "12px", md: "16px" }}
                fontStyle="italic"
              >
                <Text fontWeight="600">Codelandcs</Text>
                <Text fontWeight="400"> @ 2023 all rights reserved</Text>
              </Flex>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const pathName = useLocation().pathname;
  const dispatch = useDispatch();
  const LinkItems: Array<LinkItemProps> = [
    { name: "Courses", icon: BsFillBookmarkFill, url: routeObj.courses },
    { name: "Profile", icon: FaUserCircle, url: routeObj.profile },
    { name: "Billing", icon: RiMoneyDollarCircleFill, url: routeObj.billing },
    { name: "Certification", icon: GoVerified, url: routeObj.certification },
    // { name: "Notification", icon: FiBell, url: routeObj.notifications },
    // { name: "Compliant", icon: MdSmsFailed, url: routeObj.compliant },
  ];
  const user = useSelector(selectCurrentUser);
  return (
    <Box
      transition="3s ease"
      bg={"bg.main"}
      w={{ base: "full", md: "260px" }}
      color="white"
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        pt={{ base: "4rem", md: "3rem" }}
        alignItems="center"
        fontSize={customStyle.fontSize.logoText}
        ps={{ base: "1.3rem", md: "2rem" }}
        justifyContent="space-between"
        pb={{ base: "2rem", md: "51.5px" }}
        pos="relative"
      >
        <Center display={{ base: "none", md: "flex" }}>
          <Link to={routeObj.home}>
            <Image width="70%" src="/logo/logo.png" />
          </Link>
        </Center>
        <HStack columnGap="12px" display={{ base: "flex", md: "none" }}>
          <Avatar boxSize={"60px"} src={user?.image ? user.image : ""} />
          <VStack lineHeight="1rem" alignItems="flex-start" color="white">
            <Text fontSize="12px" fontWeight="400">
              {`${user?.first_name} ${user?.last_name}`}
            </Text>
            <Text fontSize="12px" fontWeight="400">
              {user?.email}
            </Text>
          </VStack>
        </HStack>
        <CloseButton
          display={{ base: "flex", md: "none" }}
          color="white"
          pos="absolute"
          top="1rem"
          right="1rem"
          onClick={onClose}
        />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          key={link.name}
          bg={pathName.includes(link.url) ? "white" : "none"}
          color={pathName.includes(link.url) ? "font.main" : "white"}
          icon={link.icon}
          url={link.url}
        >
          {link.name}
        </NavItem>
      ))}
      <chakra.a
        href="#"
        onClick={() => {
          dispatch(reset());
          dispatch(logout());
          window.location.href = "/";
        }}
        style={{ textDecoration: "none" }}
        //   _focus={{ boxShadow: "none" }}
      >
        <Flex
          align="center"
          mt={{ base: "3rem", md: "4.5rem" }}
          role="group"
          cursor="pointer"
          h="3.5rem"
          ps="2rem"
          columnGap="14px"
          borderLeftRadius={{ base: "0", md: "24px" }}
          fontSize={customStyle.fontSize.sideItem}
          _hover={{
            bg: "blue.700",
            //  color: "font.main",
          }}
          transition="0.6s"
        >
          <Icon
            fontSize="24px"
            // _groupHover={{
            //   color: "font.main",
            // }}
            as={FiLogOut}
          />
          Signout
        </Flex>
      </chakra.a>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: ReactText;
  url: string;
}
const NavItem = ({ icon, children, url, ...rest }: NavItemProps) => {
  return (
    <Link
      to={url}
      style={{ textDecoration: "none" }}
      //   _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        mt={{ base: "0", md: "1rem" }}
        role="group"
        cursor="pointer"
        h="3.5rem"
        ps={{ base: "1.3rem", md: "2rem" }}
        columnGap={{ base: "10px", md: "14px" }}
        borderLeftRadius={{ base: "0", md: "24px" }}
        borderBottomColor={{ base: "white", md: "none" }}
        borderBottomWidth={{ base: "1px", md: "0" }}
        fontSize={customStyle.fontSize.sideItem}
        _hover={{
          bg: "blue.700",
          // color: "font.main",
        }}
        transition="0.3s"
        {...rest}
      >
        {icon && (
          <Icon
            fontSize={{ base: "1.2rem", md: "1.5rem" }}
            // _groupHover={{
            //   color: "font.main",
            // }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

// interface NavProps extends FlexProps {
//   onOpen: () => void;
// }
interface PropsNav extends FlexProps {
  onOpenDrawer: () => void;
}

const Nav = ({ onOpenDrawer, ...rest }: PropsNav) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useSelector(selectCurrentUser);
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  React.useEffect(() => {
    if (!notifications) {
      setIsLoading(true);
      activityServices
        .getNotifications(user?.id!)
        .then((res) => {
          // console.log(res);
          setIsLoading(false);
          dispatch(setNotifications(res.data.data));
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, [notifications]);
  return (
    <Flex
      ml={{ base: 0, md: "260px" }}
      // px={{ base: 4, md: 4 }}
      ps={{ base: "1rem", md: "69px" }}
      pe={{ base: "1rem", md: "74px" }}
      height={{ base: "90px", md: "123px" }}
      alignItems="center"
      justifyContent="space-between"
      bg={"white"}
      boxShadow={{ base: "none", md: "md" }}
      color="font.dark"
      {...rest}
    >
      <NotificationModal
        notifications={notifications}
        isLoading={isLoading}
        isOpen={isOpen}
        onClose={onClose}
      />
      <Flex
        fontWeight="700"
        color="font.main"
        display={{ base: "flex", md: "none" }}
        columnGap="8px"
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpenDrawer}
          variant="outline"
          aria-label="open menu"
          fontSize="1.2rem"
          border="none"
          color="font.main"
          icon={<FiMenu />}
        />
        <Center>
          <Link to={routeObj.home}>
            <Image width="40%" src="/logo/logo-dark.png" />
          </Link>
        </Center>
      </Flex>
      <Flex>
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          color="font.main"
          border="none"
          fontSize="1.2rem"
          icon={<FiBell />}
        />
        <IconButton
          display={{ base: "flex", md: "none" }}
          // onClick={onOpenDrawer}
          variant="outline"
          border="none"
          aria-label="open menu"
          color="font.main"
          fontSize="1.2rem"
          icon={<BiSearchAlt2 />}
        />
      </Flex>
      <InputGroup display={{ base: "none", md: "flex" }}>
        <InputLeftAddon
          h="55px"
          w="66px"
          bg="bg.subMain"
          borderColor="#999FAA"
          pointerEvents="none"
          borderLeftRadius={"32px"}
          justifyContent="center"
          children={
            <Icon fontSize="16px" color="font.dark" as={BiSearchAlt2} />
          }
        />
        <Input
          borderRadius="32px"
          borderColor="#999FAA"
          color="font.dark"
          _placeholder={{ color: "font.dark" }}
          type="text"
          h="55px"
          ps="6px"
          w="467px"
          placeholder="What do you want to learn today?"
        />
      </InputGroup>

      <HStack
        spacing={{ base: "0", md: "13px" }}
        display={{ base: "none", md: "flex" }}
      >
        <Button
          h="33px"
          w="30px"
          boxSize="30px"
          fontSize="15px"
          borderRadius="full"
          bg="#DAE4F3"
          color="font.main"
          variant="outline"
          onClick={onOpen}
          aria-label="open menu"
          position="relative"
        >
          <Icon as={FiBell} />
          <Box
            position={"absolute"}
            right={"32%"}
            zIndex={1}
            top={"20%"}
            boxSize="5px"
            borderRadius={"50%"}
            display={notifications ? "block" : "none"}
            bg="red.500"
          ></Box>
          <Spinner
            position={"absolute"}
            right={"32%"}
            zIndex={1}
            top={"20%"}
            boxSize="5px"
            borderRadius={"50%"}
            display={isLoading ? "block" : "none"}
            bg="gray"
          />
        </Button>
        <Flex alignItems={"center"}>
          <HStack columnGap="8px">
            <Avatar boxSize={"48px"} src={`${user?.image}`} />
            <VStack
              display={{ base: "none", md: "flex" }}
              alignItems="flex-start"
              w="89px"
            >
              <Text fontSize="12px" fontWeight="600">
                {`${user?.first_name}`}
              </Text>
            </VStack>
          </HStack>
        </Flex>
      </HStack>
    </Flex>
  );
};
