import {
  Box,
  Avatar,
  Flex,
  Center,
  Icon,
  IconButton,
  chakra,
  Heading,
  Text,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import Layout from "../../layouts/dashboard/Layout";
import { AiOutlineCamera } from "react-icons/ai";
import { MdLocationOn, MdModeEdit } from "react-icons/md";
import { FaGithub, FaLinkedin, FaTelegram } from "react-icons/fa";
import EditModal from "../../components/modal/EditModal";
import { useState } from "react";
import { routeObj } from "../../constants/routes";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/authSlice";
export default function Profile() {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const [title, setTitle] = useState("");
  const user = useSelector(selectCurrentUser);
  const handleTittle = (title: string): void => {
    setTitle(title);
    onOpen();
  };
  return (
    <Layout>
      <EditModal onClose={onClose} title={title} isOpen={isOpen} />
      <Box
        pt={{ base: "3rem", md: "84px" }}
        pe={{ base: "1rem", md: "59px" }}
        pb={{ base: "140px", md: "238px" }}
        ps={{ base: "1rem", md: "59px" }}
      >
        <Box w={"100%"}>
          <Box
            w={"100%"}
            h={{ base: "17vh", sm: "198px" }}
            bgImage={
              user?.cover_photo
                ? user.cover_photo
                : "/assets/images/cover-icon.png"
            }
            bgRepeat={"no-repeat"}
            bgPos="center"
            bgSize="cover"
            borderRadius={"none"}
            zIndex={-1}
          >
            <Box
              w="100%"
              zIndex={3}
              h={{ base: "17vh", md: "198px" }}
              pos="relative"
            >
              <IconButton
                pos="absolute"
                bottom={{ base: "0", md: "20px" }}
                right={{ base: "0", md: "29px" }}
                fontSize={{ base: "1.5rem", md: "40px" }}
                color="white"
                _hover={{}}
                onClick={() => handleTittle("cover")}
                bg="none"
                aria-label="edit cover photo"
                icon={<AiOutlineCamera />}
              />
            </Box>
          </Box>
          <Avatar
            // borderRadius="full"
            boxSize={{ base: "80px", sm: "200px" }}
            src={`${user?.image}`}
            border="solid"
            mt={{ base: "-40px", sm: "-80px" }}
            ml={{ base: "20px", md: "44px" }}
            borderColor={"white"}
            borderWidth={{ base: "3px", md: "6px" }}
            pos="relative"
            children={
              <>
                <IconButton
                  aria-label="edit profile photo"
                  icon={<MdModeEdit />}
                  padding={0}
                  _hover={{}}
                  boxShadow="md"
                  display={{ base: "none", md: "flex" }}
                  px="0 !important"
                  borderRadius="full"
                  onClick={() => handleTittle("profile")}
                  boxSize={{ base: "1rem", md: "49px" }}
                  color="font.dark"
                  fontSize={{ base: "5px", md: "20px" }}
                  bg="white"
                  pos="absolute"
                  bottom={{ base: "5px", md: "14px" }}
                  right={{ base: "0", md: "0.8rem" }}
                />
                <Box
                  boxShadow="md"
                  color="font.dark"
                  display={{ base: "flex", md: "none" }}
                  bg="white"
                  pos="absolute"
                  bottom={"8px"}
                  right={"8px"}
                  px="0 !important"
                  borderRadius="full"
                  boxSize={"1rem"}
                  justifyContent="center"
                  alignContent="center"
                  pt="3px"
                  cursor={"pointer"}
                  onClick={onOpen}
                >
                  <Icon
                    as={MdModeEdit}
                    color="font.dark"
                    bg="none"
                    fontSize={"10px"}
                  />
                </Box>
              </>
            }
          />
        </Box>
        <Flex justifyContent="space-between" pt={{ base: "1rem", md: "47px" }}>
          <Box color="font.dark">
            <Heading fontSize={{ base: "1rem", md: "32px" }} fontWeight="700">
              {`${user?.first_name} ${user?.last_name}`}
            </Heading>
            <Text
              fontSize={{ base: "0.7rem", md: "16px" }}
              fontWeight="400"
              mt={{ base: "0.5rem", md: "17.5px" }}
            >
              <Icon as={MdLocationOn} />{" "}
              {`${user?.country}, ${user?.state ? user?.state : ""}`}
            </Text>
          </Box>
          <Flex columnGap="10px" alignItems={"center"}>
            <Link to={routeObj.editProfile}>
              <IconButton
                aria-label="edit profile"
                icon={<MdModeEdit />}
                _hover={{}}
                boxShadow="md"
                borderRadius="full"
                boxSize={{ base: "2.3rem", md: "2.6rem" }}
                color="white"
                fontSize={{ base: "0.6rem", md: "20px" }}
                bg="bg.main"
              />
            </Link>
            <Text fontSize={{ base: "0.6rem", md: "0.8rem" }}>
              Edit Profile
            </Text>
          </Flex>
        </Flex>

        <Box
          pt={{ base: "2.3rem", md: "118px" }}
          color="font.dark"
          fontSize={{ base: "0.8rem", md: "20px" }}
        >
          <Flex
            justifyContent="space-between"
            columnGap={{ base: "2rem", md: "100px" }}
            pb={{ base: "1.5rem", md: "50px" }}
          >
            <Text
              fontWeight="600"
              flex={{ base: 0.7, md: 0.3 }}
              pt={{ base: "0.2rem", md: "23px" }}
            >
              Email address
            </Text>
            <Text
              flex={1}
              fontWeight="400"
              py={{ base: "0.5rem", md: "23px" }}
              ps={{ base: "0.5rem", md: "34px" }}
              pe={{ base: "0.5rem", md: "19px" }}
              w="73%"
              bg="bg.subMain"
            >
              {user?.email}
            </Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            columnGap={{ base: "2rem", md: "100px" }}
            pb={{ base: "1.5rem", md: "50px" }}
          >
            <Text
              fontWeight="600"
              flex={{ base: 0.9, md: 0.3 }}
              pt={{ base: "0.2rem", md: "23px" }}
            >
              Phone number
            </Text>
            <Text
              flex={1}
              fontWeight="400"
              py={{ base: "0.5rem", md: "23px" }}
              ps={{ base: "0.5rem", md: "34px" }}
              pe={{ base: "0.5rem", md: "19px" }}
              w="73%"
              bg="bg.subMain"
            >
              {`${user?.phone}`}
            </Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            columnGap={{ base: "2rem", md: "100px" }}
            pb={{ base: "1.5rem", md: "50px" }}
          >
            <Text
              fontWeight="600"
              flex={{ base: 0.7, md: 0.3 }}
              pt={{ base: "0.2rem", md: "23px" }}
            >
              Add a short Bio
            </Text>
            <Text
              flex={1}
              fontWeight="400"
              minH="10vh"
              py={{ base: "0.5rem", md: "23px" }}
              ps={{ base: "0.5rem", md: "34px" }}
              pe={{ base: "0.5rem", md: "19px" }}
              w="73%"
              bg="bg.subMain"
            >
              {user?.bio ? user.bio : ""}
            </Text>
          </Flex>

          <Flex
            columnGap={{ base: "1rem", md: "68px" }}
            pt={{ base: "2rem", md: "72px" }}
            rowGap="1rem"
            flexWrap="wrap"
          >
            <Center
              columnGap={{ base: "1rem", md: "81px" }}
              fontSize={{ base: "0.5rem", md: "20px" }}
              borderColor="gray.100"
              borderWidth="1px"
              px={{ base: "0.8rem", md: "17px" }}
              py={{ base: "0.3rem", md: "16.5px" }}
              fontWeight="400"
              flex={1}
            >
              <Center columnGap={{ base: "0.6rem", md: "16px" }}>
                <Icon
                  as={FaGithub}
                  color="#000000"
                  boxSize={{ base: "0.5rem", md: "24px" }}
                />
                {user?.github ? (
                  <chakra.a noOfLines={1} href={user.github} target="_blank">
                    {user.github}
                  </chakra.a>
                ) : (
                  "Github"
                )}
              </Center>
            </Center>

            <Center
              columnGap={{ base: "1rem", md: "81px" }}
              fontSize={{ base: "0.5rem", md: "20px" }}
              borderColor="gray.100"
              borderWidth="1px"
              px={{ base: "0.8rem", md: "17px" }}
              py={{ base: "0.3rem", md: "16.5px" }}
              fontWeight="400"
              flex={1}
            >
              <Center columnGap={{ base: "0.6rem", md: "16px" }}>
                <Icon
                  as={FaLinkedin}
                  color="#000000"
                  boxSize={{ base: "0.5rem", md: "24px" }}
                />
                {user?.linkedin ? (
                  <chakra.a noOfLines={1} href={user.linkedin} target="_blank">
                    {user.linkedin}
                  </chakra.a>
                ) : (
                  "Linkedin"
                )}
              </Center>
            </Center>

            <Center
              columnGap={{ base: "1rem", md: "81px" }}
              fontSize={{ base: "0.5rem", md: "20px" }}
              borderColor="gray.100"
              borderWidth="1px"
              px={{ base: "0.8rem", md: "17px" }}
              py={{ base: "0.3rem", md: "16.5px" }}
              fontWeight="400"
              flex={1}
            >
              <Center columnGap={{ base: "0.6rem", md: "16px" }}>
                <Icon
                  as={FaTelegram}
                  color="#000000"
                  boxSize={{ base: "0.5rem", md: "24px" }}
                />
                {user?.telegram ? (
                  <chakra.a noOfLines={1} href={user.telegram} target="_blank">
                    {user.telegram}
                  </chakra.a>
                ) : (
                  "Telegram"
                )}
              </Center>
            </Center>
          </Flex>
        </Box>
      </Box>
    </Layout>
  );
}
