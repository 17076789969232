import { Box, Flex, Button, Image, Input } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import activityServices from "../../app/services/activityServices";
import { selectCurrentUser } from "../../features/authSlice";
import { selectCompliant, setCompliants } from "../../features/compliantSlice";
import Layout from "../../layouts/dashboard/Layout";
import { useCustomToast } from "../../utils/toast";

export default function Compliant() {
  const [compliantInput, setCompliantInput] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const compliant = useSelector(selectCompliant);
  const { notifyError, notifySuccess } = useCustomToast();
  const handleOnChange = (e: any) => {
    // console.log(e.target.value);
    setCompliantInput(e.target.value);
  };
  const handleClick = () => {
    if (compliantInput?.length < 2) {
      notifyError("length must be above 2");
      return;
    }
    setIsLoading(true);
    activityServices
      .addComplaint({ message: compliantInput })
      .then((res) => {
        setCompliantInput("");
        dispatch(setCompliants({ compliant: res.data.message }));
        // console.log(res);
        setIsLoading(false);
        notifySuccess("Compliant sent!");
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        notifyError(err.response.data.message);
      });
  };
  useEffect(() => {
    if (!compliant?.length) {
      activityServices
        .getcompliants(user?.id!)
        .then((res) => {
          if (res.data) {
            dispatch(setCompliants({ compliant: res.data }));
            // console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [compliant]);
  return (
    <Layout>
      <Box
        pt="61px"
        minH="100vh"
        pe={{ base: "1rem", md: "106px" }}
        pb="400px"
        ps={{ base: "1rem", md: "72px" }}
        pos="relative"
      >
        {/* <Center>
          <Divider />
          <Badge
            borderRadius={"lg"}
            color="font.dark"
            fontSize={{ base: "8px", md: "10px" }}
            // py="5px"
            px="8px"
          >
            Today
          </Badge>
          <Divider />
        </Center> */}
        <Box pt="2rem">
          {compliant?.length &&
            compliant?.map((item) => (
              <>
                {item.type === "admin" ? (
                  <Flex
                    key={item.id}
                    w="100%"
                    justifyContent={"left"}
                    pt="0.5rem"
                  >
                    <Box
                      px="1rem"
                      py="1rem"
                      bg="#C4C4C4"
                      color="#212529"
                      fontSize={{ base: "", md: "12px" }}
                      maxW={{ base: "200px", md: "400px" }}
                      borderRadius="1rem"
                      borderBottomLeftRadius={"none"}
                    >
                      {item.message}
                    </Box>
                  </Flex>
                ) : (
                  <Flex
                    key={item.id}
                    w="100%"
                    justifyContent={"right"}
                    pt="0.5rem"
                  >
                    <Box
                      px="1.2rem"
                      py="1.2rem"
                      bg="bg.main"
                      color="white"
                      fontSize={{ base: "", md: "12px" }}
                      maxW={{ base: "200px", md: "400px" }}
                      borderRadius="1rem"
                      borderBottomRightRadius={"none"}
                    >
                      {item.message}
                    </Box>
                  </Flex>
                )}
              </>
            ))}
          {/* <Flex w="100%" justifyContent={"right"} pt="0.5rem">
            <Box
              px="1.2rem"
              py="1.2rem"
              bg="bg.main"
              color="white"
              fontSize={{ base: "", md: "12px" }}
              maxW={{ base: "200px", md: "400px" }}
              borderRadius="1rem"
              borderBottomRightRadius={"none"}
            >
              Hello Mr steph, Lorem is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum hasbeen the industry's standard
              dummy text eversince the 1500s, when an unknown printertook a
              galley of type and scrambled it to makea type specimen book.
            </Box>
          </Flex>
          <Flex w="100%" justifyContent={"right"} pt="0.5rem">
            <Box
              px="1rem"
              py="1rem"
              bg="bg.main"
              color="white"
              fontSize={{ base: "", md: "12px" }}
              maxW={{ base: "200px", md: "400px" }}
              borderRadius="1rem"
              borderBottomRightRadius={"none"}
            >
              Is it something feasible to do ?
            </Box>
          </Flex>
          <Flex w="100%" justifyContent={"right"} pt="0.5rem">
            <Center color="#407BFF" fontSize={"0.7rem"} columnGap="5px">
              Seen at 1:30 pm <Icon as={AiOutlineCheck} />
            </Center>
          </Flex> */}
          {/* 
          <Flex w="100%" justifyContent={"left"} pt="0.5rem">
            <Box
              px="1.2rem"
              py="1.2rem"
              bg="#C4C4C4"
              color="#212529"
              fontSize={{ base: "", md: "12px" }}
              maxW={{ base: "200px", md: "400px" }}
              borderRadius="1rem"
              borderBottomLeftRadius={"none"}
            >
              Hello Mr steph, Lorem is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum hasbeen the industry's standard
              dummy text eversince the 1500s, when an unknown printertook a
              galley of type and scrambled it to makea type specimen book.
            </Box>
          </Flex>
          <Flex w="100%" justifyContent={"left"} pt="0.5rem">
            <Box
              px="1rem"
              py="1rem"
              bg="#C4C4C4"
              color="#212529"
              fontSize={{ base: "", md: "12px" }}
              maxW={{ base: "200px", md: "400px" }}
              borderRadius="1rem"
              borderBottomLeftRadius={"none"}
            >
              Is it something feasible to do ?
            </Box>
          </Flex>
          <Flex w="100%" justifyContent={"left"} pt="0.5rem">
            <Center color="#666666" fontSize={"0.7rem"} columnGap="5px">
              Seen at 1:30 pm <Icon as={AiOutlineCheck} />
            </Center>
          </Flex> */}
        </Box>
        <Box
          w={{ base: "100%", md: "" }}
          bottom={{ base: "10px", md: "50px" }}
          py="10px"
          left={{ base: "0px", md: "260px" }}
          pos="fixed"
          bg="white"
          zIndex={100}
          px={{ base: "1rem", md: "" }}
        >
          <Flex
            borderColor="#BDC2DB"
            borderWidth={"1px"}
            py={{ base: "0.5rem", md: "25.34px" }}
            px={{ base: "0.3rem", md: "39px" }}
            // margin="auto"
            ms={{ base: "", md: "30px" }}
            w={{ base: "100%", md: "74%" }}
            columnGap={{ base: "0.1rem", md: "42px" }}
          >
            {/* <Button bg="none" _hover={{ bg: "none" }}>
              <Image src="/assets/images/attachment-dark.svg" />
            </Button> */}

            <Input
              variant="filled"
              bg="#EBEBEB"
              isDisabled={isLoading}
              name="compliant"
              value={compliantInput}
              onChange={handleOnChange}
              _hover={{ bg: "#EBEBEB" }}
              borderRadius="18px"
              placeholder="Type  complaint  here..."
              color="font.dark"
              _placeholder={{ color: "#C4C4C4" }}
            />
            <Button
              isDisabled={isLoading}
              onClick={handleClick}
              bg="none"
              _hover={{ bg: "none" }}
            >
              <Image src="/assets/images/icon_send.svg" />
            </Button>
          </Flex>
        </Box>
      </Box>
    </Layout>
  );
}
