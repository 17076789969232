import {
  Box,
  Center,
  FormControl,
  Spinner,
  Text,
  Heading,
  Input,
  Button,
  Image,
} from "@chakra-ui/react";
import { useFormik } from "formik";

import * as Yup from "yup";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import authService from "../../app/services/authService";
import { useCustomToast } from "../../utils/toast";
import { routeObj } from "../../constants/routes";
export default function ResetPassword() {
  const inputFontSize = { base: "16px", md: "1.2rem" };
  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { notifyError, notifySuccess } = useCustomToast();
  const resetPasswordFormik = useFormik({
    initialValues: {
      password: "",
      password2: "",
    },
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Must be 8 characters or above")
        .required("Required"),
      password2: Yup.string()
        .oneOf([Yup.ref("password"), "null"], "Passwords must match")
        .required("required"),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      authService
        .resetPassword(values, code!)
        .then((res) => {
          // console.log(res);
          notifySuccess(res.message);
          setIsSuccess(true);
        })
        .catch((err) => {
          console.log(err);
          notifyError(err.response.data.message);
        });
    },
  });
  return (
    <Box bg={isSuccess ? "none" : "bg.subMain"}>
      <Box pt="2.4rem" ps="2.5rem" bg="inherit">
        {" "}
        <Link to={routeObj.home}>
          <Image
            // w={"100%"}
            w="220px"
            src="/logo/logo-dark.png"
            display={{ base: "none", md: "inline-flex" }}
          />
        </Link>
      </Box>
      <Box
        px={{ base: "7%", md: "20%" }}
        pt={{ base: "4rem", md: "5rem" }}
        color="font.dark"
        display={isSuccess ? "none" : "block"}
        h="100vh"
      >
        <Box textAlign={"center"}>
          <Heading fontSize={{ base: "1.5rem", md: "2rem" }} fontWeight="700">
            Reset password
          </Heading>
          <Text mt="1.2rem" fontSize={{ base: "14px", md: "1.2rem" }}>
            To reset your password, enter a new password in the box below
          </Text>
        </Box>
        <Box pt={{ base: "2rem", md: "3rem" }} pb="20px">
          <form onSubmit={resetPasswordFormik.handleSubmit}>
            <FormControl
              // mt="25px"
              borderColor={"font.dark"}
              borderWidth={{ base: "1px", md: "1px" }}
              px={{ base: "10px", md: "" }}
              // borderBottomStyle={"solid"}
              py={{ base: "10px", md: "0.8rem" }}
              w={{ base: "100%", md: "450px" }}
              mx="auto"
              // h="70px"
            >
              <Input
                fontSize={inputFontSize}
                fontWeight={"400"}
                name="password"
                type="password"
                value={resetPasswordFormik.values.password}
                onChange={resetPasswordFormik.handleChange}
                onBlur={resetPasswordFormik.handleBlur}
                variant={"unstyled"}
                placeholder="New password"
                borderColor={"font.main"}
                color="font.dark"
                _placeholder={{ color: "font.dark" }}
                px="1rem"
                isDisabled={isLoading}
                autoComplete="new-password"
              />
            </FormControl>
            <Box mx="auto" w={{ base: "100%", md: "450px" }}>
              {" "}
              <Text color="red" fontSize="12px">
                {resetPasswordFormik.errors.password &&
                  resetPasswordFormik.errors.password}
              </Text>
            </Box>
            <FormControl
              mt="1.4rem"
              borderColor={"font.dark"}
              borderWidth={{ base: "1px", md: "1px" }}
              px={{ base: "10px", md: "" }}
              // borderBottomStyle={"solid"}
              py={{ base: "10px", md: "0.8rem" }}
              w={{ base: "100%", md: "450px" }}
              mx="auto"
              // h="70px"
            >
              <Input
                fontSize={inputFontSize}
                fontWeight={"400"}
                variant={"unstyled"}
                name="password2"
                type="password"
                isDisabled={isLoading}
                onChange={resetPasswordFormik.handleChange}
                onBlur={resetPasswordFormik.handleBlur}
                value={resetPasswordFormik.values.password2}
                placeholder="Re-type new password"
                borderColor={"font.main"}
                color="font.dark"
                _placeholder={{ color: "font.dark" }}
                px="1rem"
              />
            </FormControl>
            <Box mx="auto" w={{ base: "100%", md: "450px" }}>
              {" "}
              <Text color="red" fontSize="12px">
                {resetPasswordFormik.errors.password2 &&
                  resetPasswordFormik.errors.password2}
              </Text>
            </Box>
            <Center mt={{ base: "1.2rem", md: "1.9rem" }}>
              <Button
                type="submit"
                display="flex"
                w={{ base: "100%", md: "450px" }}
                alignSelf={"center"}
                _hover={{ bg: "blue.700", color: "gray.200" }}
                transition="0.6s"
                borderRadius={"sm"}
                bg="bg.main"
                h="4rem"
                color="#ffffff"
                justifyContent={"center"}
                fontSize={{ base: "0.8rem", md: "1.1rem" }}
                isDisabled={isLoading}
              >
                <Center columnGap="20px">
                  SUBMIT{" "}
                  <Spinner display={isLoading ? "inline-flex" : "none"} />
                </Center>
              </Button>
            </Center>
          </form>
        </Box>
      </Box>
      <Box
        bg="white"
        w="100%"
        color="font.dark"
        px={{ base: "1.2rem", md: "2rem" }}
        textAlign={"center"}
        py="4rem"
        display={isSuccess ? "block" : "none"}
      >
        <Center>
          <Heading fontSize={{ base: "1.5rem", md: "2rem" }}>
            Password updated successfully
          </Heading>
        </Center>
        <Center mt={{ base: "1.5rem", md: "3rem" }}>
          <Image
            boxSize={{ base: "5rem", md: "8rem" }}
            src="/assets/images/checked.png"
          />
        </Center>

        <Center mt={{ base: "1.5rem", md: "3rem" }}>
          <Text fontSize={{ base: "0.8rem", md: "1rem" }}>
            {" "}
            Click{" "}
            <Link to={routeObj.login} style={{ textDecoration: "underline" }}>
              here
            </Link>{" "}
            to login
          </Text>
        </Center>
      </Box>
    </Box>
  );
}
