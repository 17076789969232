import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Box,
  ModalOverlay,
  Heading,
  Text,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { routeObj } from "../../constants/routes";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  id: string;
  courseId: string;
};
export default function SessionModal(props: Props) {
  return (
    <Modal
      isOpen={props.isOpen}
      size={{ base: "xs", md: "lg" }}
      onClose={props.onClose}
    >
      <ModalOverlay
        bg="blackAlpha.300"
        backdropFilter="blur(10px) hue-rotate(10deg)"
      />
      <ModalContent>
        <ModalCloseButton color="font.main" />
        <ModalBody bg="white" color="font.main">
          <Box
            pt={{ base: "2rem", md: "100px" }}
            pb={{ base: "2rem", md: "132px" }}
            textAlign="center"
            px={{ base: "0.5rem", md: "82px" }}
          >
            <Heading
              fontSize={{ base: "1.2rem", md: "32px" }}
              fontWeight="700"
              color="font.main"
            >
              Select course details
            </Heading>
            <Text
              mt={{ base: "0.7rem", md: "24px" }}
              fontSize={{ base: "0.7rem", md: "16px" }}
              fontWeight="400"
              lineHeight={{ base: "1rem", md: "25px" }}
              color="font.dark"
            >
              This course launches on 14th May 2024, click the button to join
              the waiting list{" "}
            </Text>

            <Center mt={{ base: "2rem", md: "72px" }}>
              <Link to={`${routeObj.checkout}session/${props.id}`}>
                {" "}
                <Button
                  borderColor={"bg.main"}
                  bg="none"
                  borderWidth={"1px"}
                  _hover={{}}
                  w={{ base: "200px", md: "388px" }}
                  h={{ base: "3rem", md: "70px" }}
                  fontSize={{ base: "0.9rem", md: "20px" }}
                  fontWeight="500"
                >
                  Join a session
                </Button>
              </Link>
            </Center>
            <Center mt={{ base: "1rem", md: "32px" }}>
              <Link to={`${routeObj.privateSession}${props.courseId}`}>
                <Button
                  borderColor={"bg.main"}
                  bg="none"
                  borderWidth={"1px"}
                  _hover={{}}
                  w={{ base: "200px", md: "388px" }}
                  h={{ base: "3rem", md: "70px" }}
                  fontSize={{ base: "0.9rem", md: "20px" }}
                  fontWeight="500"
                >
                  Join as a private student
                </Button>
              </Link>
            </Center>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
