import request from "./httpService";

const authService = {
  register(body: object) {
    return request.post("/auth/sign-up", body);
  },
  login(body: object) {
    return request.post("/auth/sign-in", body);
  },
  forgotPassword(body: object) {
    return request.post("/auth/forgot-password", body);
  },
  resetPassword(body: object, token: string) {
    return request.put(`/auth/reset-password/${token}`, body);
  },
  getVerifyEmailToken() {
    return request.get("/auth/verify-email");
  },
  verifyEmail(body: object) {
    return request.patch("/auth/verify-email", body);
  },
  logout() {
    return request.get("/auth/logout");
  },
};

export default authService;
