import React, { useEffect } from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import {
  authRoute,
  protectedRoutes,
  unProtectedRoutes,
} from "./constants/routes";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import theme from "./utils/style";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { NoAccessRoute, ProtectedRoute } from "./Private";

export const App = () => {
  return (
    <GoogleOAuthProvider clientId={`${process.env.REACT_APP_CLIENT_ID}`}>
      {" "}
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <ColorModeScript initialColorMode={theme.config.initialColorMode} />
          <Router>
            <Routes>
              {unProtectedRoutes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.component />}
                ></Route>
              ))}
              <Route element={<NoAccessRoute />}>
                {authRoute.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  ></Route>
                ))}
              </Route>
              <Route
                path={"/dashboard"}
                element={<Navigate to={"/dashboard/courses"} />}
              />
              <Route element={<ProtectedRoute />}>
                {protectedRoutes.map((route) => (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={<route.component />}
                  ></Route>
                ))}
              </Route>
            </Routes>
          </Router>
        </ChakraProvider>
      </Provider>
    </GoogleOAuthProvider>
  );
};
