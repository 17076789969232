import { Box, Heading, Text, chakra } from "@chakra-ui/react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import { customStyle } from "../../utils/style";

export default function Privacy() {
  return (
    <Box>
      <Navbar />
      <Box px={{ base: "1rem", md: "2rem" }} pt={{ base: "3rem", md: "100px" }}>
        <Heading
          color="font.main"
          fontSize={customStyle.fontSize.sectionHeader}
        >
          Privacy Policy
        </Heading>

        <Box
          pb="5rem"
          pt={{ base: "2rem", md: "2.5rem" }}
          lineHeight={{ base: "1.7rem", md: "3rem" }}
          color="font.dark"
          fontSize={{ base: "0.7rem", md: "1.5rem" }}
        >
          <Box
            pb="5rem"
            pt={{ base: "2rem", md: "2.5rem" }}
            lineHeight={{ base: "1.7rem", md: "3rem" }}
            color="font.dark"
            fontSize={{ base: "0.7rem", md: "1.5rem" }}
          >
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              At CodelandCS, we are committed to protecting your privacy. This
              Privacy Policy explains how we collect, use, and disclose your
              personal information when you use our online learning platform. By
              accessing and using our platform, you consent to the practices
              described in this Privacy Policy.
            </Text>
            <Heading fontSize={"1.7rem"}>Information We Collect</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              Personal Information: We collect personal information, such as
              your name, email address, and payment details when you register an
              account or enroll in a course.
            </Text>
            <Heading fontSize={"1.7rem"}>Usage Data</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              We collect information about how you interact with our platform,
              including your course progress, assignments, and browsing
              activity.
            </Text>
            <Heading fontSize={"1.7rem"}>Cookies</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              We use cookies and similar technologies to enhance your experience
              on our platform, personalize content, and analyze usage patterns.
            </Text>
            <Heading fontSize={"1.7rem"}>Use of Information</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              Personal Information: We use your personal information to provide
              our services, communicate with you, process payments, and deliver
              course materials.
            </Text>
            <Heading fontSize={"1.7rem"}>Usage Data</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              We analyze usage data to improve our platform, customize your
              learning experience, and understand user preferences.
            </Text>
            <Heading fontSize={"1.7rem"}>Cookies</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              We utilize cookies for authentication, personalization, and
              analytical purposes.
            </Text>
            <Heading fontSize={"1.7rem"}>Information Sharing</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              We may share your personal information with trusted third-party
              service providers who assist us in delivering our services, such
              as payment processors and hosting providers. We may disclose your
              information if required by law or to protect our legal rights,
              enforce our Terms and Conditions, or address any fraudulent or
              illegal activities.
            </Text>
            <Heading fontSize={"1.7rem"}> Data Security</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              We implement appropriate security measures to safeguard your
              personal information and protect it against unauthorized access,
              alteration, or disclosure. However, no method of data transmission
              over the internet or electronic storage is completely secure.
              While we strive to protect your information, we cannot guarantee
              its absolute security.
            </Text>
            <Heading fontSize={"1.7rem"}>Third-Party Links</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              Our platform may contain links to third-party websites or
              services. Please note that we are not responsible for the privacy
              practices or content of such third parties. We encourage you to
              review the privacy policies of those third parties before
              providing any personal information.
            </Text>

            <Heading fontSize={"1.7rem"}>Changes to the Privacy Policy</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              We reserve the right to update or modify this Privacy Policy at
              any time. We will notify you of any significant changes by posting
              the updated Privacy Policy on our platform. Your continued use of
              our platform after the changes are implemented constitutes your
              acceptance of the revised Privacy Policy.
            </Text>
            <Heading fontSize={"1.7rem"}>Contact Us</Heading>
            <Text pb={{ base: "0.5rem", md: "20px" }}>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy or the handling of your personal information,
              please contact us at{" "}
              <chakra.a href="mailto:support@codelandcs.com">
                support@codelandcs.com
              </chakra.a>
              .
            </Text>
            <Text>
              By using CodelandCS, you acknowledge that you have read and
              understood this Privacy Policy.
            </Text>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
