import {
  Box,
  Card,
  CardBody,
  CardFooter,
  Flex,
  Heading,
  Square,
  Icon,
  Text,
  Center,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

export default function CourseCountDown({
  real_date,
}: {
  real_date: string | undefined;
}) {
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);

  const date = "2023-01-01";
  // Count down function
  function countDown() {
    var countDownDate = new Date(
      real_date ? real_date : "Feb 28, 2023 15:37:25"
    ).getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {
      // Get today's date and time
      var now = new Date().getTime();

      // Find the distance between now and the count down date
      var distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      if (days < 0 && minutes < 0 && seconds < 0 && hours < 0) {
        setDays(0);
        setMinutes(0);
        setSeconds(0);
        setHours(0);
        setIsCompleted(true);
        return;
      } else {
        setDays(days);
        setMinutes(minutes);
        setSeconds(seconds);
        setHours(hours);
      }
    }, 1000);
  }
  useEffect(() => {
    countDown();
  }, []);
  return (
    <Box>
      {!isCompleted && (
        <Flex
          columnGap={{ base: "3px", md: "10px" }}
          color="font.main"
          alignContent={"center"}
        >
          <Card
            bg="white"
            w={{ base: "74px", md: "153px" }}
            variant="elevated"
            textAlign="center"
          >
            <CardBody padding={0}>
              <Text
                fontSize={{ base: "1rem", md: "2rem" }}
                fontWeight="700"
                color="font.main"
                py="0.5rem"
              >
                {days < 1 ? hours : days}
              </Text>
            </CardBody>
            <CardFooter bg="#00296B1A" py="10px" justifyContent="center">
              <Text
                textAlign="center"
                fontSize={{ base: "0.8rem", md: "1rem" }}
                color="font.dark"
                fontWeight="400"
              >
                {days < 1 ? "Hours" : "Days"}
              </Text>
            </CardFooter>
          </Card>
          <Center
            fontSize={{ base: "1.5rem", lg: "2rem" }}
            fontWeight={"bold"}
            pt={{ base: "0px", lg: "40px" }}
          >
            <Icon as={BsThreeDotsVertical} />
          </Center>
          <Card
            bg="white"
            w={{ base: "74px", md: "153px" }}
            variant="elevated"
            textAlign="center"
          >
            <CardBody padding={0}>
              <Text
                fontSize={{ base: "1rem", md: "2rem" }}
                fontWeight="700"
                color="font.main"
                py="0.5rem"
              >
                {minutes}
              </Text>
            </CardBody>
            <CardFooter bg="#00296B1A" py="10px" justifyContent="center">
              <Text
                textAlign="center"
                fontSize={{ base: "0.8rem", md: "1rem" }}
                color="font.dark"
                fontWeight="400"
              >
                Minutes
              </Text>
            </CardFooter>
          </Card>
          <Center
            fontSize={{ base: "1.5rem", lg: "2rem" }}
            fontWeight={"bold"}
            pt={{ base: "0px", lg: "40px" }}
          >
            <Icon as={BsThreeDotsVertical} />
          </Center>
          <Card
            bg="white"
            w={{ base: "74px", md: "153px" }}
            variant="elevated"
            textAlign="center"
          >
            <CardBody padding={0}>
              <Text
                fontSize={{ base: "1rem", md: "2rem" }}
                fontWeight="700"
                color="font.main"
                py="0.5rem"
              >
                {seconds}
              </Text>
            </CardBody>
            <CardFooter bg="#00296B1A" py="10px" justifyContent="center">
              <Text
                textAlign="center"
                fontSize={{ base: "0.8rem", md: "1rem" }}
                color="font.dark"
                fontWeight="400"
              >
                Seconds
              </Text>
            </CardFooter>
          </Card>
        </Flex>
      )}
    </Box>
  );
}
