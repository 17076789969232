import {
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  Spinner,
  Center,
  Text,
  Flex,
  Box,
  Card,
  Divider,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Heading,
  Icon,
  Input,
} from "@chakra-ui/react";
import {
  dateFormatter,
  dateTimeFormatter,
  momentsAgo,
  numberFormatter,
} from "../../utils";
import EmptyState from "../skeletons/EmptyState";
import { useEffect, useRef, useState } from "react";
import courseService from "../../app/services/courseService";
import { useCustomToast } from "../../utils/toast";
import { FaCheck, FaCopy } from "react-icons/fa";
import axios from "axios";
import { AiOutlineCloudUpload } from "react-icons/ai";

type Props = {
  data: BillingType[];
  loading: boolean;
  handleFetch: (e?: any) => void;
};
export default function BillingTable(props: Props) {
  const { notifyError, notifySuccess } = useCustomToast();
  const [renewLoading, setRenewLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [proof, setProof] = useState<string | null>();
  const [isCopied, setIsCopied] = useState(false);
  const accountRef = useRef<HTMLDivElement>(null);
  const [proofLoading, setProofLoading] = useState(false);
  const [file, setFile] = useState<FileList>();
  const [courseId, setCourseId] = useState<string | null>(null);

  const handleRenew = (id: string) => {
    setRenewLoading(true);
    courseService
      .renewCoursePayment(id)
      .then((res) => {
        // console.log(res);
        window.location.href = res.data.PaymentDetails.authorization_url;
        setRenewLoading(false);
      })
      .catch((err) => {
        notifyError(err.response.data.message);
        setRenewLoading(false);
      });
  };
  const handleModal = (id: string) => {
    console.log(id);
    setCourseId(id);
    onOpen();
  };
  const handleManualPay = (id: string) => {
    console.log(id);
    // return;
    setProofLoading(true);
    courseService
      .manualRegisterCourse({
        receipt: proof,
        batch_id: id,
      })
      .then((res) => {
        // console.log(res.data);
        notifySuccess("successful");
        setProofLoading(false);
        onClose();
        props.handleFetch();
      })
      .catch((err) => {
        console.log(err);
        setProofLoading(false);
        notifyError(err.response.data.message);
      });
  };
  const handleCopy = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_ACCOUNT_NUMBER}`);
    setIsCopied(true);
    // Highlight the copied text
    const range = document.createRange();
    range.selectNode(accountRef.current!);
    window.getSelection()?.removeAllRanges();
    window.getSelection()?.addRange(range);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  useEffect(() => {
    const uploadURL = process.env.REACT_APP_CLOUDINARY_URL;
    const uploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
    if (file) {
      setProofLoading(true);
      const formData = new FormData();
      formData.append("file", file[0]);
      formData.append("upload_preset", `${uploadPreset}`);
      axios({
        url: uploadURL,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formData,
      })
        .then((res) => {
          console.log(res.data.secure_url);
          setProof(res.data.secure_url);
          setProofLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setProofLoading(false);
        });
    }
    return;
  }, [file]);

  return (
    <>
      <Modal isOpen={isOpen} size={{ base: "xs", md: "2xl" }} onClose={onClose}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(10deg)"
        />
        <ModalContent>
          <ModalCloseButton color="font.main" />
          <ModalBody bg="white" color="font.main">
            <Box
              pt={{ base: "2rem", md: "100px" }}
              pb={{ base: "1rem", md: "132px" }}
              textAlign="center"
              px={{ base: "0.5rem", md: "82px" }}
            >
              <Box w="100%">
                <Heading
                  color="font.main"
                  fontSize={{ base: "1.3rem", md: "40px" }}
                  textAlign="center"
                >
                  Payment details
                </Heading>
                <Box
                  pt={{ base: "2.3rem", md: "66.41px" }}
                  w="100%"
                  color="font.dark"
                >
                  <Flex
                    justifyContent="space-between"
                    fontSize={{ base: "1rem", md: "1.3rem" }}
                    pb={{ base: "0.9rem", md: "24px" }}
                    borderBottomColor="#999FAA"
                    borderBottomWidth={"1px"}
                  >
                    <Text fontWeight="400">Payment Method</Text>
                    <Text fontWeight="500">Transfer</Text>
                  </Flex>

                  <Flex
                    pt={{ base: "2rem", md: "50px" }}
                    justifyContent="space-between"
                    fontSize={{ base: "1rem", md: "1.3rem" }}
                  >
                    <Text fontWeight="400">Name</Text>
                    <Text fontWeight="500">
                      {" "}
                      {process.env.REACT_APP_ACCOUNT_NAME}
                    </Text>
                  </Flex>

                  <Flex
                    pt={{ base: "1.2rem", md: "30px" }}
                    justifyContent="space-between"
                    fontSize={{ base: "1rem", md: "1.3rem" }}
                  >
                    <Text fontWeight="400">Account Number</Text>
                    <Text fontWeight="500" ref={accountRef}>
                      {process.env.REACT_APP_ACCOUNT_NUMBER}
                      {/* {batches && batches.days} */}
                      <Icon
                        fontSize="0.7rem"
                        color={isCopied ? "green.500" : "inherit"}
                        as={isCopied ? FaCheck : FaCopy}
                        onClick={handleCopy}
                      />{" "}
                    </Text>
                  </Flex>
                  <Flex
                    pt={{ base: "1.2rem", md: "30px" }}
                    justifyContent="space-between"
                    fontSize={{ base: "1rem", md: "1.3rem" }}
                  >
                    <Text fontWeight="400">Bank Name</Text>
                    <Text fontWeight="500">
                      {process.env.REACT_APP_BANK_NAME}
                    </Text>
                  </Flex>

                  {/* <Flex
                    pt="24px"
                    justifyContent="space-between"
                    fontSize={{ base: "1rem", md: "1.3rem" }}
                  >
                    <Text fontWeight="400">Amoumt</Text>
                    <Text fontWeight="600">
                      ₦{numberFormatter(props.data.a)}
                      {" Monthly"}
                    </Text>
                  </Flex> */}
                </Box>

                <Flex
                  flexDirection={"column"}
                  columnGap={"1rem"}
                  alignItems={"center"}
                  py="1.8rem"
                >
                  <Heading fontSize={"18px"} as={"h4"}>
                    Have You Paid?
                  </Heading>

                  <Text
                    // color="#407BFF"
                    fontSize={{ base: "0.8rem", md: "14px" }}
                    fontWeight="500"
                    cursor="pointer"
                    onClick={() => {
                      if (!renewLoading) {
                        document.getElementById("upload-btn")!.click();
                      }
                      // props.onClose();
                    }}
                    textAlign={"center"}
                  >
                    <Icon as={AiOutlineCloudUpload} /> Upload Payment Proof{" "}
                    <br />
                    <Spinner
                      display={proofLoading ? "inline-flex" : "none"}
                      ml="10px"
                      size={"xs"}
                    />
                    <Input
                      type="file"
                      onChange={(e) => {
                        if (e.target.files) {
                          // console.log(e.target.files);
                          setFile(e.target.files!);
                        }
                      }}
                      hidden
                      accept="image/*"
                      id="upload-btn"
                    />
                  </Text>
                  {proof && (
                    <Image
                      src={proof}
                      h="100px"
                      w="100px"
                      objectFit={"cover"}
                    />
                  )}

                  <Center pt={"1rem"}>
                    <Button
                      // display="flex"
                      w={{ base: "100%", md: "300px" }}
                      _hover={{ bg: "blue.700", color: "gray.200" }}
                      transition="0.6s"
                      borderRadius={"none"}
                      bg="bg.main"
                      h={{ base: "2.6rem", md: "40px" }}
                      color="#ffffff"
                      fontWeight="600"
                      fontSize={{ base: "10px", md: "14px" }}
                      onClick={() => handleManualPay(courseId!)}
                      isDisabled={renewLoading || !proof}
                    >
                      I've Sent The Money
                      <Spinner
                        display={renewLoading ? "block" : "none"}
                        ml="10px"
                      />
                    </Button>
                  </Center>
                </Flex>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box pt="3rem" pb="200px" display={{ base: "none", md: "block" }}>
        <TableContainer
          overflowY="auto"
          css={{
            "&::-webkit-scrollbar": {
              width: "2px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#C4C4C4",
              borderRadius: "24px",
            },
          }}
        >
          <Table variant="unstyled">
            <Thead
              bg="bg.main"
              h="107px"
              color="white"
              fontSize={"16px"}
              fontWeight="600"
              ps="61px"
            >
              <Tr color="white">
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Course name
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Last Billing
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Next Billing
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Subscribed on
                </Th>

                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Batch Completion
                </Th>

                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Amount
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Status
                </Th>
                <Th fontSize={"16px"} color="white" fontWeight="600">
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody fontSize="14px" fontWeight="400" color="font.dark" pt="7px">
              {props.data?.length ? (
                props.data?.map((course) => (
                  <Tr py="10px" key={course.id}>
                    <Td title={course.name}>
                      {course.name.substring(0, 30)}...
                    </Td>
                    <Td sx={{ textTransform: "capitalize" }}>
                      {momentsAgo(course.last_payment)}
                    </Td>
                    <Td sx={{ textTransform: "capitalize" }}>
                      {momentsAgo(course.next_payment)}
                    </Td>
                    <Td>{dateTimeFormatter(course.created_at)}</Td>

                    <Td>{dateFormatter(course.end_date)}</Td>
                    <Td>
                      ₦{" "}
                      {numberFormatter(
                        course.payment_due ? course.amount_due : course.amount
                      )}
                    </Td>
                    <Td>
                      <Text
                        color={
                          course.status === "EXPIRED" ? "#FA0000" : "#2CA900"
                        }
                      >
                        {course.status}
                      </Text>
                    </Td>

                    <Td>
                      <Button
                        bg="#DAE4F3"
                        isDisabled={!course.payment_due || renewLoading}
                        // isDisabled
                        borderRadius="16px"
                        px="24px"
                        py="8px"
                        onClick={() => {
                          handleRenew(course.id);
                        }}
                        columnGap="10px"
                      >
                        Renew now
                        <Spinner
                          display={
                            renewLoading && course.status === "EXPIRED"
                              ? "inline-flex"
                              : "none"
                          }
                        />
                      </Button>
                    </Td>
                  </Tr>
                ))
              ) : props.loading ? (
                <Tr>
                  <Td colSpan={6}>
                    <Center w="100%">
                      <Spinner />
                    </Center>
                  </Td>
                </Tr>
              ) : (
                <Tr pt="2rem">
                  <Td colSpan={6}>
                    <EmptyState message="All subscriptions will appear here" />
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
      <Box pb="200px" pe={"1rem"} display={{ base: "block", md: "none" }}>
        <Box>
          <Box pt="1rem">
            {props.data?.length ? (
              props.data?.map((course) => (
                <Card variant="elevated" key={course.id}>
                  <Flex
                    px="0.61rem"
                    py="1rem"
                    columnGap={"1rem"}
                    justifyContent={"space-between"}
                  >
                    <Box>
                      <Flex columnGap={"5px"}>
                        <Center
                          boxSize="30px"
                          borderWidth={"1px"}
                          borderColor="#000000"
                          borderRadius={"full"}
                        >
                          <Image
                            boxSize="10px"
                            src="/assets/images/product-design.png"
                          />
                        </Center>
                        <Text fontSize={"0.9rem"} fontWeight="600">
                          Course name
                        </Text>
                      </Flex>
                      <Flex pt="8px" columnGap={"12px"} ps="1rem">
                        <Divider
                          borderColor={"#2CA900"}
                          borderWidth="2px"
                          h="125px"
                          orientation="vertical"
                        />
                        <Box fontSize="12px" fontWeight="400">
                          <Flex columnGap={"5px"}>
                            <Text>Last Payment:</Text>
                            <Text>{momentsAgo(course.last_payment, true)}</Text>
                          </Flex>
                          <Flex columnGap={"5px"}>
                            <Text>Next Payment:</Text>
                            <Text>{momentsAgo(course.next_payment)}</Text>
                          </Flex>
                          <Flex columnGap={"5px"}>
                            <Text>Join Date:</Text>
                            <Text>{dateFormatter(course.created_at)}</Text>
                          </Flex>
                          <Flex columnGap={"5px"}>
                            <Text>End date:</Text>
                            <Text>{dateFormatter(course.end_date)}</Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection={"column"}
                      rowGap="12px"
                      fontWeight="400"
                      textAlign={"right"}
                    >
                      <Text fontSize={"0.8rem"} title={course.name}>
                        {course.name.substring(0, 20)}..
                      </Text>
                      <Flex columnGap={"5px"}>
                        <Text>Amount:</Text>
                        <Text fontSize="14px" fontWeight="700">
                          ₦
                          {numberFormatter(
                            course.payment_due
                              ? course.amount_due
                              : course.amount
                          )}
                        </Text>
                      </Flex>
                      <Text
                        fontSize="12px"
                        color={course.status === "EXPIRED" ? "red" : "#2CA900"}
                      >
                        {course.status}
                      </Text>

                      <Button
                        h="2rem"
                        w="107px"
                        borderRadius={"none"}
                        fontSize="10px"
                        isDisabled={!course.payment_due || renewLoading}
                        // isDisabled
                        onClick={() => {
                          handleRenew(course.id);
                        }}
                        columnGap="10px"
                      >
                        Renew now
                        <Spinner
                          size="sm"
                          display={
                            renewLoading && course.status === "EXPIRED"
                              ? "inline-flex"
                              : "none"
                          }
                        />
                      </Button>
                    </Box>
                  </Flex>
                </Card>
              ))
            ) : props.loading ? (
              <Center w="100%">
                <Spinner />
              </Center>
            ) : (
              <EmptyState message="All subscriptions will appear here" />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}
