import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  Icon,
  Image,
  Divider,
  IconButton,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";

import Layout from "../../layouts/dashboard/Layout";
import { HiDownload } from "react-icons/hi";
import { HiOutlineShare } from "react-icons/hi";
import { customStyle } from "../../utils/style";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentUser } from "../../features/authSlice";
import {
  selectCompletedCourses,
  setCompletedCourses,
} from "../../features/courseSlice";
import { useParams } from "react-router-dom";
import courseService from "../../app/services/courseService";
import { selectConstants } from "../../features/constantSlice";

export default function CertificationView() {
  const printRef = React.useRef<HTMLDivElement>(null);
  const user = useSelector(selectCurrentUser);
  const handleDownloadPdf = async () => {
    const element: any = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF("portrait", "px", [380, 380]);

    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    pdf.addImage(data, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("certificate.pdf");
  };
  const [courseData, setCourseData] = useState<ActiveBatches>();
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const constants = useSelector(selectConstants);
  const completedCourses = useSelector(selectCompletedCourses);
  const { id } = useParams();
  useEffect(() => {
    // console.log(constants);
    if (!completedCourses) {
      setIsLoading(true);
      courseService
        .getFinishedCourses()
        .then((res) => {
          if (res) {
            dispatch(setCompletedCourses(res.data));
            setIsLoading(false);
            // console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      const data = completedCourses.find((course) => course.id === id!);
      if (data) {
        setCourseData(data);
        // console.log(data);
        setIsLoading(false);
      }
    }
  }, [completedCourses]);
  return (
    <Layout>
      <Box
        px={{ base: "1rem", md: "62px" }}
        pt={{ base: "3rem", md: "64px" }}
        pb="157px"
      >
        <Center ref={printRef} bg="bg.subMain">
          <Box w="100%" pb="90px">
            <Box
              px={{ base: "1rem", md: "29px" }}
              pt={{ base: "1rem", md: "29px" }}
              w="100%"
              h={{ base: "132px", md: "288px" }}
              bg="bg.main"
            >
              <Box
                w="100%"
                h="100%"
                borderTopColor={"white"}
                borderWidth={{ base: "1px", md: "2px" }}
                borderLeftColor={"white"}
                borderRightColor="white"
                borderBottom={"none"}
              >
                <Box
                  textAlign="center"
                  color="white"
                  pt={{ base: "1.7rem", md: "86px" }}
                >
                  <Text>
                    {" "}
                    <Flex columnGap={"6px"} alignContent="center">
                      <Center>
                        <Image
                          w={{ base: "1rem", md: "50%" }}
                          src="/logo/logo.png"
                        />
                      </Center>
                    </Flex>
                  </Text>
                  <Heading
                    mt={{ base: "1.4rem", md: "52px" }}
                    fontSize={{ base: "1rem", md: "48px" }}
                    fontWeight="400"
                  >
                    Certification of completion
                  </Heading>
                </Box>
              </Box>
            </Box>
            <Box
              textAlign={"center"}
              pt={{ base: "1.7rem", md: "74px" }}
              px={{ base: "3rem", md: "148px" }}
              color="font.main"
            >
              <Heading
                fontFamily={"serif"}
                fontWeight="400"
                fontSize={{ base: "0.8rem", md: "40px" }}
                fontStyle="italic"
                color="font.dark"
              >
                This is to certify that
              </Heading>
              <Heading
                fontSize={{ base: "0.8em", md: "32px" }}
                fontWeight="400"
                pb="15px"
                borderBottomColor={"#999FAA"}
                borderBottomWidth="1px"
                mt={{ base: "1.5rem", md: "36px" }}
              >
                {`${user?.first_name} ${user?.last_name}`}
              </Heading>
              <Text
                color="font.main"
                fontSize={customStyle.fontSize.paragragh}
                fontWeight="400"
                lineHeight={"20px"}
                pt="28px"
                px={{ base: "1rem", md: "110px" }}
              >
                has completed an intensive training on {courseData?.name} during
                the {courseData?.name} training in codeland academy online
                training
              </Text>

              <Flex pt="33px" justifyContent="space-between">
                <Box>
                  <Flex justifyContent={"center"}>
                    <Image boxSize="40px" src="/assets/images/signature.svg" />
                  </Flex>
                  <Divider borderWidth={"1px"} borderColor="#999FAA" mt="7px" />
                  <Box pt="8px">
                    <Heading
                      fontSize={customStyle.fontSize.paragragh}
                      fontWeight="500"
                    >
                      {`${courseData?.first_name} ${courseData?.last_name}`}
                    </Heading>
                    <Text
                      fontSize={{ base: "8px", md: "10px" }}
                      fontWeight={"400"}
                      color="font.main"
                    >
                      Program instructor
                    </Text>
                  </Box>
                </Box>
                <Box>
                  <Flex justifyContent={"center"}>
                    <Image
                      boxSize="40px"
                      src={constants?.company?.CEO_signature}
                    />
                  </Flex>
                  <Divider borderWidth={"1px"} borderColor="#999FAA" mt="7px" />
                  <Box pt="8px">
                    <Heading
                      fontSize={customStyle.fontSize.paragragh}
                      fontWeight="500"
                    >
                      David welmer kyrian
                    </Heading>
                    <Text
                      fontSize={{ base: "8px", md: "10px" }}
                      fontWeight={"400"}
                      color="font.main"
                    >
                      Program instructor
                    </Text>
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Center>
        <Flex
          pt="2rem"
          justifyContent={"space-between"}
          flexDirection={{ base: "column", md: "row" }}
          rowGap="1rem"
        >
          <Box>
            {" "}
            <Button
              bg="bg.main"
              w={{ base: "100%", md: "206px" }}
              h={{ base: "2.8rem", md: "56px" }}
              color="white"
              fontSize={{ base: "1rem", md: "20px" }}
              _hover={{ bg: "blue.700", color: "gray.200" }}
              transition="0.6s"
              borderRadius={"none"}
              columnGap="20px"
              onClick={handleDownloadPdf}
            >
              Download
              <Icon as={HiDownload} />
            </Button>
          </Box>
          <Flex columnGap="28px" alignContent="center" color="font.main">
            <Button
              borderColor="#DAE4F3"
              borderWidth="1px"
              w={{ base: "100%", md: "271px" }}
              h={{ base: "2.8rem", md: "56px" }}
              fontSize={{ base: "1rem", md: "20px" }}
              bg="none"
              borderRadius={"none"}
              _hover={{ bg: "blue.700", color: "gray.200" }}
              transition="0.6s"
            >
              Request a hardcopy
            </Button>
            <Center display={{ base: "none", md: "flex" }}>
              {" "}
              <IconButton
                bg="none"
                _hover={{ bg: "none" }}
                fontSize="27px"
                icon={<HiOutlineShare />}
                aria-label="share button"
              />
            </Center>
          </Flex>
        </Flex>
      </Box>
    </Layout>
  );
}
