import { Box, Heading, Text } from "@chakra-ui/react";
import Footer from "../../layouts/Footer";
import Navbar from "../../layouts/Navbar";
import { customStyle } from "../../utils/style";

export default function Terms() {
  return (
    <Box>
      <Navbar />
      <Box px={{ base: "1rem", md: "2rem" }} pt={{ base: "3rem", md: "100px" }}>
        <Heading
          color="font.main"
          fontSize={customStyle.fontSize.sectionHeader}
        >
          Terms And Condition
        </Heading>

        <Box
          pb="5rem"
          pt={{ base: "2rem", md: "2.5rem" }}
          lineHeight={{ base: "1.7rem", md: "3rem" }}
          color="font.dark"
          fontSize={{ base: "0.7rem", md: "1.5rem" }}
        >
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            These Terms and Conditions ("Terms") outline the rules and
            regulations for your use of our online learning platform. By
            accessing and using our platform, you agree to comply with these
            Terms. If you do not agree with any part of these Terms, please
            refrain from using our services.
          </Text>
          <Heading fontSize={"1.7rem"}>Account Creation and Usage</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            You are responsible for maintaining the confidentiality of your
            account information, including your username and password. You agree
            to provide accurate and up-to-date information during the
            registration process. You are solely responsible for all activities
            that occur under your account.
          </Text>
          <Heading fontSize={"1.7rem"}>Course Enrollment and Payment</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            Our platform offers various courses, including web development,
            React JS, blockchain development, Node JS, web design, etc. By
            enrolling in a course, you agree to the specified duration and
            payment terms associated with that course. All payments are
            non-refundable, except as expressly stated in our refund policy.
            CodelandCS reserves the right to modify course fees, course
            availability, or any other aspect of the courses without prior
            notice.
          </Text>
          <Heading fontSize={"1.7rem"}>Intellectual Property</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            All course materials, including text, graphics, videos, and
            assessments, are the intellectual property of CodelandCS and are
            protected by copyright laws. You may not copy, distribute, or
            reproduce any course content without explicit permission from
            CodelandCS. Any materials provided to you during the course are for
            your personal use only and may not be shared or redistributed.
          </Text>
          <Heading fontSize={"1.7rem"}>User Conduct</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            You agree to use our platform and services for lawful purposes and
            in a manner that does not infringe upon the rights of others. You
            shall not engage in any activity that may disrupt or interfere with
            the proper functioning of our platform. You are solely responsible
            for the content you post, submit, or share on our platform and must
            ensure it does not violate any laws or infringe upon the rights of
            others.
          </Text>
          <Heading fontSize={"1.7rem"}>Privacy Policy</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            Our Privacy Policy governs the collection, use, and disclosure of
            personal information provided by users of our platform. By using our
            platform, you consent to the collection, use, and disclosure of your
            personal information as outlined in our Privacy Policy.
          </Text>
          <Heading fontSize={"1.7rem"}>Limitation of Liability</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            CodelandCS and its affiliates shall not be held liable for any
            direct, indirect, incidental, or consequential damages arising out
            of your use of our platform or participation in our courses. We do
            not guarantee the accuracy, completeness, or reliability of the
            course content or materials provided.
          </Text>
          <Heading fontSize={"1.7rem"}>Modification of Terms</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            CodelandCS reserves the right to modify these Terms at any time
            without prior notice. By continuing to use our platform after any
            modifications, you accept and agree to the updated Terms.
          </Text>
          <Heading fontSize={"1.7rem"}> Termination</Heading>
          <Text pb={{ base: "0.5rem", md: "20px" }}>
            CodelandCS may, at its discretion, terminate or suspend your account
            or access to our platform at any time and for any reason without
            prior notice. These Terms and Conditions constitute the entire
            agreement between you and CodelandCS and supersede any prior
            agreements or understandings.
          </Text>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
