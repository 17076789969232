import moment from "moment";

const formatter = new Intl.NumberFormat();

export const numberFormatter = (num: number) => {
  return formatter.format(num);
};

export const momentsAgo = (date: string, noPrefix = false) => {
  return moment.utc(`${date}`).local().startOf("seconds").fromNow(noPrefix);
};

export const dateTimeFormatter = (date: string) => {
  return moment.utc(`${date}`).local().format("DD MMM YYYY hh:mm A");
};
export const dateFormatter = (date: string) => {
  return moment.utc(`${date}`).local().format("DD MMM YYYY");
};
export const realTime = (date: string, prefix = 0) => {
  return moment(
    new Date().setHours(parseInt(date.split(":")[0]) + prefix, 0)
  ).format("LT");
};
export const formatRealTime = (date: string) => {
  return `${realTime(date)} - ${realTime(date, 1)}`;
};
export const escapeHtml = (text: string) => {
  let map: any = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#039;",
  };

  return text.replace(/[&<>"']/g, function (m: string) {
    return map[m];
  });
};
export const capitalizeFirstLetter = (str: string) => {
  let item = str.toLowerCase().replace(/\b[a-z]/g, function (letter: string) {
    return letter.toUpperCase();
  });
  return item;
};
